import * as React from 'react';

import { Link } from "react-router-dom";

import { 
   createStyles, 
  ListItem, 
  ListItemIcon,
  Theme, 
  WithStyles, 
  withStyles, 
} from '@material-ui/core';

export interface INavLinkItem {
  text: string;
  path: string;
  icon: () => React.ReactElement<any>;
}

export interface INavLinkItemProps extends WithStyles<typeof styles> {
  handleItemSelect: () => void;
  item: INavLinkItem;
}

class NavMenuItem extends React.Component<INavLinkItemProps> {
  public props: INavLinkItemProps;

  constructor(props: INavLinkItemProps) {
    super(props);
    this.props = props
  }

  public render() {
    return (
      <ListItem key={this.props.item.path}>
        <ListItemIcon>
          {this.props.item.icon()}
        </ListItemIcon>
        <Link to={{pathname: this.props.item.path}}  onClick={this.props.handleItemSelect}>{this.props.item.text}</Link>
      </ListItem>
    );
  }
}

const styles = (theme: Theme) => createStyles({
});

export default withStyles(styles)(NavMenuItem);
