import axios from 'axios';

import { AxiosError, AxiosResponse } from 'axios';

import { Permission } from '../../../stores/authorization';

import { ILoginResponse } from '../models';
import { HttpError, InvalidCredentialsError, ServerDownError } from '../models/exceptions';

import { IApiProviderUtils } from '../api.provider.utils';

export interface ILoginProvider {
  doLogin(username: string, password: string): Promise<ILoginResponse>;
}

export class LoginProvider implements ILoginProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  /**
   * Execute a remote login command to retrieve an access token and user permissions.
   * @param username The user name credential
   * @param password
   * @returns {Observable<ILoginResponse>}
   */
  public async doLogin(username: string, password: string): Promise<ILoginResponse> {
    const loginUrl: string = this.baseApiUrl + 'auth/user';
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    const data =  {name: username, password };

    return axios.post(loginUrl, data, { headers })
      .then(this.extractLoginData)
      .catch(this.handleLoginError);
  }

  private extractLoginData(response: AxiosResponse): ILoginResponse {
    const permissionsArray: Permission[] = [];
    const data = response.data;

    Object.keys(response.data.permissions).forEach((apiPermission) => {
      const permission: Permission = new Permission(
              apiPermission,
              data.permissions[apiPermission].view,
              data.permissions[apiPermission].manage,
              data.permissions[apiPermission].execute);

      permissionsArray.push(permission);
    });

    return {
      name: data.name,
      slug: data.slug,
      permissions: permissionsArray,
      token: data.token
    };
  }

  /**
   * Handles errors generated by the login api call. If the error status is 401, then the login credentials
   * were invalid.
   *
   * @param error<AxiosError> The api error.
   * @return A Promise reject containing the detected error.
   */
  private handleLoginError (error: AxiosError): Promise<ILoginResponse> {
    let apiError: HttpError;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // If this is a 401(Unauthorized) error then the login credentials were invalid.
      const status = error.response.status;
      if (status === 401) {
        apiError = new InvalidCredentialsError();
      } else {
        // Some other http error
        const err = error.response.statusText || error.message;;
        apiError = new HttpError(
          status,
          `${status} - ${status || ''}: body - ${err}`
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      // tslint:disable-next-line:no-console
      // console.log('http error has request - ', error.request);
      if (error.request.status === 0) {
        apiError = new ServerDownError();
      } else {
        apiError = new HttpError(
          -1,
          error.message ? error.message : error.toString()
        )
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      // tslint:disable-next-line:no-console
      // console.log('http Error does not have request. message: ', error.message);

      // Probably an execution exception
      apiError = new HttpError(
        -1,
        error.message ? error.message : error.toString()
      )
    }

    // tslint:disable-next-line:no-console
    console.error(apiError.message);
    return Promise.reject(apiError);
  }
 }
