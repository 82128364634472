import * as Excel from 'exceljs';
import moment from 'moment';

import {
  addEmptyRow, 
  addSheetTitleRow,
  buildColumns, 
  createWorkbook, 
  createWorksheet,
  findCellColumnLetterByNumber, 
  setHeaderStyles,
  setTitleCell,
  uploadWorkbook 
} from '../../xlsx/xlsx.report.build.utilities';
import { IInventoryRecord } from '../InventoryReportDefinition';
import { SheetColumnMetaData, XcelCategoryGroupingHeaderTypes, XcelHeaderTypes } from './inventory.xlsx.report.metadata';

export type CollectionGroupType = Map<string, IInventoryRecord[]>;
export type CollectionGroupsType = Map<string, CollectionGroupType>;

export interface IInventoryXlsxReportBuilder {
  buildExportReport(inventoryRecords: IInventoryRecord[], startDate?: Date, endDate?: Date): Promise<boolean>;
}

export class InventoryXlsxReportBuilder implements IInventoryXlsxReportBuilder {
  /**
   * This is the xlsx report builder's main routine. It creates the xlsx workbook,
   * creates the workbook sheets, and upload's the spreadsheet.
   */
  public buildExportReport(inventoryRecords: IInventoryRecord[], startDate: Date, endDate?: Date): Promise<boolean> {
    if (!endDate) {
      endDate = moment().endOf('day').toDate();
    }

    const workbook = createWorkbook('Tilde Administrator');

    // Create the sheet having no grouping.
    const noGroupingWorksheet = this.initializeWorksheet(
                workbook, 
                'No Grouping', 
                buildColumns(XcelHeaderTypes, SheetColumnMetaData), 
                startDate, 
                endDate);
    this.buildNoGroupingWorksheet(noGroupingWorksheet, inventoryRecords);

    // Create the sheet with inventory grouped by main collection and sub-collection
    const categoryGroupingWorksheet = this.initializeWorksheet(
                workbook, 
                'Category Grouping',
                buildColumns(XcelCategoryGroupingHeaderTypes, SheetColumnMetaData), 
                startDate, 
                endDate);
    this.buildCollectionGroupingWorksheet(categoryGroupingWorksheet, inventoryRecords);

    return uploadWorkbook(workbook, 'InventoryReport');
  }

  public initializeWorksheet(
                    workbook: Excel.Workbook, 
                    worksheetName: string,
                    columns: Array<Partial<Excel.Column>>,
                    startDate: Date, 
                    endDate: Date): Excel.Worksheet 
  {
    const worksheet = createWorksheet(workbook, worksheetName);
    worksheet.columns = columns;
    setHeaderStyles(worksheet);

    addSheetTitleRow(worksheet, XcelHeaderTypes, 'Inventory', startDate, endDate);

    return worksheet;
  }

  public buildNoGroupingWorksheet(worksheet: Excel.Worksheet, inventoryRecords: IInventoryRecord[]): void {
    const rows = this.buildReportRows(inventoryRecords);

    const excelRowNumbers: number[] = [];
    rows.forEach((row: IInventoryRecord) => {
      const excelRow = worksheet.addRow(row);

      excelRowNumbers.push(excelRow.number);
    })
  }

  public buildCollectionGroupingWorksheet(worksheet: Excel.Worksheet, inventoryRecords: IInventoryRecord[]): void {
    const groupByCollections: CollectionGroupsType = this.buildGroupByCollections(inventoryRecords);
    const collections = Array.from(groupByCollections.keys()).sort();
  
    collections.forEach((collection) => {
      this.buildCollection(worksheet, collection, groupByCollections.get(collection) as CollectionGroupType);
    })
  }

  /**
   * Build the sales rows for a main collection and it's sub-collections.
   * 
   * @param worksheet The worksheet to add the sales rows.
   * @param collection The name of the main collection to build.
   * @param collectionGroup A map of the collection's sub-collection sales rows.
   */
  public buildCollection(worksheet: Excel.Worksheet, collection: string, collectionGroup: CollectionGroupType): void {
    const subCollections = Array.from(collectionGroup.keys()).sort();

    subCollections.forEach((subCollection) => {
      this.addSubCollectionTitleRow(worksheet, collection, subCollection);
  
      const rowNumbers: number[] = [];

      const rows = this.buildReportRows(collectionGroup.get(subCollection) as IInventoryRecord[]);
      rows.forEach((row: IInventoryRecord) => {
        const excelRow = worksheet.addRow(row);

        rowNumbers.push(excelRow.number);
      });
      // Empty row after each sub-collection
      addEmptyRow(worksheet, XcelCategoryGroupingHeaderTypes);
    })
    
    addEmptyRow(worksheet, XcelCategoryGroupingHeaderTypes);

    return;
  }

  public buildReportRows(inventoryRecords: IInventoryRecord[]):  any[] {
    return inventoryRecords.map((inventoryRecord) => {
      return { 
              ...inventoryRecord, 
              total_amt: inventoryRecord.price * inventoryRecord.quantity,
              processed_at: moment(inventoryRecord.updated_at).format('MM/DD/YYYY')
            }
    })
    .sort((a, b) => {
      return a.sort_code.localeCompare(b.sort_code);
    })
  }

  /**
   * Adds a row containing the collection and subCollection to indicate a
   * new subCollection.
   */
  public addSubCollectionTitleRow(
                                  worksheet: Excel.Worksheet, 
                                  collection: string, 
                                  subCollection: string): Excel.Row 
  {
    const titleRow: Excel.Row = addEmptyRow(worksheet, XcelCategoryGroupingHeaderTypes);
    const collectionCellLetter = findCellColumnLetterByNumber(1);
    const subCollectionCellLetter = findCellColumnLetterByNumber(2);

    // Set the collection field label and style.
    const collectionLabelCell: Excel.Cell = titleRow.getCell(collectionCellLetter);
    setTitleCell(collectionLabelCell, collection);

    // Set the subCollection field label and style.
    const subCollectionLabelCell: Excel.Cell = titleRow.getCell(subCollectionCellLetter);
    setTitleCell(subCollectionLabelCell, subCollection);

    return titleRow;
  }

  public buildGroupByCollections(inventoryRecords: IInventoryRecord[]): CollectionGroupsType {
    const topCollectionsGroups: CollectionGroupsType = new Map<string, Map<string, IInventoryRecord[]>>();

    inventoryRecords.forEach((inventoryRecord) => {
      if (topCollectionsGroups.has(inventoryRecord.main_collection)) {
        const topCollection = topCollectionsGroups.get(inventoryRecord.main_collection);
        
        if (topCollection!.has(inventoryRecord.sub_collection)) {
          topCollection!.get(inventoryRecord.sub_collection)!.push(inventoryRecord);
        } else {
          topCollection!.set(inventoryRecord.sub_collection, [inventoryRecord]);
        }
      } else {
        const subCollectionGroup = new Map<string, IInventoryRecord[]>();

        subCollectionGroup.set(inventoryRecord.sub_collection, [inventoryRecord]);
        topCollectionsGroups.set(inventoryRecord.main_collection, subCollectionGroup);
      }
    })

    return topCollectionsGroups;
  }

}

