import * as React from 'react';

import Moment from 'moment';

import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles,} from '@material-ui/styles';

interface IProps {
  id?: string;
  label: string;
  onChange: (date?: Date) => void;
  initialDate?: Date;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  picker: {
    width: '11em',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const DatePickerBase: React.FC<IProps> = (props) => {
  const dateAsMoment = props.initialDate? Moment(props.initialDate) : null;
  const [selectedDate, setSelectedDate] = React.useState<Moment.Moment | null>(
    dateAsMoment,
  );
  
  const classes = useStyles();
   
  const handleDateChange = (date: Moment.Moment | null) => {
    // tslint:disable-next-line:no-console
    // console.log('Date: ' + date );

    if (!date) {
      props.onChange()
    } else if (date.isValid()) {
      date = date.startOf('day');
      props.onChange(date.toDate())
    }

    setSelectedDate(date);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        clearable={true}
        className={classes.picker}
        margin="normal"
        id={ props.id ? props.id : "date-picker" }
        label={props.label}
        value={selectedDate}
        onChange={handleDateChange}
        format="MM/DD/YYYY"
        autoOk={true}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
  </MuiPickersUtilsProvider>
);
}

export default DatePickerBase;
