import { HTTP_ERROR_TYPE, HttpError } from './http.error';

export const INVALID_CREDENTIALS_MESSAGE = 'Invalid login credentials.';
/**
 * Error thrown if the login credentials are invalid.
 */
export class InvalidCredentialsError extends HttpError {
  constructor() {
    super(
      401,
      INVALID_CREDENTIALS_MESSAGE,
      HTTP_ERROR_TYPE.INVALID_CREDENTIALS
    );
  }
}
