import { Permission } from '../authorization';

/**
 * Class that contains the properties for the current user session. This
 * is a simple container class with no domain knowledge.
 */
export class Session {
  private classUserName: string;
  private classSlug: string;
  private classPermissions: Map<string, Permission>;
  private classUserShops: string[];
  private classAccessToken: string;

  /**
   * Constructor, sets the session properties to default values.
   */
  constructor() {
    this.classUserName = '';
    this.classSlug = '';
    this.classPermissions = new Map<string, Permission>();
    this.classUserShops = [];
    this.classAccessToken = '';
  }

  public set userName(name: string) {
    this.classUserName = name;
  }

  public get userName(): string {
    return this.classUserName;
  }

  public set slug(slug: string) {
    this.classSlug = slug;
  }

  public get slug(): string {
    return this.classSlug;
  }

  public clearPermissions(): void {
    this.classPermissions.clear();
  }

  public set permission(permission: Permission) {
    this.classPermissions.set(permission.subject, permission);
  }

  public getPermissions(): Map<string, Permission> {
    return this.classPermissions;
  }

  public addUserShop(shop: string): void {
    this.classUserShops.push(shop);
  }

  public clearUserShops(): void {
    this.classUserShops.length = 0;
  }

  public getUserShops(): string[] {
    return this.classUserShops;
  }

  public set accessToken(accessToken: string) {
    this.classAccessToken = accessToken;
  }

  public get accessToken(): string {
    return this.classAccessToken;
  }

  public clearAccessToken(): void {
    this.classAccessToken = '';
  }
}
