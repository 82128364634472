import { 
  FieldColumnDefinitions,
  FieldColumnKeys, 
  FieldTypes, 
  IColumnKeyTitle, 
  IDisplayColumnDefinition,
} from '../reportDefinitions';

/**
 * Defines the columns and order for the non-grouped sales report.
 */
export const NoGroupingCategoryHeadersTypes: FieldTypes[] = [
  FieldTypes.SKU,
  FieldTypes.VENDOR,
  FieldTypes.TITLE,
  FieldTypes.PRICE,
  FieldTypes.QTY,
  FieldTypes.AMOUNT,
  FieldTypes.COST,
  FieldTypes.PROCESSED_AT,
  FieldTypes.COLLECTION,
  FieldTypes.SUB_COLLECTION,
  FieldTypes.ORDER_DISCOUNT,
  FieldTypes.ORDER_DISCOUNT_CODES,
  FieldTypes.ORDER_NAME,
  FieldTypes.ORDER_STATUS,
  FieldTypes.SORT_CODE,
];

/**
 * Defines the columns and order for the category grouped sales report.
 */
export const GroupByCategoryHeaderTypes: FieldTypes[] = [
  FieldTypes.SKU,
  FieldTypes.VENDOR,
  FieldTypes.TITLE,
  FieldTypes.PRICE,
  FieldTypes.QTY,
  FieldTypes.AMOUNT,
  FieldTypes.COST,
  FieldTypes.PROCESSED_AT,
  FieldTypes.SUB_COLLECTION,
  FieldTypes.ORDER_DISCOUNT,
  FieldTypes.ORDER_DISCOUNT_CODES,
  FieldTypes.ORDER_NAME,
  FieldTypes.ORDER_STATUS,
  FieldTypes.SORT_CODE,
];

export const COLUMN_KEY_TITLES: FieldColumnKeys = new Map<FieldTypes, IColumnKeyTitle>([
  [FieldTypes.SKU,                  { key: 'sku',              title: 'SKU'}],
  [FieldTypes.VENDOR,               { key: 'vendor',           title: 'Vendor'}],
  [FieldTypes.TITLE,                { key: 'title',            title: 'Title'}],
  [FieldTypes.PRICE,                { key: 'price',            title: 'Unit Price'}],
  [FieldTypes.QTY,                  { key: 'quantity',         title: 'Qty'}],
  [FieldTypes.AMOUNT,               { key: 'total_amt',        title: 'Total'}],
  [FieldTypes.PROCESSED_AT,         { key: 'processed_at',     title: 'Date'}],
  [FieldTypes.COLLECTIONS,          { key: 'collections',      title: 'Categories'}],
  [FieldTypes.COLLECTION,           { key: 'collection',       title: 'Category'}],
  [FieldTypes.SUB_COLLECTION,       { key: 'sub_collection',   title: 'Sub Category'}],
  [FieldTypes.ORDER_DISCOUNT,       { key: 'discount',         title: 'Discount'}],
  [FieldTypes.ORDER_DISCOUNT_CODES, { key: 'discount_codes',   title: 'Code'}],
  [FieldTypes.ORDER_NAME,           { key: 'order_name',       title: 'Order'}],
  [FieldTypes.ORDER_STATUS,         { key: 'order_status',     title: 'Status'}],
  [FieldTypes.SORT_CODE,            { key: 'sort_code',        title: 'Sort',}],
  [FieldTypes.COST,                 { key: 'cost',             title: 'Cost',}],
])

/**
 * Defines the GUI report column definitions used to render the table.
 */
export const DISPLAY_COLUMN_DEFINITIONS: FieldColumnDefinitions =
  new Map<FieldTypes, IDisplayColumnDefinition>([
    [FieldTypes.SKU,                  
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.key,             
        title: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.title,            display: true, width: 90 }],
    [FieldTypes.VENDOR,               
      { name: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.key,          
        title: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.title,         display: true, width: 100}],
    [FieldTypes.TITLE,                
      { name: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.title,          display: true, width: 200}],
    [FieldTypes.PRICE,                
      { name: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.title,          display: true, width: 85}],
    [FieldTypes.QTY,                  
      { name: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.key,             
        title: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.title,            display: true, width: 50}],
    [FieldTypes.AMOUNT,               
      { name: COLUMN_KEY_TITLES.get(FieldTypes.AMOUNT)!.key,          
        title: COLUMN_KEY_TITLES.get(FieldTypes.AMOUNT)!.title,         display: true, width: 85}],
    [FieldTypes.COST,               
      { name: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.key,          
        title: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.title,           display: true, width: 85}],
    [FieldTypes.PROCESSED_AT,         
      { name: COLUMN_KEY_TITLES.get(FieldTypes.PROCESSED_AT)!.key,    
        title: COLUMN_KEY_TITLES.get(FieldTypes.PROCESSED_AT)!.title,   display: true, width: 95}],
    [FieldTypes.COLLECTION,           
      { name: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTION)!.key,      
        title: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTION)!.title,     display: true, width: 120}],
    [FieldTypes.SUB_COLLECTION,       
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.key,  
        title: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.title, display: true, width: 140}],
    [FieldTypes.ORDER_DISCOUNT,       
      { name: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT)!.key,  
        title: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT)!.title, display: true, width: 85}],
    [FieldTypes.ORDER_DISCOUNT_CODES, 
      { name: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT_CODES)!.key,  
        title: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT_CODES)!.title, display: true, width: 70}],
    [FieldTypes.ORDER_NAME,           
      { name: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NAME)!.key,      
        title: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NAME)!.title,     display: true, width: 100}],
    [FieldTypes.ORDER_STATUS,         
      { name: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_STATUS)!.key,    
        title: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_STATUS)!.title,   display: true, width: 80}],
    [FieldTypes.SORT_CODE,            
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.key,       
        title: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.title,      display: true, width: 105}],
  ]);

/**
 * Defines the properties used to build a sales report row.
 */
export interface ISalesRecord {
  sku: string;
  vendor: string;
  title: string;
  price: number;
  quantity: number;
  total_amt: number;
  cost: number;
  processed_at: Date;
  collections: string;
  collection: string;
  sub_collection: string;
  discount: number;
  discount_codes: string;
  order_name: string;
  order_status: string;
  sort_code: string;
}

/**
 * Defines the Sales rows for the GUI report.
 */
export interface ISalesDisplayRecord {
  sku: string;
  vendor: string;
  title: string;
  price: string;
  quantity: string;
  total_amt: string;
  cost: string;
  processed_at: string;
  collections: string;
  collection: string;
  sub_collection: string;
  discount: string;
  discount_codes: string;
  order_name: string;
  order_status: string;
  sort_code: string;
}
