import * as React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import { RootContext } from './core/stores/root.context';

import { IAuthenticationStore } from './core/stores/authentication';
import { IAuthorizationStore } from './core/stores/authorization';
import { Permission } from './core/stores/authorization';

interface IProps extends RouteProps {
  shops: string[];
  permissions: Permission[];
  props?: any;
}

class SecuredRoute extends React.Component<IProps, {}> {
  public static contextType = RootContext;
  public context!: React.ContextType<typeof RootContext>;

  public props: any;

  constructor(props: IProps) {
    super(props);

    this.props = props;
  }

  public render() {
    // tslint:disable-next-line:no-console
    // console.log('securedRoute', this.props);

    const authenticationStore: IAuthenticationStore = this.context!.services.authenticationStore;
    const authorizationStore: IAuthorizationStore = this.context!.services.authorizationStore;
    
    const {component: Component, render: routeRenderer, props: componentProps, ...rest} = this.props;
    /**
     * Developer Note: pulling these properties separately so they are passed to the wrapped
     * component.
     */
    const {shops, permissions} = this.props;
    
    let pathRedirect = false;
    let isLoggedIn: boolean;
    let authorizedForShops: boolean;
    let hasPermission: boolean;

    /**
     * Redirect back only if the session is logged in but the access token is expired.
     * 
     */
    pathRedirect = authenticationStore.isLoggedIn && authenticationStore.tokenExpired();
    isLoggedIn = authenticationStore.isLoggedIn && !authenticationStore.tokenExpired();
    authorizedForShops = authorizationStore.isAuthorizedForShops(shops);
    hasPermission = authorizationStore.hasAuthority(permissions);

    // Combine the route properties with the configuration properties.
    const allProps = {...rest, ...componentProps};

    if (isLoggedIn) {
      if (authorizedForShops && hasPermission) {
        if (routeRenderer) {
          return routeRenderer(allProps);
        } else {
          return ( <Component {...allProps} /> );
        }
      } else {
        return ( <Redirect to={{pathname: '/login'}} /> );
      }
    } else {
      // Not logged in. Always redirect to the login page.
      return (
        <Redirect to={{ 
            pathname: '/login',
            state: { from: rest.location, pathRedirect, securityReferral: true }
          }} 
        />
      );
    }
  }
}

export default SecuredRoute;
