import * as React from 'react';

import classNames from 'classnames';

import { 
  createStyles, 
  Drawer, 
  IconButton, 
  Theme, 
  WithStyles, 
  withStyles, 
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import NavMenu from './NavMenu';

export interface IControllerProps extends WithStyles<typeof styles> {
    open: boolean;
    handleMenuToggle: () => void;
}

class NavMenuController extends React.Component<IControllerProps> {
  public props: IControllerProps;

  constructor(props: IControllerProps) {
    super(props);
    this.props = props
  }

  public render() {
    const { open, classes } = this.props;
 
    return (
      <div className={classes.root}>
            <Drawer
                variant="temporary"
                anchor={'left'}
                open={open}
                classes={{
                    paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                onClose={this.props.handleMenuToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <div className={classes.navMenuIcon}>
                    <IconButton onClick={this.props.handleMenuToggle}>
                    <ChevronLeftIcon />
                    </IconButton>
                </div>
                {open && 
                  <NavMenu handleItemSelect={this.props.handleMenuToggle} />
                }
            </Drawer>
      </div>
    );
  }
}

const drawerWidth = 240;
const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  navMenuIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
});

export default withStyles(styles)(NavMenuController);
