import axios from 'axios';

import { AxiosResponse } from 'axios';

import { IGetShopsResponse } from '../models';

import { IApiProviderUtils } from '../api.provider.utils';

export interface IShopsProvider {
  retrieveShops(): Promise<IGetShopsResponse>;
}

export class ShopsProvider implements IShopsProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  public async retrieveShops(): Promise<IGetShopsResponse> {
    const url: string = this.baseApiUrl + 'shopify/shops';
    const headers = this.apiProviderUtils.buildDefaultHeaders();

    return axios.get<IGetShopsResponse>(url, { headers })
      .then((response: AxiosResponse): any => {
        // Note: ImportApp returns shops data as an object. New version, 'AdminServices', returns
        //       as an object with 'shops' property.
        let shops: IGetShopsResponse[];
        // tslint:disable-next-line:no-console
        // console.log('retrieveShops', response);

        if (response.data.shops) {
          shops = response.data.shops;
        } else {
          shops = response.data as IGetShopsResponse[];
        }
        return { shops };
      })
      .catch(this.apiProviderUtils.handleApiError);
  }
}
