import axios from 'axios';

import { AxiosResponse } from 'axios';

import { IGetInventoryResponse, IInventoryQueryOptions } from '../models';

import { IApiProviderUtils } from '../api.provider.utils';

export interface IInventoryProvider {
  retrieveInventory(
    shop: string, 
    appId: string,
    options?: IInventoryQueryOptions): Promise<IGetInventoryResponse>;
}

export class InventoryProvider implements IInventoryProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  public async retrieveInventory(
    shop: string, 
    appId: string, 
    options?: IInventoryQueryOptions): Promise<IGetInventoryResponse>
  {
    const headers = this.apiProviderUtils.buildDefaultHeaders();

    const url: string = this.baseApiUrl + `shopifyapi/${shop}/${appId}/inventory`;
    const fullOptions = options || {};
    const queryUrl = this.apiProviderUtils.buildQueryString(url, fullOptions);

    return axios.get<IGetInventoryResponse>(queryUrl, { headers })
      .then((response: AxiosResponse): any => {
        // tslint:disable-next-line:no-console
        // console.log('retrieveInventory', response.data);
        const responseData = response.data as IGetInventoryResponse;

        return responseData;
      })
      .catch(this.apiProviderUtils.handleApiError);
  }
}
