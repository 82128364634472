import { Permission } from '../stores/authorization/permission';

export type AppComponentName = 'Login' | 'TildeReports' | 'Dashboard' | 'About' | 'Webhooks';

export interface IShopAuthorization {
  shop: string;
  action: string;
}

export enum CONFIGURATION_ITEM_TYPES {
  FOLDER,
  LINK,
  ROUTER_PATH,
  SEPARATOR,
  EXTERNAL_LINK,
  EXTERNAL_TAB_LINK
}

export interface BadgeItem {
  value: string;
  color: string;
}

export interface Separator {
  title: string;
  type?: CONFIGURATION_ITEM_TYPES;
}

export interface IRouteProps {
  [prop: string]: string;
}

export interface IRouteConfiguration {
  exact: boolean,
  secured: boolean,
  componentName: AppComponentName,
  permissions?: Permission[];
  shops?: string[];
  props?: IRouteProps;
}

export interface IRoute {
  path: string,
  exact: boolean,
  secured: boolean,
  componentName: AppComponentName,
  permissions?: Permission[];
  shops?: string[];
  props?: IRouteProps;
}

export interface IConfigurationItem {
  handle: string;
  type: CONFIGURATION_ITEM_TYPES;
  path?: string;

  urlSegments?: string[];
  title?: string;
  icon?: string;
  badge?: BadgeItem[];
  separator?: Separator;
  children?: IConfigurationItem[];
  quickLink?: boolean;
  // Flag indicating that the item is visible in the navigation menu.
  navItem?: boolean;
  permissions?: Permission[];
  shopAuthorizations?: IShopAuthorization[];
  routeConfiguration?: IRouteConfiguration;
}

/**
 * Application Pages
 */
export const loginPage: IConfigurationItem = {
  handle: 'login-page',
  type: CONFIGURATION_ITEM_TYPES.LINK,

  path: 'login',
  title: 'Login',
  icon: 'av_timer',
  quickLink: false,
  navItem: false,
  routeConfiguration: {
    exact: true,
    secured: false,
    componentName: 'Login',
  }
};

export const dashboardPage: IConfigurationItem = {
  handle: 'dashboard-page',
  type: CONFIGURATION_ITEM_TYPES.LINK,

  path: '',
  title: 'Dashboard',
  icon: 'av_timer',
  quickLink: false,
  navItem: true,
  permissions: [{ subject: 'home', view: true, manage: false, execute: false }],
  routeConfiguration: {
    exact: true,
    secured: true,
    componentName: 'Dashboard',
  }
};

export const aboutPage: IConfigurationItem = {
  handle: 'about-page',
  type: CONFIGURATION_ITEM_TYPES.LINK,

  path: 'about',
  title: 'About',
  icon: 'av_timer',
  quickLink: false,
  navItem: true,
  permissions: [{ subject: 'home', view: true, manage: false, execute: false }],
  routeConfiguration: {
    exact: true,
    secured: true,
    componentName: 'About',
  }
};

/**
 * TildeReports Folder
 */
export const devReportsPage: IConfigurationItem = {
  type: CONFIGURATION_ITEM_TYPES.LINK,
  handle: 'devReports-page',

  path: 'dev1-22fish',
  title: 'Dev Reports',
  permissions: [
    { subject: 'tildeReports', view: true, manage: false, execute: false }
  ],
  shopAuthorizations: [{ shop: 'dev1-22fish', action: 'view' }],
  quickLink: true,
  navItem: true,
  routeConfiguration: {
    exact: true,
    secured: true,
    componentName: 'TildeReports',
    props: { shop: 'dev1-22fish', appId: 'admin' },
  }
};

export const tildeReportsPage: IConfigurationItem = {
  type: CONFIGURATION_ITEM_TYPES.LINK,
  handle: 'tildeReports-page',

  path: 'tilde-3',
  title: 'Tilde Reports',
  permissions: [
    { subject: 'tildeReports', view: true, manage: false, execute: false }
  ],
  shopAuthorizations: [{ shop: 'tilde-3', action: 'view' }],
  quickLink: true,
  navItem: true,
  routeConfiguration: {
    exact: true,
    secured: true,
    componentName: 'TildeReports',
    props: { shop: 'tilde-3', appId: 'admin' },
  }
};

export const reportsFolder: IConfigurationItem = {
  type: CONFIGURATION_ITEM_TYPES.FOLDER,
  handle: 'reportsFolder',

  path: 'tilde-rpts',
  title: 'Tilde Applications',
  icon: 'apps',
  permissions: [
    { subject: 'tildeReports', view: true, manage: false, execute: false }
  ],
  shopAuthorizations: [
    { shop: 'dev1-22fish', action: 'view' },
    { shop: 'tilde-3', action: 'view' }
  ],
  children: [devReportsPage, tildeReportsPage]
};

/**
 * Webhooks Administration Folder
 */
export const devWebhooksPage: IConfigurationItem = {
  type: CONFIGURATION_ITEM_TYPES.LINK,
  handle: 'devWebhooks-page',

  path: 'dev-webhooks',
  title: 'Dev Webhooks',
  permissions: [
    { subject: 'shopApps', view: true, manage: false, execute: false },
    { subject: 'webhooks', view: true, manage: false, execute: false }
  ],
  shopAuthorizations: [{ shop: 'dev1-22fish', action: 'view' }],
  quickLink: true,
  navItem: true,
  routeConfiguration: {
    exact: true,
    secured: true,
    componentName: 'Webhooks',
    props: { shop: 'dev1-22fish', appId: 'admin' },
  }
};

export const tildeWebhooksPage: IConfigurationItem = {
  type: CONFIGURATION_ITEM_TYPES.LINK,
  handle: 'tildeWebhooks-page',

  path: 'tilde-webhooks',
  title: 'Tilde Webhooks',
  permissions: [
    { subject: 'shopApps', view: true, manage: false, execute: false },
    { subject: 'webhooks', view: true, manage: false, execute: false }
  ],
  shopAuthorizations: [{ shop: 'tilde-3', action: 'view' }],
  quickLink: true,
  navItem: true,
  routeConfiguration: {
    exact: true,
    secured: true,
    componentName: 'Webhooks',
    props: { shop: 'tilde-3', appId: 'admin' },
  }
};

export const webhooksFolder: IConfigurationItem = {
  type: CONFIGURATION_ITEM_TYPES.FOLDER,
  handle: 'webhooksFolder',

  path: 'webhooks',
  title: 'Webhooks Management',
  icon: 'apps',
  permissions: [
    { subject: 'shopApps', view: true, manage: false, execute: false },
    { subject: 'webhooks', view: true, manage: false, execute: false }
  ],
  shopAuthorizations: [
    { shop: 'dev1-22fish', action: 'view' },
    { shop: 'tilde-3', action: 'view' }
  ],
  children: [devWebhooksPage, tildeWebhooksPage]
};

/**
 * Menu items
 */
export const reportsSeparator: IConfigurationItem = {
  handle: 'reports-separator',
  type: CONFIGURATION_ITEM_TYPES.SEPARATOR,

  title: 'Reports',
  icon: '',
  permissions: [
    { subject: 'tildeReports', view: true, manage: false, execute: false }
  ]
};

export const adminSeparator: IConfigurationItem = {
  handle: 'admin-separator',
  type: CONFIGURATION_ITEM_TYPES.SEPARATOR,

  title: 'Administration',
  icon: '',
  permissions: [
    { subject: 'shopApps', view: true, manage: false, execute: false }
  ]
};

/**
 * The top level menu configuration.
 */
export const MENU_CONFIGURATION: IConfigurationItem = {
  handle: 'root',
  type: CONFIGURATION_ITEM_TYPES.ROUTER_PATH,
  path: '',

  children: [
    loginPage,
    dashboardPage,
    aboutPage,

    reportsSeparator,
    reportsFolder,

    adminSeparator,
    webhooksFolder
  ]
};
