import { AxiosError } from 'axios';

import { IEnvironmentStore } from '../../stores/environment';
import { ISessionStore } from '../../stores/session';

import { HttpError, ServerDownError, UnauthorizedAccessError } from './models/exceptions';

export interface IApiProviderUtils {
  buildDefaultHeaders(): any;
  buildQueryString(url: string, options: any): string;
  getBaseApiUrl(): string;
  handleApiError (error: AxiosError | any): Promise<HttpError>;
  buildFieldOption(options?: object, fields?: string[]): object;
}

export class ApiProviderUtils implements IApiProviderUtils {
  private baseApiUrl: string;
  private sessionStore: ISessionStore;

  constructor(environmentStore: IEnvironmentStore, sessionStore: ISessionStore) {
    this.baseApiUrl =  environmentStore.getBaseServerUrl();
    this.sessionStore = sessionStore;
  }

  public buildDefaultHeaders(): any {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    const sessionAccessToken = this.sessionStore.getSession().accessToken;
    if (sessionAccessToken) {
      headers['Authorization'] = 'Bearer ' + sessionAccessToken;
    }

    return headers;
}

  public buildQueryString(url: string, options: any): string {
    let queryUrl = url;
    const queryString =
            Object.keys(options).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(options[k])}`).join('&');

    if (queryString.length > 0) {
      queryUrl += '?' + queryString;
    }
        
    return queryUrl;
  }

  public getBaseApiUrl(): string {
    return this.baseApiUrl;
  }
  
  /**
   * Handles api http errors.
   *
   * @param error The api error.
   * @return void An ErrorObservable<IApiError> is thrown with the http error.
   */
  public handleApiError (error: AxiosError | any): Promise<HttpError> {
    let responseError: HttpError = new HttpError(-1, 'Http Error');

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // If this is a 401(Unauthorized) error the access token is invalid or has timed out.
      const status = error.response.status;
      if (status === 401) {
        responseError = new UnauthorizedAccessError();
      } else if (status === 0) {
        responseError = new ServerDownError();
      } else {
        // Some other http error
        const err = error.response.statusText || error.message;
        const message = `${status} - ${status || ''}: body - ${err}`;
        responseError = new HttpError(status, message);
      }
    } else if (error.request) {
      // tslint:disable-next-line:no-console 
      // console.log('No Server response  error: %s  \\n  %s', error.request.status + '', JSON.stringify(error.request));

      // The request was made but no response was received.
      if (error.request.status === 0) {
        responseError = new ServerDownError();
      } else {
        responseError = new HttpError(
          -1,
          error.message ? error.message : error.toString()
        )
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      // tslint:disable-next-line:no-console
      console.log('Error', error.message);

      // Probably an execution exception
      responseError.message = error.message ? error.message : error.toString();
    }

    // tslint:disable-next-line:no-console
    console.error('Api detected http error: ' + JSON.stringify(responseError));
    return Promise.reject(responseError);
  }

  public buildFieldOption(options?: object, fields?: string[]): object {
    const fullOptions = options || {};

    if (fields && fields.length > 0) {
      fullOptions['fields'] = fields.join(',');
    }

    return fullOptions;
  }
}
