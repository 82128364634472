import { Column, TableColumnWidthInfo } from '@devexpress/dx-react-grid';

import { FieldColumnDefinitions, FieldTypes } from './reportDefinitions';

export interface IReportUtilities {
  sortCollections(collections: string[]): string[];
  getCollectionsFilters(): string[];
  findMainCollection(collections: string[]): string;
  findSubCollection(collections: string[]): string;
  formatSku(sku: string): string;
  getTagsToCollectionsMap(): Map<string, ICollectionInfo>; 
  buildDisplayColumns(fields: FieldTypes[], columnDefinitions: FieldColumnDefinitions): Column[];
  buildDisplayColumnWidthInfo(fields: FieldTypes[], columnDefinitions: FieldColumnDefinitions): TableColumnWidthInfo[];
}

export const ALL_COLLECTION_FILTER = 'All';
export const NONE_COLLECTION_FILTER = 'none';

/**
 * List of main product collections. These are used to set the collections
 * array reordered to have the main category as the first element.
 */
const mainCollections = [
  'Accessories',
  'Gifts',
  'Jewelry',
  'Tabletop',
  'Home Decor',
  'Bags',
];


export interface ICollectionInfo {
  title: string;
  main_collection: boolean;
  main_collection_title: string;
}

const TAG_TO_COLLECTIONS_MAP = new Map<string, ICollectionInfo>([
  ['Accessories', {title: 'Accessories', main_collection: true, main_collection_title: ''}],
    ['Eyewear',             {title: 'Eyewear', main_collection: false, main_collection_title: 'Accessories'}],
    ['hair clips',          {title: 'Hair Clips', main_collection: false, main_collection_title: 'Accessories'}],
    ['Hair sticks',         {title: 'Hair Clips', main_collection: false, main_collection_title: 'Accessories'}],
    ['Wallets',             {title: 'Wallets', main_collection: false, main_collection_title: 'Accessories'}],
    ['Scarves',             {title: 'Scarves', main_collection: false, main_collection_title: 'Accessories'}],
    ['Watches',             {title: 'Watches', main_collection: false, main_collection_title: 'Accessories'}],

  ['Bags',        {title: 'Bags', main_collection: true, main_collection_title: ''}],
    ['Handbags',            {title: 'Handbags', main_collection: false, main_collection_title: 'Bags'}],
    ['Backpacks',           {title: 'Backpacks', main_collection: false, main_collection_title: 'Bags'}],
    ['cosmetic bags',       {title: 'Cosmetic Bags', main_collection: false, main_collection_title: 'Bags'}],
    ['Other Bags',          {title: 'Other Bags', main_collection: false, main_collection_title: 'Bags'}],

  ['Gifts',       {title: 'Gifts', main_collection: true, main_collection_title: ''}],
    ['Calendars & Planners', {title: 'Calendars & Planners', main_collection: false, main_collection_title: ''}],
    ['Candles',             {title: 'Candles', main_collection: false, main_collection_title: 'Gifts'}],
    ['Greeting Cards',      {title: 'Greeting Cards', main_collection: false, main_collection_title: 'Gifts'}],
    ['Lotions & Fragrance', {title: 'Lotions & Fragrance', main_collection: false, main_collection_title: 'Gifts'}],
    ['Make Up',             {title: 'Make Up', main_collection: false, main_collection_title: 'Gifts'}],
    ['Misc Gifts',          {title: 'Misc Gifts', main_collection: false, main_collection_title: 'Gifts'}],
    ['Soaps & Bath Bombs',  {title: 'Soaps & Bath Bombs', main_collection: false, main_collection_title: 'Gifts'}],
    ['Stationery',          {title: 'Stationery', main_collection: false, main_collection_title: 'Gifts'}],

  ['Home Decor',  {title: 'Home Decor', main_collection: true, main_collection_title: ''}],
    ['Art',                 {title: 'Art', main_collection: false, main_collection_title: 'Home Decor'}],
    ['Clocks',              {title: 'Clocks', main_collection: false, main_collection_title: 'Home Decor'}],
    ['Prints',              {title: 'Prints', main_collection: false, main_collection_title: 'Home Decor'}],
    ['vases & planters',    {title: 'Vases & Planters', main_collection: false, main_collection_title: 'Home Decor'}],

  ['Jewelry',     {title: 'Jewelry', main_collection: true, main_collection_title: ''}],
    ['Bracelets & Cuffs',   {title: 'Bracelets & Cuffs', main_collection: false, main_collection_title: 'Jewelry'}],
    ['earrings',            {title: 'Earrings', main_collection: false, main_collection_title: 'Jewelry'}],
    ['Necklaces',           {title: 'Necklaces', main_collection: false, main_collection_title: 'Jewelry'}],
    ['Pins',                {title: 'Pins', main_collection: false, main_collection_title: 'Jewelry'}],
    ['Rings',               {title: 'Rings', main_collection: false, main_collection_title: 'Jewelry'}],

  ['Tabletop',    {title: 'Tabletop', main_collection: true, main_collection_title: ''}],
    ['Bowls & Plates',      {title: 'Bowls & Plates', main_collection: false, main_collection_title: 'Tabletop'}],
    ['Coasters & Trivets',  {title: 'Coasters & Trivets', main_collection: false, main_collection_title: 'Tabletop'}],
    ['Misc. Tabletop',      {title: 'Misc Tabletop', main_collection: false, main_collection_title: 'Tabletop'}],
    ['Mugs & Glasses',      {title: 'Mugs & Glasses', main_collection: false, main_collection_title: 'Tabletop'}],
    ['Placemats',           {title: 'Placemats', main_collection: false, main_collection_title: 'Tabletop'}],
    ['Tea Towels',          {title: 'Tea Towels', main_collection: false, main_collection_title: 'Tabletop'}],

  ['New',                 {title: 'New', main_collection: false, main_collection_title: ''}],
  ['Holiday',             {title: 'Holiday', main_collection: false, main_collection_title: ''}],

  // These should have associated products as they are not in any of the main menu collections.
  ['hats & gloves',       {title: 'Hats & Gloves', main_collection: false, main_collection_title: ''}],
  ['Blankets',            {title: 'Blankets', main_collection: false, main_collection_title: ''}],
  ['Frames',              {title: 'Frames', main_collection: false, main_collection_title: ''}],
  ['Vases',               {title: 'Vases', main_collection: false, main_collection_title: ''}],
  ['Vases & Frames',      {title: 'Vases & Frames', main_collection: false, main_collection_title: ''}],
  ['Art & Prints',        {title: 'Art & Prints', main_collection: false, main_collection_title: ''}],
  ['Barrettes',           {title: 'Barrettes', main_collection: false, main_collection_title: ''}],
  ['Notebooks',           {title: 'Notebooks', main_collection: false, main_collection_title: ''}],
]);


export class ReportUtilities implements IReportUtilities {
  /**
   * Rearrange the collections array so the main category collection is the first
   * in the list.
   * 
   * @param collections The array of collections to rearrange.
   */
  public sortCollections(collections: string[]): string[] {
    if (collections.length === 0) {
      return collections;
    }

    // Main collection is the first item.
    if (mainCollections.includes(collections[0])) {
      return collections;
    }

    if (collections.length === 2) {
      return collections.reverse();
    }

    const rearrangedCollections: string[] = [];
    collections.forEach(collection => {
      if (mainCollections.includes(collection)) {
        rearrangedCollections.unshift(collection)
      } else {
        rearrangedCollections.push(collection);
      }
    })
    
    return rearrangedCollections;
  }

  /**
   * Find the top-most collection from the list of collections.
   */
  public findMainCollection(collections: string[]): string {
    let mainCollection = collections.find(collection => {
      return mainCollections.includes(collection);
    })

    if (!mainCollection) {
      mainCollection = 'none';
    }

    return mainCollection;
  }

  /**
   * Find the sub collection from the list of collections.
   */
  public findSubCollection(collections: string[]): string {
    // There could be more than one sub-collection.
    const subCollections: string[] = [];
    let subCollection = 'none';

    collections.forEach(collection => {
      if (!mainCollections.includes(collection)) {
        subCollections.push(collection);
      }
    })

    if (subCollections.length > 0) {
      subCollection = subCollections.join(',');
    }
    return subCollection;
  }

  /**
   * Build the list of collections filters from the known list of main collections.
   * The list is built by pre-pending 'All' and appending 'none' to the main collections
   * list.
   */
  public getCollectionsFilters(): string[] {
    const filters = [ALL_COLLECTION_FILTER].concat(mainCollections);
    filters.push(NONE_COLLECTION_FILTER);

    return filters;
  }

  /**
   * Checks if a string is undefined, null, or ''.
   *
   * @param str
   * @returns {boolean}
   */
  public isEmpty(str: string): boolean {
    return (!str || 0 === str.length);
  }

  /**
   * Validates the product sku. Valid sku's are of the form xxx-xxx.
   *
   * @param sku
   * @returns {boolean} true if the sku is valid, otherwise false
   */
  public isSkuValid(sku: string): boolean {
    // xxx-xxx
    const codeFormat = /^\d{1,3}-\d{1,3}$/;

    return !this.isEmpty(sku) && (sku.search(codeFormat) >= 0);
  }

  /**
   * Parses a Shopify Sku into it's vendor and sku id parts.
   *
   * @param sku
   * @returns {{vendor: string, id: string}}
   */
  public parseSku(sku: string): {vendor: string, id: string} {
    if (!this.isSkuValid(sku)) {
      // tslint:disable-next-line:no-console
      console.log('Invalid Sku: ' + sku);
      // throw new Error('Invalid Sku: ' + sku);
      return {vendor: '000', id: '999'};
    }
    const skuParts = sku.split('-');

    return {vendor: skuParts[ 0 ], id: skuParts[ 1 ]};
  }
  /**
   * Pads a string with leading '0'.
   *
   * @param num The string to pad.
   * @param size The size of the returned string.
   * @returns {string} The string padded with leading zeros. If the string is longer than the 'size', the returned string
   *        is the right 'size' characters of the string.
   */
  public pad(num: string, size: number): string {
    const s = '000000000' + num;
    return s.substr(s.length - size);
  }

  public formatSku(sku: string): string {
    const {vendor, id} = {...this.parseSku(sku)};

    return this.pad(vendor, 5) + '-' + this.pad(id, 5);
  }

  public getTagsToCollectionsMap(): Map<string, ICollectionInfo> {
    return TAG_TO_COLLECTIONS_MAP;
  }

  /**
   * Build an ordered list of display columns from an ordered list of column field types.
   * 
   * @param fields The ordered list of column field types.
   * @param columnDefinitions 
   */  
  public buildDisplayColumns(fields: FieldTypes[], columnDefinitions: FieldColumnDefinitions): Column[] {
    const columns: Column[] = [];

    fields.forEach((field) => {
      if (columnDefinitions.has(field)) {
        const columnDefinition = columnDefinitions.get(field)!;
        
        if (columnDefinition.display) {
          columns.push(columnDefinition);
        }
      }
    })

    return columns;
  }

  public buildDisplayColumnWidthInfo(fields: FieldTypes[], columnDefinitions: FieldColumnDefinitions): TableColumnWidthInfo[] {
    const columns: TableColumnWidthInfo[] = [];

    fields.forEach((field) => {
      if (columnDefinitions.has(field)) {
        const columnDefinition = columnDefinitions.get(field)!;

        if (columnDefinition.display) {
          columns.push({columnName: columnDefinition.name, width: columnDefinition.width});
        }
      }
    })

    return columns;
  }

}
