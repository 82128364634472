import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import blueGrey from '@material-ui/core/colors/blueGrey';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles,} from '@material-ui/styles';

interface IProps {
  filters: () => React.ReactNode;
  actions: () => React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    backgroundColor: blueGrey[200],
  },
}));

/** 
 * Creates single row grid that contains the report's filter and action components.
 */
const ReportToolbarBase: React.FC<IProps> = (props) => {
  // tslint:disable-next-line:no-console
  // console.log('LoginPage', this.props);
  
  const classes = useStyles();
  const { actions, filters } = props;
   
  return (
    <main className={classes.main}>
      <Grid container={true} justify='space-between'>
        <Grid item={true} >
          <span>{filters()}</span>
        </Grid>
        <Grid item={true} >
        <span>{actions()}</span>
        </Grid>
      </Grid>
    </main>
  );
}

export default ReportToolbarBase;
