import * as React from 'react';

import { CONFIGURATION_ITEM_TYPES, IConfigurationItem, MENU_CONFIGURATION  } from '../core/configuration/app.configuration';
import { IAppConfigurationService } from '../core/configuration/app.configuration.service';

import { 
  createStyles, 
  Divider, 
  List, 
  Theme, 
  WithStyles, 
  withStyles, 
} from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';

import { RootContext } from '../core/stores/root.context';

import NavMenuFolder from './NavMenuFolder';
import NavMenuItem from './NavMenuItem';
import { INavLinkItem } from './NavMenuItem';

export interface INavProps extends WithStyles<typeof styles> {
    handleItemSelect: () => void;
}

class NavMenu extends React.Component<INavProps, {}> {
  public static contextType = RootContext;
  public context!: React.ContextType<typeof RootContext>;

  public props: INavProps;
  
  constructor(props: INavProps) {
    super(props);
    this.props = props
  }

  /**
   * Builds the navigation menu from the app configuration. 
   */
  public buildMenu(appConfigurationService: IAppConfigurationService) {
    const menuConfiguration: IConfigurationItem = appConfigurationService.buildConfiguration(MENU_CONFIGURATION);

      // tslint:disable-next-line:no-console
      // console.log('menu configuration', menuConfiguration);

      return this.buildMenuItem(menuConfiguration);
  }

  public buildMenuItem(menuItem: IConfigurationItem, key = 0 ): any {
    switch(menuItem.type) {
      case CONFIGURATION_ITEM_TYPES.ROUTER_PATH:
        if (menuItem.children) {
          return menuItem.children.map((child, index) => this.buildMenuItem(child, index));
        }
        break;

      case CONFIGURATION_ITEM_TYPES.LINK:
        return this.buildNavMenuItem(menuItem, this.props.handleItemSelect, key);

      case CONFIGURATION_ITEM_TYPES.SEPARATOR:
        return (<Divider key={key} />);

      case CONFIGURATION_ITEM_TYPES.FOLDER:
        return this.buildNavMenuFolder(menuItem, this.props.handleItemSelect);
    }
  }

  public render() {
    const appConfigurationService: IAppConfigurationService = this.context!.services.appConfigurationService;
    
    const { classes } = this.props;

    return (
      <div className={classes.menuHeader} >
        <Divider />
        <List>
          {this.buildMenu(appConfigurationService)}
        </List>
      </div>
    )
  }

  public buildNavMenuItem(menuItem: IConfigurationItem, handleItemSelect: () => void, key = 0 ) {
    if (!menuItem.navItem) {
      return null;
    }
    
    const item: INavLinkItem = {
      text: menuItem.title as string,
      path: (menuItem.urlSegments as string[]).join('/'),
      icon: () => <HomeIcon />,
    }

    return (
      <NavMenuItem 
        handleItemSelect={handleItemSelect} 
        item={item}
        key={key} 
      />
    )
  }

  public buildNavMenuFolder(menuItem: IConfigurationItem, handleItemSelect: () => void ) {
    const folderItems: INavLinkItem[] = (menuItem.children as IConfigurationItem[]).map(child => {
      return {
        text: child.title as string,
        path: (child.urlSegments as string[]).join('/'),
        icon: () => <HomeIcon />,
      }
    })

    return (
      <NavMenuFolder text={menuItem.title as string} handleItemSelect={handleItemSelect} items={folderItems} key={456} />
    )
  }
}

const styles = (theme: Theme) => createStyles({
  menuHeader: theme.mixins.toolbar,
});

export default withStyles(styles)(NavMenu);
