
export interface IEnvironmentStore {
  getBaseServerUrl(): string;
  getVersion(): string;
  production(): boolean;
}

interface EnvironmentProperties {
  production: boolean;
  build: string;
  baseServerUrl: string;
  version: string;
}

export class EnvironmentStore implements IEnvironmentStore {
  private environmentProperties: EnvironmentProperties;

  constructor() {
    const version = process.env.REACT_APP_VERSION || 'Unknown';

    if (process.env.NODE_ENV === 'production') {
      this.environmentProperties = {
        production: true,
        build: 'production',
        baseServerUrl: 'https://adminservices.22fish.net/admin/',
        //  baseServerUrl: 'http://tildeadmin.22fish.net:63900/',
        version,
      }
    } else {
      this.environmentProperties = {
        production: false,
        build: 'development',
        //  baseServerUrl: 'https://adminservices.22fish.net/admin/',
        //  baseServerUrl: 'http://localhost:63999/',
        baseServerUrl: 'http://localhost:3001/',
        version,
      }
    }
  }

  /*
   * Returns the base server url for the current environment.
   *
   * @returns {string}
   */
  public getBaseServerUrl(): string {
    return this.environmentProperties.baseServerUrl;
  }

  /*
   * Returns current version.
   *
   * @returns {string}
   */
  public getVersion(): string {
    return this.environmentProperties.version;
  }

  /*
   * Returns true if this is a production build, otherwise false;
   *
   * @returns {string}
   */
  public production(): boolean {
    return this.environmentProperties.production;
  }
}
