import React, { useState } from 'react';

import moment from 'moment';

import { 
  createStyles, 
  makeStyles,
  Theme,
 } from '@material-ui/core';
 import CircularProgress from '@material-ui/core/CircularProgress';

 import {
  Column,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  Sorting,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';

 import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';

import ReportHeaderRow from '../components/ReportHeaderRow';
import { IReportServiceProp, withReportService } from '../withReportService';
import { InventoryReportService } from './inventory.report.service';
import { IInventoryDisplayRecord, IInventoryRecord } from './InventoryReportDefinition';
import InventoryToolbar, { IInventoryActionDates } from './InventoryToolbar';

interface IProps extends IReportServiceProp<InventoryReportService> {
}

/**
 * The inventory report component definition.
 * 
 * @param props 
 */
const InventoryReportBase: React.FC<IProps> = (props) => {
  const defaultFilterDates: IInventoryActionDates = {
    startDate: moment().date(1).toDate(),
    endDate: moment().toDate(),
  }
 
  const classes = useStyles();
  const [filterDates, setFilterDates] = useState<IInventoryActionDates>(defaultFilterDates);

  const [serverRecords, setServerRecords] = useState<IInventoryRecord[]>([]);
  const [allDisplayRows, setAllDisplayRows] = useState<IInventoryDisplayRecord[]>([]);

  // The table rows and method to set them. This is a subset of serverRecords, as it
  // is filtered by the collections filter
  const [filteredRows, setFilteredRows] = useState<IInventoryDisplayRecord[]>([]);

  // The product collections to allow filtering.
  const [filterCollections] = useState<string[]>(props.dataService.getCollectionsFilters());
  // The collection to filter.
  const [collectionFilter, setCollectionFilter] = useState<string>('All');

  const [columns] = useState<Column[]>(props.dataService.getColumns());
  const [defaultColumnWidths] = useState<TableColumnWidthInfo[]>(props.dataService.getDisplayColumnWidthInfo());
  const [sortColumnDefaults] = useState<Sorting[]>(props.dataService.getDisplaySortColumnDefaults());
  const [sortColumnExtensions] = useState<SortingState.ColumnExtension[]>(props.dataService.getDisplaySortColumns());
  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(['sub_collection']);

  // Enable/disable the loading spinner.
  const [loading, setLoading] = React.useState(false);

  // Enable/disable filtering by updatedAt date.
  const [filterByUpdatedAt, setFilterByUpdatedAt] = React.useState(false);

  // Enable/disable the toolbar action button.
  const [actionsEnabled, setActionsEnabled] = React.useState(true);

  React.useEffect(() => {
    // tslint:disable-next-line:no-console
    // console.log('InventoryReport - useEffect: ' + JSON.stringify(filterDates));
    // tslint:disable-next-line:no-console
    // console.log('InventoryReport - useEffect collectionFilter: ' + collectionFilter);
  }, [filterDates.startDate, filterDates.endDate])

  const refreshHandler = (actionDates: IInventoryActionDates) => {
    // tslint:disable-next-line:no-console
    console.log('refreshHandler- actionDates: ', actionDates);
    
    setLoading(true);
    setActionsEnabled(false);
  
    if (filterByUpdatedAt) {
      setFilterDates(actionDates);
    }

    props.dataService.retrieveInventory(actionDates.startDate, actionDates.endDate)
      .then((items: IInventoryRecord[]) => { 
        // tslint:disable-next-line:no-console
        // console.log('refreshHandler: got data', items);

        setServerRecords(items);

        const displayRows = props.dataService.buildDisplayRows(items);
        setAllDisplayRows(displayRows);

        return filterRecords(collectionFilter, displayRows);
      })
      .then(rows => {
        // tslint:disable-next-line:no-console
        // console.log('refreshHandler: filteredRows ', rows.length);

        setFilteredRows(rows);
        setLoading(false);
        setActionsEnabled(true);
      });
  }

  const exportHandler = async (actionDates: IInventoryActionDates) => {
    setLoading(true);
    setActionsEnabled(false);

    props.dataService.buildExportReport(serverRecords, actionDates.startDate, actionDates.endDate)
      .then(() => {
        setFilterDates(actionDates);
        setLoading(false);
        setActionsEnabled(true);
      });
  }
  
  /**
   * Handle a new collections filter click.
   * 
   * @param actionDates The transaction date filters.
   */
  const collectionFilterHandler = (collection: string) => {
    // tslint:disable-next-line:no-console
    // console.log('collectionFilterHandler: ', collection, '  ', collectionFilter);

    setLoading(true);
    setActionsEnabled(false);
    setCollectionFilter(collection);
    
    if (collection === 'All') {
      setHiddenColumnNames(['sub_collection']);
    } else {
      setHiddenColumnNames(['collections']);
    }

    setFilteredRows(filterRecords(collection, allDisplayRows));
    setLoading(false);
    setActionsEnabled(true);
  }
  /**
   * Handle filter by UpdatedAt dates flag handling.
   * 
   * @param filterByUpdatedAt True if updatedAt date filtering should be used.
   */
  const updatedAtFilterHandler = (useUpdatedAt: boolean) => {
    // tslint:disable-next-line:no-console
    // console.log('updatedAtFilterHandler: ', useUpdatedAt);

    setFilterByUpdatedAt(useUpdatedAt);
  }
  
  /**
   * Filter sales records by the sale product's main collection.
   */
  const filterRecords = (collection: string, tableRows: IInventoryDisplayRecord[]): IInventoryDisplayRecord[] => {
    if (collection === 'All') { 
      return tableRows;
    }

    const rows: IInventoryDisplayRecord[] = [];

    tableRows.forEach(record => {
      if(record.main_collection && record.main_collection === collection) {
        rows.push(record);
      }
    })

    return rows;
  }

  /**
   * Wrap the toolbar component, adding the report filters and actions properties.
   */
  const ToolbarComponentWrapper: React.FC<Toolbar.RootProps> = (toolbarProps: Toolbar.RootProps) => {
    return (
      <InventoryToolbar 
      actionsEnabled={actionsEnabled} 
      refreshAction={refreshHandler} 
      exportAction={exportHandler} 
      filterAction={collectionFilterHandler}
      collections={filterCollections}
      collectionFilter={collectionFilter}
      actionDates={filterDates}
      updatedAtHandler={updatedAtFilterHandler}
      useUpdatedAtFilter={filterByUpdatedAt}
      {...toolbarProps} />
    )
  }

  /** 
   * Build the full report page.
   */
  return (
    <main className={classes.main}>
      <Grid
        columns={columns}
        rows={filteredRows} 
      >
        <PagingState
          defaultCurrentPage={0}
          defaultPageSize={15}
        />
        <IntegratedPaging />
        <SortingState
          defaultSorting={sortColumnDefaults}
          columnExtensions={sortColumnExtensions}
        />
        <IntegratedSorting />
        <Table />
        <Toolbar
            rootComponent = {ToolbarComponentWrapper}
        />
        <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow 
          rowComponent={ReportHeaderRow}
          showSortingControls={true}
        />
        <PagingPanel pageSizes={[15, 30, 100]} />
      </Grid>
      {loading && <CircularProgress size={80} disableShrink={true} className={classes.loadingProgress} />}
    </main>
  );
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
});

export default withReportService<InventoryReportService>(InventoryReportBase, InventoryReportService);
