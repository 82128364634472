import * as Excel from 'exceljs';
import moment from 'moment';

import { FieldTypes } from '../../reportDefinitions';

import {
  addEmptyRow, 
  addSheetTitleRow,
  addSummationFormulaToRow,
  addSummationFormulaWithTitleToRow,
  buildColumns, 
  createWorkbook, 
  createWorksheet,
  findCellColumnLetterByNumber, 
  findCellColumnLetterByType,
  setHeaderStyles,
  setTitleCell,
  styleSummationRow, 
  uploadWorkbook 
} from '../../xlsx/xlsx.report.build.utilities';
import { GroupByCategoryHeaderTypes, ISalesRecord, NoGroupingCategoryHeadersTypes } from '../SalesReportDefinition';
import { SheetColumnMetaData } from './sales.xlsx.report.metadata';

export type CollectionGroupType = Map<string, ISalesRecord[]>;
export type CollectionGroupsType = Map<string, CollectionGroupType>;

export interface ISalesXlsxReportBuilder {
  buildExportReport(salesRecords: ISalesRecord[], startDate?: Date, endDate?: Date): Promise<boolean>;
}

export class SalesXlsxReportBuilder implements ISalesXlsxReportBuilder {
  /**
   * This is the xlsx report builder's main routine. It creates the xlsx workbook,
   * creates the workbook sheets, and upload's the spreadsheet.
   */
  public buildExportReport(salesRecords: ISalesRecord[], startDate: Date, endDate?: Date): Promise<boolean> {
    if (!endDate) {
      endDate = moment().endOf('day').toDate();
    }

    const workbook = createWorkbook('Tilde Administrator');

    // Create the sheet having no grouping.
    const noGroupingWorksheet = this.initializeWorksheet(
                workbook, 
                'No Grouping', 
                buildColumns(NoGroupingCategoryHeadersTypes, SheetColumnMetaData), 
                startDate, 
                endDate);
    this.buildNoGroupingWorksheet(noGroupingWorksheet, salesRecords);

    // Create the sheet with sales grouped by main collection and sub-collection
    const categoryGroupingWorksheet = this.initializeWorksheet(
                workbook, 
                'Category Grouping',
                buildColumns(GroupByCategoryHeaderTypes, SheetColumnMetaData), 
                startDate, 
                endDate);
    this.buildCollectionGroupingWorksheet(categoryGroupingWorksheet, salesRecords);

    return uploadWorkbook(workbook, 'SalesReport');
  }

  public initializeWorksheet(
                    workbook: Excel.Workbook, 
                    worksheetName: string,
                    columns: Array<Partial<Excel.Column>>,
                    startDate: Date, 
                    endDate: Date): Excel.Worksheet 
  {
    const worksheet = createWorksheet(workbook, worksheetName);
    worksheet.columns = columns;
    setHeaderStyles(worksheet);

    addSheetTitleRow(worksheet, NoGroupingCategoryHeadersTypes, 'Sales', startDate, endDate);

    return worksheet;
  }

  public buildNoGroupingWorksheet(worksheet: Excel.Worksheet, salesRecords: ISalesRecord[]): void {
    const rows = this.buildReportSalesRows(salesRecords);

    const excelRowNumbers: number[] = [];
    rows.forEach((row: ISalesRecord) => {
      const excelRow = worksheet.addRow(row);

      this.addSaleTotalFormula(worksheet, excelRow);
      excelRowNumbers.push(excelRow.number);
    })

    // Add the summation of all sales.
    this.addSummationRow(worksheet, excelRowNumbers);
  }

  public buildCollectionGroupingWorksheet(worksheet: Excel.Worksheet, salesRecords: ISalesRecord[]): void {
    const groupByCollections: CollectionGroupsType = this.buildGroupByCollections(salesRecords);
    const collections = Array.from(groupByCollections.keys()).sort();
    let totalsRowNumbers: number[] = [];
  
    collections.forEach((collection) => {
      const collectionTotalsRowNumbers = this.buildCollection(worksheet, collection, groupByCollections.get(collection) as CollectionGroupType);
      totalsRowNumbers = totalsRowNumbers.concat(collectionTotalsRowNumbers);
    })

    this.addGroupsTotalsRow(worksheet, totalsRowNumbers);
  }

  /**
   * Build the sales rows for a main collection and it's sub-collections.
   * 
   * @param worksheet The worksheet to add the sales rows.
   * @param collection The name of the main collection to build.
   * @param collectionGroup A map of the collection's sub-collection sales rows.
   */
  public buildCollection(worksheet: Excel.Worksheet, collection: string, collectionGroup: CollectionGroupType): number[] {
    const totalLabelCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.QTY);
    const amtTotalCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.AMOUNT);
    const subCollections = Array.from(collectionGroup.keys()).sort();
    const totalsRowNumbers: number[] = [];

    subCollections.forEach((subCollection) => {
      this.addSubCollectionTitleRow(worksheet, collection, subCollection);
  
      const rowNumbers: number[] = [];

      const rows = this.buildReportSalesRows(collectionGroup.get(subCollection) as ISalesRecord[]);
      rows.forEach((row: ISalesRecord) => {
        const excelRow = worksheet.addRow(row);
        this.addSaleTotalFormula(worksheet, excelRow);

        rowNumbers.push(excelRow.number);
      })

      const summationRow = addEmptyRow(worksheet, GroupByCategoryHeaderTypes);
  
      addSummationFormulaWithTitleToRow(
                                        worksheet, 
                                        summationRow, 
                                        rowNumbers, 
                                        totalLabelCellLetter,
                                        amtTotalCellLetter,
                                        subCollection + ' Total:');

      totalsRowNumbers.push(summationRow.number);

      addEmptyRow(worksheet, GroupByCategoryHeaderTypes);
    })
    const collectionSummationRow = addEmptyRow(worksheet, GroupByCategoryHeaderTypes);

    addSummationFormulaWithTitleToRow(
      worksheet, 
      collectionSummationRow, 
      totalsRowNumbers, 
      totalLabelCellLetter,
      amtTotalCellLetter,
      collection + ' Total:');

    addEmptyRow(worksheet, GroupByCategoryHeaderTypes);

    return [collectionSummationRow.number];
  }

  public buildReportSalesRows(salesRecords: ISalesRecord[]):  any[] {
    return salesRecords.map((salesRecord) => {
      return { 
              ...salesRecord, 
              total_amt: salesRecord.price * salesRecord.quantity,
              processed_at: moment(salesRecord.processed_at).format('MM/DD/YYYY')
            }
    })
    .sort((a, b) => {
      return a.sort_code.localeCompare(b.sort_code);
    })
  }

  /**
   * Adds a row containing the collection and subCollection to indicate a
   * new subCollection.
   */
  public addSubCollectionTitleRow(
                                  worksheet: Excel.Worksheet, 
                                  collection: string, 
                                  subCollection: string): Excel.Row 
  {
    const titleRow: Excel.Row = addEmptyRow(worksheet, GroupByCategoryHeaderTypes);
    const collectionCellLetter = findCellColumnLetterByNumber(1);
    const subCollectionCellLetter = findCellColumnLetterByNumber(2);

    // Set the collection field label and style.
    const collectionLabelCell: Excel.Cell = titleRow.getCell(collectionCellLetter);
    setTitleCell(collectionLabelCell, collection);

    // Set the subCollection field label and style.
    const subCollectionLabelCell: Excel.Cell = titleRow.getCell(subCollectionCellLetter);
    setTitleCell(subCollectionLabelCell, subCollection);

    return titleRow;
  }

  /**
   * Add a row containing the formula and sum of sales totals from the specified sheet row numbers.
   * 
   * @param worksheet The Excel sheet containing to rows used in the summation.
   * @param summationRowNumbers Array of sheet row numbers used in the summation.
   * 
   * @returns The sheet row number of the summation row.
   */
  public addSummationRow(worksheet: Excel.Worksheet, summationRowNumbers: number[]): number  {
    // Add the Amount total row, having all empty values.
    const sumRow: Excel.Row = addEmptyRow(worksheet, GroupByCategoryHeaderTypes);

    styleSummationRow(sumRow);
 
    const totalLabelCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.QTY);
    const amtTotalCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.AMOUNT);
     
    addSummationFormulaToRow(worksheet, sumRow, summationRowNumbers, totalLabelCellLetter, amtTotalCellLetter);

    return sumRow.number;
  }

  /**
   * Add a row containing the formula and sum of total amount fields from the specified sheet row numbers.
   */
  public addGroupsTotalsRow(worksheet: Excel.Worksheet, totalsRowNumbers: number[]): number {
    // Add the Amount total row, having all empty values.
    const sumRow: Excel.Row = addEmptyRow(worksheet, GroupByCategoryHeaderTypes);

    styleSummationRow(sumRow);

    const totalLabelCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.QTY);
    const amtTotalCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.AMOUNT);

    addSummationFormulaToRow(worksheet, sumRow, totalsRowNumbers, totalLabelCellLetter, amtTotalCellLetter);

    return sumRow.number;
  }

  /**
   * Add the product of the price and quantity fields. Add result and formula to 
   * the total amount field.
   *   
   * @param workSheet the worksheet to add the row.
   * @param row the sale row.
   */
  public addSaleTotalFormula(workSheet: Excel.Worksheet, row: Excel.Row): Excel.Row {
    const priceCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.PRICE);
    const qtyCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.QTY);
    const amtTotalCellLetter = findCellColumnLetterByType(GroupByCategoryHeaderTypes, FieldTypes.AMOUNT);

    // Calculate the total amount.
    const saleTotal = (row.getCell(priceCellLetter).value as number) * (row.getCell(qtyCellLetter).value as number);

    // Add the sum and formula to the totals cell.
    const totalCell: Excel.Cell = row.getCell(amtTotalCellLetter);
    totalCell.value = {formula: `PRODUCT(${priceCellLetter}${row.number},${qtyCellLetter}${row.number})`, result: saleTotal, date1904: true};

    return row;
  }

  public buildGroupByCollections(salesRecords: ISalesRecord[]): CollectionGroupsType {
    const topCollectionsGroups: CollectionGroupsType = new Map<string, Map<string, ISalesRecord[]>>();

    salesRecords.forEach((salesRecord) => {
      if (topCollectionsGroups.has(salesRecord.collection)) {
        const topCollection = topCollectionsGroups.get(salesRecord.collection);
        
        if (topCollection!.has(salesRecord.sub_collection)) {
          topCollection!.get(salesRecord.sub_collection)!.push(salesRecord);
        } else {
          topCollection!.set(salesRecord.sub_collection, [salesRecord]);
        }
      } else {
        const subCollectionGroup = new Map<string, ISalesRecord[]>();

        subCollectionGroup.set(salesRecord.sub_collection, [salesRecord]);
        topCollectionsGroups.set(salesRecord.collection, subCollectionGroup);
      }
    })

    return topCollectionsGroups;
  }

}

