import * as React from 'react';
import {
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

function NoMatch(props: RouteComponentProps<{}>) {
  return (
    <div>
      <h3>
        No match for <code>{props.location.pathname}</code>
      </h3>
    </div>
  );
}

export default withRouter(NoMatch);
