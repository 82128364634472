import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from "react-router-dom";

import { WithStyles } from '@material-ui/core';

import { IAuthenticationStore } from '../../core/stores/authentication';
import { RootContext } from '../../core/stores/root.context';

import LoginPage from './Login';

interface IProps extends RouteComponentProps<{}>, WithStyles<any> {}

class LoginRouteManager extends React.Component<IProps, {}> {
  public static contextType = RootContext;
  public context!: React.ContextType<typeof RootContext>;

  public props: IProps;
 
  constructor(props: IProps) {
    super(props);

    this.props = props;
  }

  public render() {    
    const authenticationStore: IAuthenticationStore = this.context!.services.authenticationStore;

    const isLoggedIn = authenticationStore.isLoggedIn && !authenticationStore.tokenExpired();
    const locationState: any = this.props.location && this.props.location.state;
    let from = { pathname: "/" };
    if (locationState && locationState.pathname) {
      from = locationState;
    }
    const pathRedirect = false;
    const securityReferral = false;

    /**
     * Directly after logging in, redirect to the previously viewed page only if 
     * login was directed by security routing.
     */
    if (isLoggedIn) {
      if (securityReferral) {
        if (!pathRedirect) {
          from.pathname = '/';
        }
      } else {
        from.pathname = '/';
      }
    
      return (
        <Redirect to={from} />
      )
    } 
    
    return (<LoginPage {...this.props} />);
  }
}

export default LoginRouteManager;
