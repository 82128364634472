import axios from 'axios';
import { AxiosResponse } from 'axios';

import { IGetVersionResponse, IVersion } from '../models';

import { IApiProviderUtils } from '../api.provider.utils';

export interface IVersionProvider {
  retrieveVersion(): Promise<IVersion>;
}

export class VersionProvider implements IVersionProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  public async retrieveVersion(): Promise<IVersion>
  {
    const headers = this.apiProviderUtils.buildDefaultHeaders();
    const url: string = this.baseApiUrl + `version`;

    const queryUrl = this.apiProviderUtils.buildQueryString(url, {});

    return axios.get<IGetVersionResponse>(queryUrl, { headers })
      .then((response: AxiosResponse): any => {
        // tslint:disable-next-line:no-console
        // console.log('retrieveVersion', response.data);
        const responseData = response.data as IGetVersionResponse;

        return responseData.version;
      })
      .catch(this.apiProviderUtils.handleApiError);
  }
}
