import moment from 'moment';

import { Column, TableColumnWidthInfo } from '@devexpress/dx-react-grid';

import { IApiProvider, ISalesProvider } from '../../../core/providers/api.provider';
import { 
  IApiSales,
  IApiSalesDiscount,
  IGetSalesResponse, 
  ISalesQueryOptions,
 } from '../../../core/providers/api.provider/models';

import { IReportUtilities } from '../report.utilities';
import { FieldTypes, IDisplayColumnDefinition} from '../reportDefinitions';

import { 
  DISPLAY_COLUMN_DEFINITIONS,
  ISalesDisplayRecord, 
  ISalesRecord, 
  NoGroupingCategoryHeadersTypes,
} from './SalesReportDefinition';
import { ISalesXlsxReportBuilder, SalesXlsxReportBuilder } from './xlsx/sales.xlsx.report.builder';

const delayExecution = async (): Promise<any> => {
  return new Promise((resolveCb, rejectCb) => {
      setTimeout(() => {
          resolveCb();
      },
      10);
  });
}

export interface ISalesReportService {
  retrieveSales(updatedAtStart?: Date, updatedAtEnd?: Date): Promise<ISalesRecord[]>;
  buildDisplayColumns(showCategoryColumn: boolean): Column[];
  buildDisplayColumnWidthInfo(showCategoryColumn: boolean): TableColumnWidthInfo[];
  getCollectionsFilters(): string[];
  buildExportReport(serverRecords: ISalesRecord[], startDate?: Date, endDate?: Date): void;
}

export class SalesReportService implements ISalesReportService {
  private xlsxBuilder: ISalesXlsxReportBuilder;

  constructor(
    private readonly shop: string,
    private appId: string,
    private readonly apiProvider: IApiProvider,
    private readonly reportUtilities: IReportUtilities) {
      // Dev. Note - appId is required by withReportService wrapper. This
      // bugger stuff works around not used variable compiler error.
      this.appId = '';
      if (this.appId.length > 1) {
        this.appId = '';
      }

      this.xlsxBuilder = new SalesXlsxReportBuilder();
    }

  public async retrieveSales(updatedAtStart?: Date, updatedAtEnd?: Date): Promise<ISalesRecord[]> {
    const salesProvider: ISalesProvider = this.apiProvider.getSalesProvider();

    const options: ISalesQueryOptions = {}
    if (updatedAtStart) {
      options.processed_at_min = moment(updatedAtStart).startOf('day').format();
    } else {
      options.processed_at_min = moment('2010-01-01').startOf('day').format();
    }
    if (updatedAtEnd) {
      options.processed_at_max = moment(updatedAtEnd).endOf('day').format();
    } else {
      options.processed_at_max = moment().endOf('day').format();
    }

    return salesProvider.retrieveSales(this.shop, options)
      .then((salesResponse: IGetSalesResponse) => {
        return salesResponse.sales;
      })
      .then((apiSales: IApiSales[]) => {
        return this.buildReportRecords(apiSales);
      })
      .catch ((err) => {
          // tslint:disable-next-line:no-console
           console.log('retrieveSales catch error: message - %s  type - %d   status - %d', err.message, err.type, err.status);
          throw err;
      })
  }

  public buildDisplayColumns(): Column[] {
    const columns: Column[] = [];
    
    NoGroupingCategoryHeadersTypes.forEach((headerType: FieldTypes) => {
      const columnDefinition: IDisplayColumnDefinition | undefined = DISPLAY_COLUMN_DEFINITIONS.get(headerType);

      if (columnDefinition) {
        if (columnDefinition.display) {
          columns.push({name: columnDefinition.name, title: columnDefinition.title});
        }
      }
    })

    return columns;
  }

  public buildDisplayColumnWidthInfo(): TableColumnWidthInfo[] {
    const columns: TableColumnWidthInfo[] = [];

    NoGroupingCategoryHeadersTypes.forEach((headerType: FieldTypes) => {
      const columnDefinition: IDisplayColumnDefinition | undefined = DISPLAY_COLUMN_DEFINITIONS.get(headerType);

      if (columnDefinition) {
        if (columnDefinition.display) {
          columns.push({columnName: columnDefinition.name, width: columnDefinition.width});
        }
      }
    })

    return columns;
  }

  public buildDisplayRows(salesRecords: ISalesRecord[]): ISalesDisplayRecord[] {
    const displayRecords: ISalesDisplayRecord[] = salesRecords.map((salesRecord) => {
      const totalAmt = '$' + (salesRecord.price * salesRecord.quantity).toFixed(2) + '';

      return {
        sku: salesRecord.sku,
        vendor: salesRecord.vendor,
        title: salesRecord.title,
        price: '$' + salesRecord.price.toFixed(2),
        quantity: salesRecord.quantity + '',
        total_amt: totalAmt,
        processed_at: moment(salesRecord.processed_at).format('L'),
        collections: salesRecord.collections,
        collection: salesRecord.collection,
        sub_collection: salesRecord.sub_collection,
        discount: '$' + salesRecord.discount.toFixed(2),
        discount_codes: salesRecord.discount_codes,
        order_name: salesRecord.order_name,
        order_status: salesRecord.order_status,
        sort_code: salesRecord.sort_code,
        cost: '$' + salesRecord.cost.toFixed(2),
      };
    })

    return displayRecords;
  }

  public getCollectionsFilters(): string[] {
    return this.reportUtilities.getCollectionsFilters();
  }

  
  public buildReportRecords(apiSales: IApiSales[]): ISalesRecord[] {
    const salesRecords: ISalesRecord[] = apiSales.map((apiSale: IApiSales) => {

      const price = parseFloat(apiSale.price);
      const totalAmt = price * apiSale.quantity;
      const cost = parseFloat(apiSale.cost);
      const collections = apiSale.collections ? this.reportUtilities.sortCollections(apiSale.collections).join() : '';
      const collection = this.reportUtilities.findMainCollection(apiSale.collections);
      const subCollection = this.reportUtilities.findSubCollection(apiSale.collections);
      let discountCodes = '';
      apiSale.discount_codes.forEach((discount: IApiSalesDiscount) => {
        discountCodes +=
          discount.code + ': ' + discount.type + '  ';
      });

      return {
        sku: apiSale.sku,
        vendor: apiSale.vendor,
        title: apiSale.title,
        price,
        quantity: apiSale.quantity,
        total_amt: totalAmt,
        processed_at: apiSale.processed_at,
        collections,
        sub_collection: subCollection,
        collection,
        discount: parseFloat(apiSale.discount),
        discount_codes: discountCodes,
        order_name: apiSale.order_name,
        order_status: apiSale.financial_status,
        sort_code: apiSale.sort_code,
        cost
      };
    })

    return salesRecords;
  }
  
  public async buildExportReport(salesRecords: ISalesRecord[], startDate?: Date, endDate?: Date): Promise<boolean> {
    return new Promise(async (resolve) => {
      await delayExecution();
      this.xlsxBuilder.buildExportReport(salesRecords, startDate, endDate);

      resolve(true);
    })
  }
}
