import moment from 'moment';

import { Column, TableColumnWidthInfo } from '@devexpress/dx-react-grid';

import { IApiProvider, IOrdersProvider } from '../../../core/providers/api.provider';
import { 
  IApiOrder,
  IApiOrderDiscount,
  IApiRefund,
  IApiRefundLineItem,
  IGetOrdersResponse, 
  IOrdersQueryOptions,
  SHOPIFY_ORDER_FIELD_NAMES,
 } from '../../../core/providers/api.provider/models';

import { IReportUtilities, ReportUtilities } from '../report.utilities';
import { DISPLAY_COLUMN_DEFINITIONS, HeaderFieldTypes, ITransactionDisplayRecord, ITransactionRecord } from './transactionsReportDefinition';
import { ITransactionsXlsxReportBuilder, TransactionsXlsxReportBuilder } from './xlsx/transactions.xlsx.report.builder';

const delayExecution = async (): Promise<any> => {
  return new Promise((resolveCb, rejectCb) => {
      setTimeout(() => {
          resolveCb();
      },
      10);
  });
}

export interface ITransactionsReportService {
  retrieveTransactions(processed_at_min?: Date, uprocessed_at_max?: Date): Promise<ITransactionRecord[]>;
  buildDisplayColumns(showCategoryColumn: boolean): Column[];
  buildDisplayColumnWidthInfo(): TableColumnWidthInfo[];
  buildExportReport(serverRecords: ITransactionRecord[], startDate?: Date, endDate?: Date): void;
}

export class TransactionsReportService implements ITransactionsReportService {
  private xlsxBuilder: ITransactionsXlsxReportBuilder;
  private reportUtilities: IReportUtilities;

  constructor(
    private readonly shop: string,
    private appId: string,
    private readonly apiProvider: IApiProvider) {

      this.xlsxBuilder = new TransactionsXlsxReportBuilder();
      this.reportUtilities = new ReportUtilities();
    }

  public async retrieveTransactions(processed_at_min?: Date, processed_at_max?: Date): Promise<ITransactionRecord[]> {

    const orderFields: SHOPIFY_ORDER_FIELD_NAMES[] = [
      'id',
      'order_number',
      'source_name',
      'total_price',
      'total_discounts',
      'discount_codes',
      'processed_at',
      'refunds',
      'financial_status',
    ];

    const ordersProvider: IOrdersProvider = this.apiProvider.getOrdersProvider();

    const options: IOrdersQueryOptions = { 
      fields: orderFields,
      processed_at_min: processed_at_min,
      processed_at_max: processed_at_max,
    };

    if (!processed_at_min)
    {
      options.updated_at_min = moment().date(1).startOf('day').toDate();
    }
    if (!processed_at_max)
    {
      options.updated_at_max = moment().endOf('day').toDate();
    }

    return ordersProvider.retrieveOrders(this.shop, this.appId, options)
      .then((ordersResponse: IGetOrdersResponse) => {
        return ordersResponse.orders;
      })
      .then((apiOrders: IApiOrder[]) => {
        return this.buildReportRecords(apiOrders);
      })
      .catch ((err) => {
          // tslint:disable-next-line:no-console
           console.log('retrieveOrders catch error: message - %s  type - %d   status - %d', err.message, err.type, err.status);
          throw err;
      })
  }

  public buildDisplayColumns(showCategoryColumn: boolean): Column[] {
    return this.reportUtilities.buildDisplayColumns(HeaderFieldTypes, DISPLAY_COLUMN_DEFINITIONS);
  }

  public buildDisplayColumnWidthInfo(): TableColumnWidthInfo[] {
    return this.reportUtilities.buildDisplayColumnWidthInfo(HeaderFieldTypes, DISPLAY_COLUMN_DEFINITIONS);
  }

  public buildDisplayRows(tranactionRecords: ITransactionRecord[]): ITransactionDisplayRecord[] {
    const displayRecords: ITransactionDisplayRecord[] = tranactionRecords.map((transactionRecord) => {
      const totalPrice = '$' + transactionRecord.total.toFixed(2);
      const totalDiscount = '$' + transactionRecord.discount.toFixed(2);
      const refunds = '$' + transactionRecord.refunds.toFixed(2);

      return {
        id: transactionRecord.id + '',
        order_number: transactionRecord.order_number + '',
        source: transactionRecord.source,
        total: totalPrice,
        discount: totalDiscount,
        discount_code: transactionRecord.discount_code,
        refunds,
        date: moment(transactionRecord.date).format('L'),
      };
    })

    return displayRecords;
  }
  
  /**
   * Extact order information to create the transaction records for the GUI and export reports.
   * 
   * @param apiOrders 
   */
  public buildReportRecords(apiOrders: IApiOrder[]): ITransactionRecord[] {
    const transactionRecords: ITransactionRecord[] = apiOrders.map((apiOrder: IApiOrder) => {
      let discountCodes = '';
 
      if (apiOrder.discount_codes) {
        apiOrder.discount_codes.forEach((discount: IApiOrderDiscount) => {
          discountCodes +=
            discount.code + ': ' + discount.type + '  ';
        });
      }

      let refundTotal = 0
      if (apiOrder.financial_status === 'refunded') {
        apiOrder.refunds!.forEach((refund: IApiRefund) => {
          refund.refund_line_items.forEach((refundLineItem: IApiRefundLineItem) => {
            refundTotal += parseFloat(refundLineItem.line_item.price);
          });
        });
      }

      return {
        id: apiOrder.id as number,
        order_number: apiOrder.order_number as number,
        source: apiOrder.source_name as string,
        total: parseFloat(apiOrder.total_price as string),
        discount: parseFloat(apiOrder.total_discounts as string),
        discount_code: discountCodes,
  
        date: moment(apiOrder.processed_at).toDate(),
        refunds: refundTotal,
        financial_status: apiOrder.financial_status as string,
      };
    })

    return transactionRecords;
  }
  
  public async buildExportReport(transactionRecords: ITransactionRecord[], startDate?: Date, endDate?: Date): Promise<boolean> {
    return new Promise(async (resolve) => {
      await delayExecution();
      this.xlsxBuilder.buildExportReport(transactionRecords, startDate, endDate);

      resolve(true);
    })
  }
}
