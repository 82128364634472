import * as React from 'react';

import { IApiProvider } from '../../core/providers/api.provider';
import { IRootContext, useCtx } from '../../core/stores/root.context';

import { IReportUtilities, ReportUtilities } from './report.utilities';

/**
 * Defines the service prop passed to the wrapped component.
 */
export interface IReportServiceProp<T> {
  dataService: T;
}

/**
 * Defines the wrapper props.
 */
export interface IServiceProps {
  shop: string;
  appId: string;
}

/**
 * Defines the expected service constructor signature. 
 */
type Constructor<T> = 
  new (
    shop: string, 
    appId: string, 
    apiProvider: IApiProvider, 
    reportUtilities: IReportUtilities) => T;

/**
 * Wraps a component expecting an api service as a prop. The wrapper creates an instance
 * of the service providing shop, appId, and apiProvider to the service constructor.
 * 
 * @param the wrapped component.
 * @param the service class to provide to the wrapped component.
 */
export function withReportService<T>(Component: React.ComponentType<IReportServiceProp<T>>, ctor: Constructor<T>) {
  const WithReportService: React.FC<IServiceProps> = (props) => {
    const rootContext: IRootContext = useCtx();
    const apiProvider = rootContext.services.apiProvider;
    const newService = new (ctor)(props.shop, props.appId, apiProvider, new ReportUtilities());;

    return (
      <Component {...{ dataService: newService }} />
    );

  }

  return WithReportService;
}
