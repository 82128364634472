import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
    },
  }),
);

const findIndex = (searchStrings: string[], toFind: string): number => {
  let index = searchStrings.findIndex(searchString => searchString === toFind);
  if (index < 0) {
    index = 0;
  }

  return index;
}

interface IProps {
  filters: string[];
  selected: string;
  onChange: (filter: string) => void;
  title: string;
}

const FilterMenuBase: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(findIndex(props.filters, props.selected));
  const {filters, onChange, title} = {...props};

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    handleClose();
    setSelectedIndex(index);
    onChange(filters[index]);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <List component="nav" aria-label={title}>
        <ListItem
          button={true}
          aria-haspopup="true"
          aria-controls="filter-menu"
          aria-label={title}
          onClick={handleClickListItem}
        >
          <ListItemText primary={title} secondary={filters[selectedIndex]} />
        </ListItem>
      </List>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {filters.map((collection, index) => (
          <MenuItem
            key={collection}
            selected={index === selectedIndex}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {collection}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default FilterMenuBase;