
import { 
  FieldColumnDefinitions, 
  FieldColumnKeys, 
  FieldTypes,
  IColumnKeyTitle, 
  IDisplayColumnDefinition,
} from '../reportDefinitions';

/**
 * Defines the columns and order for the transactions report.
 */
export const HeaderFieldTypes: FieldTypes[] = [
  FieldTypes.ORDER_NUMBER,
  FieldTypes.TOTAL_PRICE,
  FieldTypes.SOURCE,
  FieldTypes.TOTAL_DISCOUNTS,
  FieldTypes.DISCOUNT_CODE,
  FieldTypes.REFUNDS,
  FieldTypes.DATE,
]

export const COLUMN_KEY_TITLES: FieldColumnKeys = new Map<FieldTypes, IColumnKeyTitle>([
  [FieldTypes.ORDER_NUMBER,         { key: 'order_number',     title: 'Order Number'}],
  [FieldTypes.TOTAL_PRICE,          { key: 'total',            title: 'Total'}],
  [FieldTypes.SOURCE,               { key: 'source',           title: 'Source'}],
  [FieldTypes.TOTAL_DISCOUNTS,      { key: 'discount',         title: 'Discount'}],
  [FieldTypes.DISCOUNT_CODE,        { key: 'discount_code',    title: 'Disc Code'}],
  [FieldTypes.REFUNDS,              { key: 'refunds',          title: 'Refunds'}],
  [FieldTypes.DATE,                 { key: 'date',             title: 'Date'}],
])

/**
 * Defines the GUI report column definitions used to render the table.
 */
export const DISPLAY_COLUMN_DEFINITIONS: FieldColumnDefinitions = 
  new Map<FieldTypes, IDisplayColumnDefinition>([
    [FieldTypes.ORDER_NUMBER,       
      { name: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NUMBER)!.key,     
        title: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NUMBER)!.title,    
        display: true, 
        width: 140 }],
    [FieldTypes.TOTAL_PRICE,        
      { name: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_PRICE)!.key,      
        title: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_PRICE)!.title,     
        display: true, 
        width: 100}],
    [FieldTypes.SOURCE,             
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SOURCE)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.SOURCE)!.title,          
        display: true, 
        width: 100}],
    [FieldTypes.TOTAL_DISCOUNTS,    
      { name: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_DISCOUNTS)!.key,  
        title: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_DISCOUNTS)!.title, 
        display: true, 
        width: 100}],
    [FieldTypes.DISCOUNT_CODE,    
      { name: COLUMN_KEY_TITLES.get(FieldTypes.DISCOUNT_CODE)!.key,  
        title: COLUMN_KEY_TITLES.get(FieldTypes.DISCOUNT_CODE)!.title, 
        display: true, 
        width: 120}],
    [FieldTypes.REFUNDS,            
      { name: COLUMN_KEY_TITLES.get(FieldTypes.REFUNDS)!.key,          
        title: COLUMN_KEY_TITLES.get(FieldTypes.REFUNDS)!.title,         
        display: true, 
        width: 100}],
    [FieldTypes.DATE,               
      { name: COLUMN_KEY_TITLES.get(FieldTypes.DATE)!.key,             
        title: COLUMN_KEY_TITLES.get(FieldTypes.DATE)!.title,            
        display: true, 
        width: 100}],
  ]);

/**
 * Defines the properties used to build a transaction report row.
 */
export interface ITransactionRecord {
  id: number;
  order_number: number;
  source: string;
  total: number;
  discount: number;
  discount_code: string;
  date: Date;
  refunds: number;
  financial_status: string;
}

/**
 * Defines the Transaction rows for the GUI report.
 */
export interface ITransactionDisplayRecord {
  id: string;
  order_number: string;
  source: string;
  total: string;
  discount: string;
  discount_code: string;
  refunds: string;
  date: string;
}
