import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { IAuthenticationStore } from './core/stores/authentication';
import { RootContext } from './core/stores/root.context';

import { HttpError } from './core/providers/api.provider';

export enum RESET_ACTION_TYPES {
  NO_ACTION = 'No Action',
  REAUTHORIZE = 'Reauthorize credentials',
  RETURN_TO_PAGE = 'Return to Page',
  ERROR_PAGE = 'error page'
}

export interface IErrorResetAction {
  message?: string;
  action: RESET_ACTION_TYPES;
}

export type IErrorReset = (resetAction?: IErrorResetAction) => void;

export interface IErrorHandlerProps {
  // Function that error handlers call to restore the view.
  onReset: IErrorReset;
  // The thrown error.
  error: HttpError | Error;
}

interface IErrorBoundaryProps extends RouteComponentProps {
  errorHandler: React.ComponentType<IErrorHandlerProps>;
};

interface BoundaryState {
  readonly error: HttpError | Error | null | undefined;
  readonly resetAction: IErrorResetAction;
};

const NO_ACTION: IErrorResetAction = {
  action: RESET_ACTION_TYPES.NO_ACTION,
}

class ErrorBoundaryBase extends React.Component<IErrorBoundaryProps, BoundaryState> {
  public static contextType = RootContext;

  public static getDerivedStateFromError(error: Error) {
    // Caught an exception, display fallback UI
    return {error};
  }

  public context!: React.ContextType<typeof RootContext>;
 
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, resetAction: NO_ACTION };
  }
  
  /**
   * Handle reset of boundry error. Resets component start initiating a redisplay. The error is set
   * to undefined to the reset Action, if set, will run. If not set the boundary's children will be
   * redisplayed.
   */
  public handleReset: IErrorReset = (resetAction?: IErrorResetAction) => {
    // tslint:disable-next-line:no-console
    // console.log('ErrorBoundary:reset  ', resetAction, this.state);

    this.setState({ error: undefined, resetAction: resetAction ? resetAction : NO_ACTION });
  };

  public componentDidCatch(error: Error, info: any) {
    // Display fallback UI
    // this.setState({ error, info });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  /**
   * If an error has been detected, componentDidCatch called by React framework, render
   * the error handler specified by props.errorHandler. 
   */
  public render() {  
    // tslint:disable-next-line:no-console
    // console.log('Error Boundary:  ', this.props, '   State: ', this.state);

    const error = this.state.error;
    const {children, errorHandler: ErrorHandler, ...restProps} = this.props;
    const authenticationStore: IAuthenticationStore = this.context!.services.authenticationStore;

    if (error) {
      // tslint:disable-next-line:no-console
      // console.log('Error Boundary - displaying errorhandler:  ');

      return (
          <ErrorHandler
            onReset={this.handleReset}
            {...restProps}
            error={error}
            children={children}
          />
        )
    }

    if (this.state.resetAction && this.state.resetAction.action === RESET_ACTION_TYPES.REAUTHORIZE) {
      authenticationStore.reAuthenticate();

      return null;
    }
    
    return children;
   }
}

/**
 * Wrap the error
 * @param props Error boundry props with error handler.
 */
const withErrorBoundaryHandler: React.FC<IErrorBoundaryProps> = (props) => {
  const {errorHandler: ErrorHandler, ...restProps} = props;

  return (
    <ErrorBoundaryBase 
     errorHandler={ErrorHandler}
     {...restProps}
    />
  )
}

export default withErrorBoundaryHandler;

