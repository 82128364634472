import * as React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import { 
  createStyles, 
  Theme,
  WithStyles,
  withStyles, 
  withWidth 
} from '@material-ui/core';

import withRoot from './withRoot';

import TopBar from './shell/TopBar';

import AppRouterConfiguration from './core/configuration/app.router.configuration';

import { RootContext } from './core/stores/root.context';

import { IAuthenticationStore } from './core/stores/authentication';

export interface IAppProps extends WithStyles<typeof styles> {
    width: any;
}

class AppBase extends React.Component<IAppProps, {}> {
  public static contextType = RootContext;

  public props: IAppProps;

  constructor(props: IAppProps) {
    super(props);
    this.props = props
  }

  public render() {
    const authenticationStore: IAuthenticationStore = this.context!.services.authenticationStore;

    authenticationStore.restoreSession();
  
    const { classes } = this.props;
    
    // tslint:disable-next-line:no-console
    // console.log('App render  ', this.props);

    return (
      <Router>
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <TopBar />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <AppRouterConfiguration />
            </main>
          </div>
        </div>
      </Router>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  appFrame: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    padding: theme.spacing(),
    height: '100vh',
    overflow: 'auto',
  },
  // Creates area under the toolbar so router content is moved below it.
  appBarSpacer: {
    height: theme.spacing(7),
  }
});

export default withRoot(withStyles(styles)((withWidth()(AppBase))));
