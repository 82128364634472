import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import indigo from '@material-ui/core/colors/indigo';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from "react-router-dom";

import { CONFIGURATION_ITEM_TYPES, IConfigurationItem, MENU_CONFIGURATION } from '../../core/configuration/app.configuration';
import { IAppConfigurationService } from '../../core/configuration/app.configuration.service';

import { IRootContext, useCtx } from '../../core/stores/root.context';

interface IDashboardProps {
  name: string;
}

interface IProps extends RouteComponentProps<{}>, IDashboardProps {}

const DashboardBase: React.FC<IProps> = (props) => {
  const rootContext: IRootContext = useCtx();
  const configurationService: IAppConfigurationService = rootContext.services.appConfigurationService;

  // Tilde reports folder open flag.
  const [reportsOpen, setReportsOpen] = React.useState(true);

  const handleReportFolderClick = () => {
    setReportsOpen(!reportsOpen);
  };

  const buildDashboardFolder= (folderItem: IConfigurationItem ): React.ReactNode => {
    if (folderItem.children) {
      return (
        <React.Fragment key={folderItem.title}>
          <ListItem button={true} onClick={handleReportFolderClick} className={classes.folderListItem}>
            <ListItemText primary={folderItem.title} />
            {reportsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={reportsOpen} timeout="auto" unmountOnExit={true}>
            <List component="div" disablePadding={true}>
              {folderItem.children.map((child, index) => buildDashboardItem(child, index))}
            </List>
          </Collapse>
        </React.Fragment>
      )
    }

    return null;
  }

  const buildDashboardItem = (menuItem: IConfigurationItem, key = 0 ): React.ReactNode => {
    let element: React.ReactNode = (<React.Fragment key={key}/>);

    switch(menuItem.type) {
      case CONFIGURATION_ITEM_TYPES.ROUTER_PATH:
        if (menuItem.children) {
          element = menuItem.children.map((child, index) => buildDashboardItem(child, index));
        }
        break;

      case CONFIGURATION_ITEM_TYPES.LINK:
        if (menuItem.quickLink) {
          const path = (menuItem.urlSegments as string[]).join('/');

          element = (
            <ListItem 
              dense={true}
              button={true} 
              component={Link} 
              to={{pathname: path}} 
              className={classes.nested} 
              divider={true}
              key={path}>
              <ListItemText primary={menuItem.title} />
            </ListItem>
          )
        }
        break;

      case CONFIGURATION_ITEM_TYPES.FOLDER:
        element = buildDashboardFolder(menuItem);
        break;
    }

    return element;
  }

  const BuildDashboard: React.FC<{}> = () => {
    const appConfiguration: IConfigurationItem = configurationService.buildConfiguration(MENU_CONFIGURATION);
    const listClasses = useStyles();

    return (
      <List
        component="nav"
        className={listClasses.list}
      >
        {buildDashboardItem(appConfiguration)}
      </List>
    )

  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={4}>
          <div />
        </Grid>
        <Grid item={true} xs={4}>
          <BuildDashboard />
        </Grid>
        <Grid item={true} xs={4}>
          <div />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    textAlign: 'left',
    paddingTop: theme.spacing(1),
    paddingLeft: 15,
    paddingRight: 15,
  },
  list: {
    disablePadding: 'true',
  },
  folderListItem: {
    backgroundColor: indigo[400],
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: indigo[100],
  },
}));

export default withRouter(DashboardBase);
