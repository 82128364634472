import { 
  FieldColumnDefinitions,
  FieldColumnKeys, 
  FieldTypes, 
  IColumnKeyTitle, 
  IDisplayColumnDefinition,
} from '../reportDefinitions';

/**
 * Defines the columns and order for the inventory report.
 */
export const DisplayHeaderTypes: FieldTypes[] = [
  FieldTypes.SKU,
  FieldTypes.VENDOR,
  FieldTypes.TITLE,
  FieldTypes.VARIANT_TITLE,
  FieldTypes.PRICE,
  FieldTypes.QTY,
  FieldTypes.COST,
  FieldTypes.COLLECTIONS,
  FieldTypes.SUB_COLLECTION,
  FieldTypes.SORT_CODE,
  FieldTypes.UPDATED_AT,
];

export const COLUMN_KEY_TITLES: FieldColumnKeys = new Map<FieldTypes, IColumnKeyTitle>([
  [FieldTypes.SKU,                  { key: 'sku',              title: 'SKU'}],
  [FieldTypes.VENDOR,               { key: 'vendor',           title: 'Vendor'}],
  [FieldTypes.TITLE,                { key: 'title',            title: 'Title'}],
  [FieldTypes.PRICE,                { key: 'price',            title: 'Unit Price'}],
  [FieldTypes.QTY,                  { key: 'quantity',         title: 'Qty'}],
  [FieldTypes.COST,                 { key: 'cost',             title: 'Cost'}],
  [FieldTypes.COLLECTIONS,          { key: 'collections',      title: 'Categories'}],
  [FieldTypes.COLLECTION,           { key: 'main_collection',  title: 'Top Category'}],
  [FieldTypes.SUB_COLLECTION,       { key: 'sub_collection',   title: 'Category'}],
  [FieldTypes.SORT_CODE,            { key: 'sort_code',        title: 'Sort',}],
  [FieldTypes.UPDATED_AT,           { key: 'updated_at',       title: 'Date'}],
  [FieldTypes.VARIANT_TITLE,        { key: 'variant_title',    title: 'Variant'}],
])

/**
 * Defines the GUI report column definitions used to render the table.
 */
export const DISPLAY_COLUMN_DEFINITIONS: FieldColumnDefinitions =
  new Map<FieldTypes, IDisplayColumnDefinition>([
    [FieldTypes.SKU,                  
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.key,             
        title: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.title,
        display: true, 
        width: 100,
        sort: false,
      }
    ],
    [FieldTypes.VENDOR,               
      { name: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.key,          
        title: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.title,        
        display: true, 
        width: 120,
        sort: true,
      }
    ],
    [FieldTypes.TITLE,                
      { name: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.title,          
        display: true, 
        width: 200,
        sort: false,
      }
    ],
    [FieldTypes.VARIANT_TITLE,                
      { name: COLUMN_KEY_TITLES.get(FieldTypes.VARIANT_TITLE)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.VARIANT_TITLE)!.title,          
        display: true, 
        width: 200,
        sort: false,
      }
    ],
    [FieldTypes.PRICE,                
      { name: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.title,          
        display: true, 
        width: 120,
        sort: false,
      }
    ],
    [FieldTypes.QTY,                  
      { name: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.key,             
        title: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.title,            
        display: true, 
        width: 100,
        sort: false,
      }
    ],
    [FieldTypes.COST,                
      { name: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.key,           
        title: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.title,          
        display: true, 
        width: 120,
        sort: false,
      }
    ],
    [FieldTypes.COLLECTIONS,           
      { name: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTIONS)!.key,      
        title: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTIONS)!.title,     
        display: true, 
        width: 120,
        sort: true,
      }
    ],
    [FieldTypes.SUB_COLLECTION,       
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.key,  
        title: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.title, 
        display: true, 
        width: 140,
        sort: true,
      }
    ],
    [FieldTypes.SORT_CODE,            
      { name: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.key,       
        title: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.title,      
        display: true, 
        width: 105,
        sort: true,
      }
    ],
    [FieldTypes.UPDATED_AT,         
      { name: COLUMN_KEY_TITLES.get(FieldTypes.UPDATED_AT)!.key,    
        title: COLUMN_KEY_TITLES.get(FieldTypes.UPDATED_AT)!.title,   
        display: true, 
        width: 95,
        sort: true,
      }
    ],
  ]);

/**
 * Defines the properties used to build an inventory report row.
 */
export interface IInventoryRecord {
  sku: string;
  vendor: string;
  title: string;
  variant_title: string;
  price: number;
  quantity: number;
  cost: number;
  collections: string;
  main_collection: string;
  sub_collection: string;
  sort_code: string;
  updated_at: Date;
}

/**
 * Defines the Inventory rows for the GUI report.
 */
export interface IInventoryDisplayRecord {
  sku: string;
  vendor: string;
  title: string;
  variant_title: string;
  price: string;
  quantity: string;
  cost: string;
  collections: string;
  main_collection: string;
  sub_collection: string;
  sort_code: string;
  updated_at: string;
}
