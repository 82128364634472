import { Column } from '@devexpress/dx-react-grid';

/**
 * Defines the column types for Tilde reports. 
 * Note: These are types for all Tilde reports. This is to provide type checking for routines
 *       commom to all reports. It would be great if separate reports could sub-class this type
 *       but need to look into this for the future. 
 */
export enum FieldTypes {
  NONE = 'NONE',
  AMOUNT = 'AMT',
  COLLECTION = 'COLLECTION',
  COLLECTIONS = 'COLLECTIONS',
  COST = 'COST',
  DATE = 'DATE',
  DISCOUNT_CODE = 'DISCOUNT_CODE',
  ID = 'ID',
  ORDER_DISCOUNT = 'ORDER_DISCOUNT',
  ORDER_DISCOUNT_CODES = 'ORDER_DISCOUNT_CODES',  
  ORDER_NAME = 'ORDER_NAME',
  ORDER_NUMBER = 'ORDER_NUMBER',
  ORDER_STATUS = 'ORDER_STATUS',
  PRICE = 'PRICE',
  PROCESSED_AT = 'PROCESSED_AT',
  QTY = 'QTY',
  REFUNDS = 'REFUNDS',
  SKU = 'SKU',
  SORT_CODE = 'SORT_CODE',
  SOURCE = 'SOURCE',
  SUB_COLLECTION = 'SUB_COLLECTION',
  TITLE = 'TITLE',
  TOTAL_DISCOUNTS = 'TOTAL_DISCOUNTS',
  TOTAL_PRICE = 'TOTAL_PRICE',
  UPDATED_AT = 'UPDATED_AT',
  VARIANT_TITLE = 'VARIANT_TITLE',
  VENDOR = 'VENDOR',

  SUMMATION_TOTAL_LABEL = 'SUMMATION_TOTAL_LABEL',
  SUMMATION_TOTAL = 'SUMMATION_TOTAL',
  SUMMATION_DISCOUNTS_TOTAL_LABEL = 'SUMMATION_DISCOUNTS_TOTAL_LABEL',
  SUMMATION_DISCOUNTS_TOTAL = 'SUMMATION_DISCOUNTS_TOTAL',
  SUMMATION_REFUNDS_TOTAL_LABEL = 'SUMMATIONNG_REFUNDS_TOTAL_LABEL',
  SUMMATION_REFUNDS_TOTAL = 'SUMMATION_REFUNDS_TOTAL',
 }

// Defines a map of FieldTypes to column number. 
export type FieldColumnNumber = Map<FieldTypes, number>;

export interface IColumnKeyTitle {
  key: string;
  title: string;
}

export type FieldColumnKeys= Map<FieldTypes, IColumnKeyTitle>; 

export interface IDisplayColumnDefinition extends Column {
  display: boolean;
  width: number;
  sort?: boolean; // Flag indicating this field should have column sorting.
};

export type FieldColumnDefinitions = Map<FieldTypes, IDisplayColumnDefinition>;
