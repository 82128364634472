import { HTTP_ERROR_TYPE, HttpError } from './http.error';

const UNAUTHORIZED_ACCESS_MESSAGE = 'Unauthorized access';
/**
 * If this is a 401(Unauthorized) error the access token is invalid or has timed out.
 */
export class UnauthorizedAccessError extends HttpError {
  constructor() {
    super(
      401,
      UNAUTHORIZED_ACCESS_MESSAGE,
      HTTP_ERROR_TYPE.UNAUTHORIZED_ACCESS
    );
  }
}
