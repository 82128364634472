import * as React from 'react';

import { 
  ApiProvider, 
  ApiProviderUtils, 
  IApiProvider,
  IApiProviderUtils,
} from '../providers/api.provider';
import {
  IInventoryProvider,
  ILoginProvider,
  InventoryProvider,
  IOrdersProvider,
  IProductsProvider,
  ISalesProvider,
  IShopsProvider,
  IVersionProvider,
  LoginProvider,
  OrdersProvider,
  ProductsProvider,
  SalesProvider,
  ShopsProvider,
  VersionProvider,
} from '../providers/api.provider/api.providers';

import { IJwtHelper, JwtHelper } from '../utils/jwt.helper';

import { AuthenticationStore, IAuthenticationStore } from './authentication';
import { AuthorizationStore, IAuthorizationStore } from './authorization';
import { EnvironmentStore, IEnvironmentStore } from './environment';
import { ISessionStore, SessionStore } from './session';

import { AppConfigurationService, IAppConfigurationService } from '../configuration/app.configuration.service';

export interface IRootServices {
  authenticationStore: IAuthenticationStore;
  authorizationStore: IAuthorizationStore;
  environmentStore: IEnvironmentStore;
  sessionStore: ISessionStore;
  appConfigurationService: IAppConfigurationService;
  apiProvider: IApiProvider;
}

export interface IRootContext {
  services: IRootServices;
}

/**
 * Creates the application services.
 *
 * @returns IRootServices, the instantiatied application's root services.
 */
const createRootServices = (onContextChangeActions: IOnContextChange): IRootServices => {
  const sessionStore: SessionStore = new SessionStore();
  const environmentStore: IEnvironmentStore = new EnvironmentStore();

  const apiProviderUtils: IApiProviderUtils = new ApiProviderUtils(environmentStore, sessionStore);

  const inventoryProvider: IInventoryProvider = new InventoryProvider(apiProviderUtils);
  const loginProvider: ILoginProvider = new LoginProvider(apiProviderUtils);
  const shopsProvider: IShopsProvider = new ShopsProvider(apiProviderUtils);
  const ordersProvider: IOrdersProvider = new OrdersProvider(apiProviderUtils);
  const productsProvider: IProductsProvider = new ProductsProvider(apiProviderUtils);
  const salesProvider: ISalesProvider = new SalesProvider(apiProviderUtils);
  const versionProvider: IVersionProvider = new VersionProvider(apiProviderUtils);
  const apiProvider: IApiProvider = 
    new ApiProvider(
      inventoryProvider,
      loginProvider, 
      ordersProvider, 
      productsProvider, 
      salesProvider, 
      shopsProvider,
      versionProvider);
  
  const jwtHelper: IJwtHelper = new JwtHelper();
  const authorizationStore: IAuthorizationStore = new AuthorizationStore(sessionStore);

  return {
    authenticationStore: new AuthenticationStore(sessionStore, apiProvider, jwtHelper, onContextChangeActions),
    authorizationStore,
    environmentStore,
    sessionStore,
    appConfigurationService: new AppConfigurationService(authorizationStore),
    apiProvider,
  }
}

// create context with no upfront defaultValue
export const RootContext = React.createContext<IRootContext | undefined>(undefined);

/**
 * Use context created above, checking if it has been initialized.
 */
export const useCtx = (): IRootContext => {
  const c = React.useContext(RootContext);
  if (!c) {
    throw new Error('useCtx must be inside a Provider with a value');
  }

  return c;
}

enum ContextChangeTypes {
  SessionLogIn = 'Context change - Login',
  SessionLogOut = 'Context change - Logout',
  // A session timeout was detected and the user must reauthenticate. Currently
  // this is the same action as logout, forcing a new session.
  SessionReauthenticate = 'Context change - Reauthenticate',
}

interface IContextState {
  changeCounter: number;
}

interface IContextAction {
  type: ContextChangeTypes;
}

const initialContextState: IContextState = { changeCounter: 0 };

/**
 * The context state change reducer, simply change the context changeCounter forcing an
 * app redraw. 
 * 
 * @param state The current context state.
 * @param action The state change type.
 */
const reducer: React.Reducer<IContextState, IContextAction> = (state, action) => {
  // tslint:disable-next-line:no-console
  // console.log('Root Context - useReducer: ', state, '  Action: ', action);
     
  return { changeCounter: state.changeCounter + 1 };
}

export interface IOnContextChange {
  login: () => void;
  logout: () => void;
  reAuthenticate: () => void;
}

export const ContextProvider: React.FunctionComponent = props => {
  // At this point, don't need the state value so it's ignored.
  const [/* contextState */, onContextChange] = React.useReducer<React.Reducer<IContextState, IContextAction>>(reducer, initialContextState);

  const onContextChangeActions: IOnContextChange = {
    login: () => onContextChange({type: ContextChangeTypes.SessionLogIn}),
    logout: () => onContextChange({type: ContextChangeTypes.SessionLogOut}),
    reAuthenticate: () => onContextChange({type: ContextChangeTypes.SessionReauthenticate}),
  }

  // Use a constant rootServices object instead of instancing a new one for each re-render loop.
  // Thus children components that use the context won't get rerendered.
  const [rootServices] = React.useState<IRootServices>(createRootServices(onContextChangeActions));

  return (
    <RootContext.Provider value={{services: rootServices}}>
      {props.children}
    </RootContext.Provider>
  );
};
