import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { IRootContext, useCtx } from '../../core/stores/root.context';

import NotFoundPage from './notFoundPage';

interface IProps extends RouteComponentProps<{}> {}

const ProviderHOC = (NotFoundRoute: React.ComponentType<any>) => {
  const RouteProvider = ((props: any) => {
    const rootContext: IRootContext = useCtx();

    // tslint:disable-next-line:no-console
    // console.log('ProviderHOC');
    const isLoggedIn = rootContext.services.authenticationStore.isLoggedIn && !rootContext.services.authenticationStore.tokenExpired();
    // tslint:disable-next-line:no-console
    // console.log('RouteProvider', props, isLoggedIn  );

    if(props.location && props.location.state && props.location.state.noMatch) {
      if (!isLoggedIn) {
        return (
          ( <Redirect to={{pathname: '/login'}} /> )
        );
      }
        return <NotFoundRoute {...props} />
    }
    return props.children;
  })

  return withRouter(RouteProvider)

}

export const RouteManager  = ProviderHOC(NotFoundPage);

class NoMatchBase extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    // tslint:disable-next-line:no-console
    console.log('NoMatchBase', this.props);
    return (
      <Redirect to={{state: {noMatch: true}}} />
    );
  }
}

export default withRouter(NoMatchBase);
