import axios from 'axios';
import { AxiosResponse } from 'axios';

import moment from 'moment';

import { IGetSalesResponse, ISalesQueryOptions } from '../models';

import { IApiProviderUtils } from '../api.provider.utils';

export interface ISalesProvider {
  retrieveSales(
    shop: string, 
    options?: ISalesQueryOptions): Promise<IGetSalesResponse>;
}

export class SalesProvider implements ISalesProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  public async retrieveSales(
    shop: string, 
    options?: ISalesQueryOptions): Promise<IGetSalesResponse>
  {
    const headers = this.apiProviderUtils.buildDefaultHeaders();
    const url: string = this.baseApiUrl + `shopifyapi/${shop}/sales`;

    const fullOptions: ISalesQueryOptions = {};

    if (options!.processed_at_max) {
      fullOptions.processed_at_max = moment(options!.processed_at_max)
        .endOf('day')
        .format();
    }
    if (options!.processed_at_min) {
      fullOptions.processed_at_min = moment(options!.processed_at_min)
        .startOf('day')
        .format();
    }

    if (options!.page) {
      fullOptions['page'] = options!.page;
    }
    if (options!.limit) {
      fullOptions['limit'] = options!.limit;
    }

    const queryUrl = this.apiProviderUtils.buildQueryString(url, fullOptions);

    return axios.get<IGetSalesResponse>(queryUrl, { headers })
      .then((response: AxiosResponse): any => {
        // tslint:disable-next-line:no-console
        // console.log('retrieveSales', response.data);
        const responseData = response.data as IGetSalesResponse;

        return responseData;
      })
      .catch(this.apiProviderUtils.handleApiError);
  }
}
