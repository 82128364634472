import jwtDecode from 'jwt-decode';
import moment from 'moment';

export interface IJwtHelper {
  tokenExpired(token: string, currentTime: Date): boolean;
}

/**
 * Provides JWT token utility methods.
 */
export class JwtHelper implements IJwtHelper  {

  /*
   * Determines if the token has expired. The token is decoded and
   * the token exp field is compared to the specified date. If exp is before
   * the specified date then the token has expired.
   * 
   * @param token The base64 encoded JWT token.
   * @param currentTime The time with witch to check if expired.
   * 
   * @return false if the token's exp date if after the current date, otherwise true.
   */
  public tokenExpired(token: string, currentTime: Date): boolean {
    const decoded: any = jwtDecode(token);
    const expiredTimeSeconds = decoded.exp;
    let isExpired = false;

    if (expiredTimeSeconds && moment(currentTime).isAfter(expiredTimeSeconds * 1000)) {
      isExpired = true;
    } 

    return isExpired;
  }

}
