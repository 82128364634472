import * as Excel from 'exceljs';
import moment from 'moment';

import { FieldTypes } from '../../reportDefinitions';

import {
  addEmptyRow, 
  addSheetTitleRow,
  addSummationFormulaToRow, 
  buildColumns, 
  createWorkbook, 
  createWorksheet,
  findCellColumnLetterByNumber, 
  setHeaderStyles,
  setTitleCell,
  styleSummationRow, 
  uploadWorkbook 
} from '../../xlsx/xlsx.report.build.utilities';
import { HeaderFieldTypes, ITransactionRecord } from '../transactionsReportDefinition';
import { XlsxSheetColumnMetaData, XlsxSummationColumns } from './transactions.xlsx.report.metadata';

/**
 * Map containing discount code as key and list of transactions as value. 
 */
export type DiscountGroupType = Map<string, ITransactionRecord[]>;

export interface ITransactionsXlsxReportBuilder {
  buildExportReport(transactionRecords: ITransactionRecord[], startDate?: Date, endDate?: Date): Promise<boolean>;
}

export class TransactionsXlsxReportBuilder implements ITransactionsXlsxReportBuilder {
  /**
   * This is the xlsx report builder's main routine. It creates the xlsx workbook,
   * creates the workbook sheets, and upload's the spreadsheet.
   */
  public buildExportReport(transactionsRecords: ITransactionRecord[], startDate: Date, endDate?: Date): Promise<boolean> {
    if (!endDate) {
      endDate = moment().endOf('day').toDate();
    }

    const workbook = createWorkbook('Tilde Administrator');

    // Create the sheet having no grouping.
    const noGroupingWorksheet = this.initializeWorksheet(workbook, 'Transactions', startDate, endDate);
    this.buildNoGroupingWorksheet(noGroupingWorksheet, transactionsRecords);

    // Create the sheet with transactions grouped by discount code.
    const discountGroupingWorksheet = this.initializeWorksheet(workbook, 'Discount Grouping', startDate, endDate);
    this.buildDiscountGroupingWorksheet(discountGroupingWorksheet, transactionsRecords);

    return uploadWorkbook(workbook, 'TransactionsReport');
  }

  public initializeWorksheet(
                    workbook: Excel.Workbook, 
                    worksheetName: string, 
                    startDate: Date, 
                    endDate: Date): Excel.Worksheet 
  {
    const worksheet = createWorksheet(workbook, worksheetName);
    worksheet.columns = buildColumns(HeaderFieldTypes, XlsxSheetColumnMetaData);
    setHeaderStyles(worksheet);

    addSheetTitleRow(worksheet, HeaderFieldTypes, 'Transactions', startDate, endDate);

    return worksheet;
  }

  public buildNoGroupingWorksheet(worksheet: Excel.Worksheet, transactionRecords: ITransactionRecord[]): void {
    const rows = this.buildReportTransactionRows(transactionRecords);

    const excelRowNumbers: number[] = [];
    rows.forEach((row: any[]) => {
      const excelRow = worksheet.addRow(row);
      excelRowNumbers.push(excelRow.number);
    })

    // Add the summation of all the transactions.
    this.addSummationRow(worksheet, excelRowNumbers);
  }

  public buildDiscountGroupingWorksheet(worksheet: Excel.Worksheet, transactionRecords: ITransactionRecord[]): void {
    const groupByDiscountCodes: DiscountGroupType = this.buildGroupByDiscountCode(transactionRecords);
    const discountCodes = Array.from(groupByDiscountCodes.keys()).sort();
    const discountSummationRowNumbers: number[] = [];
  
    discountCodes.forEach((discountCode) => {
      const discountTotalsRowNumber = this.addDiscountCodeRows(worksheet, discountCode, groupByDiscountCodes.get(discountCode)!);
      discountSummationRowNumbers.push(discountTotalsRowNumber);
    })

    // Add the summation of all the discount code summations.
    this.addSummationRow(worksheet, discountSummationRowNumbers);
  }

  /**
   * Add the transaction rows for a discount code.
   * 
   * @param worksheet The worksheet to add the sales rows.
   * @param discountCode The discount code of the rows.
   * @param discountCodeRecords The transaction records for the discount code.
   * 
   * @returns The row number of the discount summation row. 
   */
  public addDiscountCodeRows(worksheet: Excel.Worksheet, discountCode: string, discountCodeRecords: ITransactionRecord[]): number {
    this.addDiscountCodeTitleRow(worksheet, discountCode);

    const dataRows = this.buildReportTransactionRows(discountCodeRecords);

    // Add the data rows to the excel sheet, saving the row numbers of the new rows.
    const sheetRowNumbers: number[] = [];
    dataRows.forEach((dataRow: any[]) => {
      const excelRow = worksheet.addRow(dataRow);
      sheetRowNumbers.push(excelRow.number);
    })

    const totalsRowNumber = this.addSummationRow(worksheet, sheetRowNumbers);

    addEmptyRow(worksheet, HeaderFieldTypes);

    return totalsRowNumber;
  }

  public buildReportTransactionRows(transactionRecords: ITransactionRecord[]):  any[] {
    return transactionRecords.map((transactionRecord) => {
      return { 
              ...transactionRecord, 
              date: moment(transactionRecord.date).format('MM/DD/YYYY')
            }
    })
    .sort((a, b) => {
      return moment(a.date).format('YYYY/MM/DD').localeCompare(moment(b.date).format('YYYY/MM/DD'));
    })
  }

  /**
   * Adds a row containing the discount code title.
   */
  public addDiscountCodeTitleRow(
                                  worksheet: Excel.Worksheet, 
                                  discountCode: string): Excel.Row 
  {
    const titleRow = addEmptyRow(worksheet, HeaderFieldTypes);
    const titleCellLetter = findCellColumnLetterByNumber(1);

    // Set the collection field label and style.
    const discountCodeLabelCell: Excel.Cell = titleRow.getCell(titleCellLetter);
    setTitleCell(discountCodeLabelCell, discountCode);

    return titleRow;
  }

  /**
   * Add a row containing the formula and sum of transaction total, discount and refunds fields 
   * from the specified sheet row numbers.
   * 
   * @param worksheet The Excel sheet containing to rows used in the summation.
   * @param summationRowNumbers Array of sheet row numbers used in the summation.
   * 
   * @returns The sheet row number of the summation row.
   */
  public addSummationRow(worksheet: Excel.Worksheet, summationRowNumbers: number[]): number {
    // Add the Amount total row, having all empty values.
    const sumRow: Excel.Row = addEmptyRow(worksheet, HeaderFieldTypes);

    styleSummationRow(sumRow);
 
    // Add the transaction total summation.
    const totalLabelCellLetter = findCellColumnLetterByNumber(XlsxSummationColumns.get(FieldTypes.SUMMATION_TOTAL_LABEL)!);
    const amtTotalCellLetter = findCellColumnLetterByNumber(XlsxSummationColumns.get(FieldTypes.SUMMATION_TOTAL)!);

    addSummationFormulaToRow(worksheet, sumRow, summationRowNumbers, totalLabelCellLetter, amtTotalCellLetter);
 
    // Add the transaction discounts summation.
    const discountLabelCellLetter = findCellColumnLetterByNumber(XlsxSummationColumns.get(FieldTypes.SUMMATION_DISCOUNTS_TOTAL_LABEL)!);
    const discountCellLetter = findCellColumnLetterByNumber(XlsxSummationColumns.get(FieldTypes.SUMMATION_DISCOUNTS_TOTAL)!);

    addSummationFormulaToRow(worksheet, sumRow, summationRowNumbers, discountLabelCellLetter, discountCellLetter);
 
    // Add the transaction refunds summation.
    const refundsLabelCellLetter = findCellColumnLetterByNumber(XlsxSummationColumns.get(FieldTypes.SUMMATION_REFUNDS_TOTAL_LABEL)!);
    const refundsCellLetter = findCellColumnLetterByNumber(XlsxSummationColumns.get(FieldTypes.SUMMATION_REFUNDS_TOTAL)!);

    addSummationFormulaToRow(worksheet, sumRow, summationRowNumbers, refundsLabelCellLetter, refundsCellLetter);

    return sumRow.number;
  }

  /**
   * Build a map of transactions with discount_code as the key.
   * 
   * @param transactionRecords 
   */
  public buildGroupByDiscountCode(transactionRecords: ITransactionRecord[]): DiscountGroupType {
    const discountGroupMap: DiscountGroupType = new Map<string, ITransactionRecord[]>();

    transactionRecords.forEach((transactionRecord) => {
      if (discountGroupMap.has(transactionRecord.discount_code)) {
        const discountTransactions = discountGroupMap.get(transactionRecord.discount_code);
        
        discountTransactions!.push(transactionRecord);
      } else {
        const discountTransactions: ITransactionRecord[] = [transactionRecord];

        discountGroupMap.set(transactionRecord.discount_code, discountTransactions);
      }
    })

    return discountGroupMap;
  }
}

