import { SessionStore } from '../session';
import { Permission } from './permission';

export interface IAuthorizationStore {
  isAuthorized(authority: string, action: string): boolean;
  isAuthorizedForShops(shops: string[]): boolean;
  hasAuthority(permissions: Permission[]): boolean;
}

export class AuthorizationStore implements IAuthorizationStore {
  constructor(private readonly sessionStore: SessionStore) {}

  /**
   * Checks the specified 'authority' and 'action' against the permissions in the current session.
   * If the session permissions have the authority and the specified action is active, return true.
   *
   * @param authority The permission authority.
   * @param action The requested action.
   * @returns True if the authority and action are enabled.
   */
  public isAuthorized(authority: string, action: string): boolean {
    let isAuthorized = false;
    const permissions: Map<
      string,
      Permission
    > = this.sessionStore.getSession().getPermissions();

    if (permissions.has(authority)) {
      const sessionPermission = (permissions.get(authority)) as Permission;

      if ('view' === action && sessionPermission.view) {
        isAuthorized = true;
      }
      if ('manage' === action && sessionPermission.manage) {
        isAuthorized = true;
      }
      if ('execute' === action && sessionPermission.execute) {
        isAuthorized = true;
      }
    }

    return isAuthorized;
  }

  /**
   * Checks the specified 'shops' against the user shops in the current session.
   * If the session shop permissions contains any of the requested shops, return true.
   *
   * @param shops The request shops.
   * @returns True if the current session has access to the requested shops.
   */
  public isAuthorizedForShops(requiredShops: string[]): boolean {
    let isAuthorized = false;
    const permittedShops: string[] = this.sessionStore.getSession().getUserShops();

    if (requiredShops.length === 0) {
      isAuthorized = true;
    } else {
      requiredShops.forEach((userShop: string) => {
        if (permittedShops.find((permittedShop: string) => userShop === permittedShop)) {
          isAuthorized = true;
        }
      });
    }

    return isAuthorized;
  }

  /**
   * Checks the permissions against the permissions in the current session.
   * If the session permissions have all of the requested permissions, true is returned.
   *
   * @param permissions The permission to check.
   * @returns True if the current session has all of the requested permissions or no permissions
   *          are requested.
   */
  public hasAuthority(permissions: Permission[]): boolean {
    let hasAuthority = true;

    if (permissions) {
      const sessionsPermissions: Map<string, Permission> 
        = this.sessionStore.getSession().getPermissions();

      for (const permission of permissions) {
        let hasPermissionAuthority = false;
        if (sessionsPermissions.has(permission.subject)) {
          const sessionPermission: Permission = sessionsPermissions.get(
            permission.subject
          ) as Permission;
          if (sessionPermission.view && permission.view) {
            hasPermissionAuthority = true;
          }
          if (sessionPermission.manage && permission.manage) {
            hasPermissionAuthority = true;
          }
          if (sessionPermission.execute && permission.execute) {
            hasPermissionAuthority = true;
          }
        }
  
        if (!hasPermissionAuthority) {
          hasAuthority = false;
          break;
        }
      }
    }

    return hasAuthority;
  }
}
