import * as Excel from 'exceljs';

import { FieldTypes } from '../../reportDefinitions';
import { XlsxFieldColumnNumber } from '../../xlsx/xlsxDefinitions';

import { COLUMN_KEY_TITLES } from '../SalesReportDefinition';

export interface IRow {
  sku: string;
  vendor: string;
  title: string;
  price: number;
  quantity: number;
  cost: number;
  collections: string;
  sub_collection: string;
  sort_code: string;
}

/**
 * Defines the summation column numbers for the sales xlsx report.
 * Excel columns start with column 1.
 */
export const XlsxSummationColumns: XlsxFieldColumnNumber = new Map<FieldTypes, number>([
  [FieldTypes.SUMMATION_TOTAL_LABEL, 1],
  [FieldTypes.SUMMATION_TOTAL, 2],
])

const DEFAULT_COLUMN_STYLE: Partial<Excel.Style> = {
  alignment: {horizontal: 'left'},
  font: { size: 10, name: 'Arial', family: 2, charset: 1 },
}
const DEFAULT_NUM_COLUMN_STYLE: Partial<Excel.Style> = {
  alignment: {horizontal: 'right'},
  font: { size: 10, name: 'Arial', family: 2, charset: 1 },
}

const DEFAULT_COLUMN_METADATA : Partial<Excel.Column> = {
  header: 'Default Header', 
  key: 'default',
  hidden: false,
  width: 11,
  outlineLevel: 0,
  style: {...DEFAULT_COLUMN_STYLE},
}

const SKU_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.key,
}

const VENDOR_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 18,
  header: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.key,
}

const TITLE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 25,
  header: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.key,
}

const PRICE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 11,
  header: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE, 
          numFmt: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00'},
}

const AMOUNT_METADATA: Partial<Excel.Column> = {
  ...PRICE_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.AMOUNT)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.AMOUNT)!.key,
}

const COST_METADATA: Partial<Excel.Column> = {
  ...PRICE_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.key,
}

const PROCESSED_AT_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 12,
  header: COLUMN_KEY_TITLES.get(FieldTypes.PROCESSED_AT)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.PROCESSED_AT)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE,
          alignment: {horizontal: 'center'},
          numFmt: 'MM/DD/YYYY'},
}

const QTY_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 6,
header: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE, 
    numFmt: 'General'},
}

const COLLECTIONS_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 20,
  header: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTIONS)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTIONS)!.key,
}

const COLLECTION_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 20,
  header: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTION)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTION)!.key,
}

const SUB_COLLECTION_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 20,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.key,
}

const ORDER_DISCOUNT_METADATA: Partial<Excel.Column> = {
  ...PRICE_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE, 
    numFmt: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00'},
}

const ORDER_DISCOUNT_CODES_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 12,
  header: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT_CODES)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_DISCOUNT_CODES)!.key,
}

const ORDER_NAME_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 10,
  header: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NAME)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NAME)!.key,
}

const ORDER_STATUS_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 11,
  header: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_STATUS)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_STATUS)!.key,
}

const SORT_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 11,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.key,
}

export const SheetColumnMetaData = new Map<FieldTypes, Partial<Excel.Column>>([
  [FieldTypes.SKU,  SKU_METADATA],
  [FieldTypes.COLLECTIONS, COLLECTIONS_METADATA],
  [FieldTypes.COLLECTION, COLLECTION_METADATA],
  [FieldTypes.SUB_COLLECTION, SUB_COLLECTION_METADATA],
  [FieldTypes.ORDER_DISCOUNT, ORDER_DISCOUNT_METADATA],
  [FieldTypes.ORDER_DISCOUNT_CODES, ORDER_DISCOUNT_CODES_METADATA],
  [FieldTypes.ORDER_NAME, ORDER_NAME_METADATA],
  [FieldTypes.ORDER_STATUS, ORDER_STATUS_METADATA],
  [FieldTypes.PRICE, PRICE_METADATA],
  [FieldTypes.AMOUNT, AMOUNT_METADATA],
  [FieldTypes.COST, COST_METADATA],
  [FieldTypes.PROCESSED_AT, PROCESSED_AT_METADATA],
  [FieldTypes.QTY, QTY_METADATA],
  [FieldTypes.SORT_CODE, SORT_METADATA],
  [FieldTypes.TITLE, TITLE_METADATA],
  [FieldTypes.VENDOR, VENDOR_METADATA],
]);
