import * as React from 'react';

import classNames from 'classnames';

import { 
  AppBar, 
  createStyles, 
  IconButton, 
  Menu,
  MenuItem,
  Theme, 
  Toolbar, 
  Typography, 
  WithStyles, 
  withStyles, 
  withWidth 
} from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';

import { isSmartphone } from '../responsive';
import withRoot from '../withRoot';

import NavMenuController from './NavMenuController';

import { IAuthenticationStore } from '../core/stores/authentication';
import { ISessionStore } from '../core/stores/session';
import { RootContext } from '../core/stores/root.context';

export interface ITopBarProps extends WithStyles<typeof styles> {
    width: any;
}

interface ITopBarState {
    navMenuOpen: boolean;
    profileMenuAnchorElement: HTMLElement | null;
}

class TopBar extends React.Component<ITopBarProps, {}> {
  public static contextType = RootContext;
  public context!: React.ContextType<typeof RootContext>;

  public props: ITopBarProps;
  public state: ITopBarState;

  private authenticationStore: IAuthenticationStore | undefined;
  private sessionStore: ISessionStore | undefined;

  /**
   * Initial component state 
   */
  constructor(props: ITopBarProps) {
    super(props);
    this.props = props
    this.state = {
        navMenuOpen: false,
        profileMenuAnchorElement: null,
    };

    this.authenticationStore = undefined;
    this.sessionStore = undefined;
  }

  /**
   * Builds the top tool bar, including the left side expandable navigation menu.
   */
  public buildAppBar() {
    const { width, classes } = this.props;
    const { navMenuOpen, profileMenuAnchorElement} = this.state;

    const profileMenuOpen = Boolean(this.state.profileMenuAnchorElement);
 
    return (
      <AppBar position="absolute"
              className={classNames(classes.appBar, navMenuOpen && classes.appBarShift)}>
        <Toolbar disableGutters={!navMenuOpen} className={classes.toolbar}>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleNavMenuToggle}
              className={classNames(
                  classes.menuButton,
                  navMenuOpen && classes.menuButtonHidden,
              )}
          >
              <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap={!isSmartphone(width)}>
              Tilde Administration
          </Typography>
          <div className={classes.appBarDivider}/>
          <div >
            <IconButton
              aria-owns={profileMenuOpen ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={profileMenuAnchorElement}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={profileMenuOpen}
              onClose={this.handleProfileMenuClose}
            >
              <MenuItem onClick={this.handleProfileMenuClose}>{this.sessionStore?.getSession().userName}</MenuItem>
              <MenuItem onClick={this.handleLogoff}>Log off</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  public render() {
    this.authenticationStore = this.context!.services.authenticationStore;
    this.sessionStore = this.context!.services.sessionStore;

    const { navMenuOpen} = this.state;

    return (
      <div>
        {this.buildAppBar()}
        <NavMenuController open={navMenuOpen} handleMenuToggle={this.handleNavMenuToggle} />
      </div>
    );
  }

  public handleProfileMenuOpen = (event: any) => {
    if (this.authenticationStore!.isLoggedIn) {
      this.setState({profileMenuAnchorElement: event.currentTarget});
    }
  };

  public handleProfileMenuClose = (event: any) => {
    this.setState({profileMenuAnchorElement: null});
  };

  public handleLogoff = (event: any) => {
    this.authenticationStore!.logout();
    this.handleProfileMenuClose(event);
  };

  private handleNavMenuToggle = () => {
    if ( this.authenticationStore!.isLoggedIn) {
      this.setState({navMenuOpen: !this.state.navMenuOpen});
    }
  };

}

const drawerWidth = 240;
const styles = (theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarDivider: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
});

export default withRoot(withStyles(styles)((withWidth()(TopBar))));
