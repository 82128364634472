import * as React from 'react';
import { Route, Switch } from "react-router-dom";

import SecuredRoute from '../../securedRoute';

import NoMatch from '../../shell/not.found/noMatchRoute';
import { RouteManager } from '../../shell/not.found/noMatchRoute';

import { AppComponentName, IRoute, MENU_CONFIGURATION } from './app.configuration';

import { RootContext } from '../stores/root.context';

import AboutPage from '../../pages/about/About';
import DashboardPage from '../../pages/dashboard/Dashboard';
import LoginRouteManager from '../../pages/login/LoginRouteManager';
import TildeReportsPage from '../../pages/tilde.reports/TildeReports';

export class AppRouterConfiguration extends React.Component<{}, {}> {
  public static contextType = RootContext;
  public context!: React.ContextType<typeof RootContext>;

  private nameToComponentMap = new Map<AppComponentName, React.ComponentType<any>>();

  constructor(props: any) {
    super(props);

    this.nameToComponentMap.set('Login', LoginRouteManager);
    this.nameToComponentMap.set('About', AboutPage);
    this.nameToComponentMap.set('Dashboard', DashboardPage);
    this.nameToComponentMap.set('TildeReports', TildeReportsPage);
  }

  public buildRoutes() {
    const configuredRoutes: IRoute[] = this.context!.services.appConfigurationService.buildRoutes(MENU_CONFIGURATION);
      // tslint:disable-next-line:no-console
      // console.log('router configuration', configuredRoutes);

      const routes = configuredRoutes.map((routeConfiguration: IRoute, index: number) => {
        if (routeConfiguration.path.length === 0) {
          routeConfiguration.path = '/';
        }

        if (routeConfiguration.secured) {
          return (
            <SecuredRoute 
              path={routeConfiguration.path}  
              exact={routeConfiguration.exact || false} 
              component={this.nameToComponentMap.get(routeConfiguration.componentName)}
              permissions={routeConfiguration.permissions || []} 
              shops={routeConfiguration.shops || []} 
              key={index}
              props={routeConfiguration.props}
            />
          )
      }
      
      return (
        <Route 
          path={routeConfiguration.path} 
          exact={routeConfiguration.exact || false} 
          component={this.nameToComponentMap.get(routeConfiguration.componentName)}
          key={index}
        />
      )
    });

    return routes;
  }

  public render() {
    const routes = this.buildRoutes();

    return (
        <RouteManager>
          <Switch>
          { routes }
            // Handle unregistered routes
            <NoMatch />
          </Switch>
        </RouteManager>
    );
  }
}

export default AppRouterConfiguration;
