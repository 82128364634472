import moment from 'moment';

import { 
  Column, 
  Sorting, 
  SortingState, 
  TableColumnWidthInfo,
 } from '@devexpress/dx-react-grid';

import { IApiProvider, IInventoryProvider } from '../../../core/providers/api.provider';
import { 
  IApiShopifyInventory,
  IGetInventoryResponse, 
  IInventoryQueryOptions,
 } from '../../../core/providers/api.provider/models';

import { ICollectionInfo, IReportUtilities } from '../report.utilities';

import { 
  FieldTypes, 
} from '../reportDefinitions';

import { 
  DISPLAY_COLUMN_DEFINITIONS, 
  DisplayHeaderTypes,
  IInventoryDisplayRecord,
  IInventoryRecord,
} from './InventoryReportDefinition';
import { IInventoryXlsxReportBuilder, InventoryXlsxReportBuilder } from './xlsx/inventory.xlsx.report.builder';

const delayExecution = async (): Promise<any> => {
  return new Promise((resolveCb, rejectCb) => {
      setTimeout(() => {
          resolveCb();
      },
      10);
  });
}

export interface IInventoryReportService {
  retrieveInventory(updatedAtStart?: Date, updatedAtEnd?: Date): Promise<IInventoryRecord[]>;
  getColumns(): Column[];
  getDisplayColumnWidthInfo(): TableColumnWidthInfo[];
  getDisplaySortColumnDefaults(): Sorting[];
  getDisplaySortColumns(): SortingState.ColumnExtension[];
  getCollectionsFilters(): string[];
  buildExportReport(serverRecords: IInventoryRecord[], startDate?: Date, endDate?: Date): void;
}

export class InventoryReportService implements IInventoryReportService {
  private displayColumns: Column[];
  private displayColumnWidths: TableColumnWidthInfo[];
  private sortColumnDefaults: Sorting[];
  private sortColumns: SortingState.ColumnExtension[];
  private xlsxReportBuilder: IInventoryXlsxReportBuilder;

  constructor(
    private readonly shop: string,
    private readonly appId: string,
    private readonly apiProvider: IApiProvider,
    private readonly reportUtilities: IReportUtilities) {
      this.displayColumns = this.buildDisplayColumns();
      this.displayColumnWidths = this.buildColumnWidthInfo();
      this.sortColumnDefaults = this.buildSortColumnDefaults();
      this.sortColumns = this.buildSortColumns();

      this.xlsxReportBuilder = new InventoryXlsxReportBuilder();
    }

  public async retrieveInventory(updatedAtStart?: Date, updatedAtEnd?: Date): Promise<IInventoryRecord[]> {
    const inventoryProvider: IInventoryProvider = this.apiProvider.getInventoryProvider();

    const options: IInventoryQueryOptions = {}
    if (updatedAtStart) {
      options.updated_at_min = moment(updatedAtStart).startOf('day').format();
    }
    if (updatedAtEnd) {
      options.updated_at_max = moment(updatedAtEnd).endOf('day').format();
    }

    return inventoryProvider.retrieveInventory(this.shop, this.appId, options)
      .then((inventoryResponse: IGetInventoryResponse) => {
          // tslint:disable-next-line:no-console
          // console.log('retrieveInventory response: ', productsResponse);

        return inventoryResponse.inventoryProducts;
      })
      .then((apiInventory: IApiShopifyInventory[]) => {
        return this.buildReportRecords(apiInventory);        
      })
  }
  
  public getColumns(): Column[] {
    return this.displayColumns;
  }

  public getDisplayColumnWidthInfo(): TableColumnWidthInfo[] {
    return this.displayColumnWidths;
  }

  public getDisplaySortColumnDefaults(): Sorting[] {
    return this.sortColumnDefaults;
  }

  public getDisplaySortColumns(): SortingState.ColumnExtension[] {
    return this.sortColumns;
  }
  
  public getCollectionsFilters(): string[] {
    return this.reportUtilities.getCollectionsFilters();
  }

  public async buildExportReport(serverRecords: IInventoryRecord[], startDate?: Date, endDate?: Date): Promise<boolean> {
    return new Promise(async (resolve) => {
      await delayExecution();
      this.xlsxReportBuilder.buildExportReport(serverRecords, startDate, endDate);

      resolve(true);
    })
  }

  public buildDisplayRows(inventoryRecords: IInventoryRecord[]): IInventoryDisplayRecord[] {
    // Loop through each inventory record and create the table display rows.
    return inventoryRecords.map((inventoryRecord: IInventoryRecord) => {
      const record: IInventoryDisplayRecord = {
        sku: inventoryRecord.sku,
        vendor: inventoryRecord.vendor,
        title: inventoryRecord.title,
        price: '$' + inventoryRecord.price.toFixed(2),
        quantity: inventoryRecord.quantity + '',
        cost: '$' + inventoryRecord.cost.toFixed(2),
        collections: inventoryRecord.collections,
        main_collection: inventoryRecord.main_collection,
        sub_collection: inventoryRecord.sub_collection,
        sort_code: inventoryRecord.sort_code,
        updated_at: moment(inventoryRecord.updated_at).format('L'),
        variant_title: inventoryRecord.variant_title,
      };

      return record;
    })  
  }

  /**
   * Build the list of table columns.
   */
  private buildDisplayColumns(): Column[] {
    const columns: Column[] = [];

    DisplayHeaderTypes.forEach((type) => {
      const columnDefinition = DISPLAY_COLUMN_DEFINITIONS.get(type)!;

      if (columnDefinition.display) {
        columns.push(columnDefinition);
      }
    });
    // tslint:disable-next-line:no-console
    // console.log('buildColumns: ', columns);

    return columns;
  }

  /**
   * Build the default column width configurations.
   * 
   * @return array of columnName and width.
   */
  private buildColumnWidthInfo(): TableColumnWidthInfo[] {
    const columns: TableColumnWidthInfo[] = [];

    DisplayHeaderTypes.forEach((type) => {
      const columnDefinition = DISPLAY_COLUMN_DEFINITIONS.get(type)!;

      if (columnDefinition.display) {
        columns.push({columnName: columnDefinition.name, width: columnDefinition.width});
      }
    });

    // tslint:disable-next-line:no-console
    // console.log('buildColumnWidthInfo: ', columns);

    return columns;
  }

  /**
   * Configuration the sort widgets for columns that are pre-sorted. 
   * 
   * @return array of columnName and sort direction.
   */
  private buildSortColumnDefaults(): Sorting[] {
    const columns: Sorting[] = [];

    const name = DISPLAY_COLUMN_DEFINITIONS.get(FieldTypes.SORT_CODE)!.name;
    columns.push({columnName: name, direction: 'asc'});

    return columns;
  }

  /**
   * Enable the sort widget, in the column header, only for sortable columns.
   * 
   * @return array of columnName and sort enabled flags.
   */
  private buildSortColumns(): SortingState.ColumnExtension[] {
    const sortColumns: SortingState.ColumnExtension[] = [];

    DisplayHeaderTypes.forEach((type) => {
      const columnDefinition = DISPLAY_COLUMN_DEFINITIONS.get(type)!;

      if (columnDefinition.display) {
        sortColumns.push({columnName: columnDefinition.name, sortingEnabled: columnDefinition.sort || false});
      }
    });
    // tslint:disable-next-line:no-console
    // console.log('buildSortColumns: ', sortColumns);

    return sortColumns;
  }

  /**
   * Convert records retrieved from the server in reporting format.
   * 
   * @param apiRecords 
   */
  private buildReportRecords(apiRecords: IApiShopifyInventory[]): IInventoryRecord[] {
    const records: IInventoryRecord[] = [];
    
    // Loop through each inventory record and create the table display rows.
    apiRecords.forEach((apiRecord: IApiShopifyInventory) => {
      const { collections, main_collection, sub_collection} = this.buildCollections(apiRecord.tags);
      const price = parseFloat(apiRecord.price);
      const quantity = apiRecord.qty;
      const cost = parseFloat(apiRecord.cost);

      const record: IInventoryRecord = {
        sku: apiRecord.sku,
        vendor: apiRecord.vendor,
        title: apiRecord.title,
        price,
        quantity,
        cost,
        collections,
        main_collection,
        sub_collection,
        sort_code: this.reportUtilities.formatSku(apiRecord.sku),
        updated_at: moment(apiRecord.updated_at).toDate(),
        variant_title: apiRecord.variant_title,
      };

      records.push(record);
    })  

    return records;
  }

  private buildCollections(tags: string): { collections: string, main_collection: string, sub_collection: string} {
    let itemTags: string[] = tags.split(',');
    const collectionsList: string[] = [];
    let mainCollection = '';
    let subCollection = '';

    // Cleanup the tags
    itemTags = itemTags.map((tag: string) => {
      return tag.trim();
    })

    const tagToCollectionMap: Map<string, ICollectionInfo> = this.reportUtilities.getTagsToCollectionsMap();

    itemTags.forEach(tag => {
      if(tagToCollectionMap.has(tag)) {
        const collectionInfo = tagToCollectionMap.get(tag);

        if (collectionInfo!.main_collection) {
          mainCollection = collectionInfo!.title;
        } else {
          subCollection = collectionInfo!.title;
          collectionsList.push(collectionInfo!.title);
        }
      } else {
        // tslint:disable-next-line:no-console
        console.log('Tag not found in tags map: \'' + tag + '\' tags: ' + tags);
      }
    })
    if (mainCollection.length > 0) {
      collectionsList.unshift(mainCollection);
    }

    return {collections: collectionsList.join(','), main_collection: mainCollection, sub_collection: subCollection};
  }
}
