import React, { useState } from 'react';

import moment from 'moment';

import { 
  createStyles, 
  makeStyles,
  Theme,
 } from '@material-ui/core';
 import CircularProgress from '@material-ui/core/CircularProgress';

 import {
  Column,
  IntegratedPaging,
  PagingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';

 import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';

import ReportHeaderRow from '../components/ReportHeaderRow';
import { IReportServiceProp, withReportService } from '../withReportService';
import { TransactionsReportService } from './transactions.report.service';
import { ITransactionDisplayRecord, ITransactionRecord } from './transactionsReportDefinition';
import TransactionsToolbar, { ITransactionsActionDates } from './TransactionsToolbar';

import { useErrorHandler } from '../ReportsErrorHandler';

interface IProps extends IReportServiceProp<TransactionsReportService> {
}

const TransactionsReportBase: React.FC<IProps> = (props) => {
  // tslint:disable-next-line:no-console
  // console.log('TransactionsReportBase: ', props);
  const defaultFilterDates: ITransactionsActionDates = {
    startDate: moment().date(1).toDate(),
    endDate: moment().toDate(),
  }

  const classes = useStyles();
  const [filterDates, setFilterDates] = useState<ITransactionsActionDates>(defaultFilterDates);

  const [serverRows, setServerRows] = useState<ITransactionRecord[]>([]);
  const [displayRows, setDisplayRows] = useState<ITransactionDisplayRecord[]>([]);

  const [columns] = useState<Column[]>(props.dataService.buildDisplayColumns(true));
  const [defaultColumnWidths] = useState<TableColumnWidthInfo[]>(props.dataService.buildDisplayColumnWidthInfo());

  // Enable/disable the loading spinner.
  const [loading, setLoading] = React.useState(false);

  // Enable/disable the toolbar action buttons.
  const [actionsEnabled, setActionsEnabled] = React.useState(false);

  /**
   * Use custom hook to handle errors generated report generation.
   */
  const errorHandler = useErrorHandler();

  /** 
   * Render the component when the filter dates change.
   */
  React.useEffect(() => {
    const effect = async () => {
        // tslint:disable-next-line:no-console
        // console.log('TransactionsReport - useEffect: ' + JSON.stringify(filterDates));

        setLoading(true);
        setActionsEnabled(false);

        try {
          const rows = await props.dataService.retrieveTransactions(filterDates.startDate, filterDates.endDate);
          const allDisplayRows = props.dataService.buildDisplayRows(rows);

          setServerRows(rows);
          setDisplayRows(allDisplayRows);

          setLoading(false);
          setActionsEnabled(true);
          // tslint:disable-next-line:no-console
          // console.log('TransactionsReport - refresh: ' + collectionFilter);
        }
        catch(e) {
          // tslint:disable-next-line:no-console
          // console.log('TransactionsReport - error: ', e);
           setLoading(false);
           setActionsEnabled(true);

           // Must throw the error in a new hook as I found that error boundary
           // doesn't always catch errors generated from async calls within
           // a hook.
           errorHandler(e);
        }
    }

    effect();

  }, [filterDates.startDate, filterDates.endDate])

  /**
   * Refresh the report data with the new date filters.
   * 
   * @param actionDates The transaction date filters.
   */
  const refreshHandler = (actionDates: ITransactionsActionDates) => {
    // tslint:disable-next-line:no-console
    // console.log('TransactionsReport - refreshHandler: ', actionDates);

    setFilterDates(actionDates);
  }
  
  /**
   * Export the report using the date filters
   * 
   * @param filterDates The sale's transaction date filters.
   */
  const exportHandler = async (exportFilterDates: ITransactionsActionDates) => {
    // tslint:disable-next-line:no-console
    // console.log('TransactionsReport - export: ' + exportFilterDates);
    
    setLoading(true);
    setActionsEnabled(false);

    props.dataService.buildExportReport(serverRows, exportFilterDates.startDate, exportFilterDates.endDate)
      .then(() => {
        setFilterDates(exportFilterDates);
        setLoading(false);
        setActionsEnabled(true);
      });
  }

  /**
   * Wrap the toolbar component, adding the report filters and actions properties.
   */
  const ToolbarComponentWrapper: React.FC<Toolbar.RootProps> = (toolbarProps: Toolbar.RootProps) => {
    return (
      <TransactionsToolbar 
        actionsEnabled={actionsEnabled} 
        refreshAction={refreshHandler} 
        exportAction={exportHandler} 
        actionDates={filterDates}
        {...toolbarProps} 
      />
    )
  }
  
  /**
   * Build the report component.
   */
  return (
    <main className={classes.main}>
      <Grid
        columns={columns}
        rows={displayRows} 
      >
        <PagingState
          defaultCurrentPage={0}
          defaultPageSize={15}
        />
        <IntegratedPaging />
        <Table />
        <Toolbar
           rootComponent = {ToolbarComponentWrapper}
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow 
          rowComponent={ReportHeaderRow}
        />
        <PagingPanel pageSizes={[15, 30, 100]} />
      </Grid>
      {loading && <CircularProgress size={80}  disableShrink={true} className={classes.loadingProgress} />}
    </main>
  );
}

const useStyles = makeStyles((theme: Theme) => {
  // tslint:disable-next-line:no-console
  // console.log('theme: ', theme);
  return createStyles({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    loadingProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
});

export default withReportService<TransactionsReportService>(TransactionsReportBase, TransactionsReportService);
