import { 
  Avatar,
  Button, 
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Paper, 
  Theme,
  Typography, 
 } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { createStyles, makeStyles,} from '@material-ui/styles';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { IRootContext, useCtx } from '../../core/stores/root.context';

import { IAuthenticationStore } from '../../core/stores/authentication';

interface IState {
  username: string;
  password: string;
  errorMsg: string;
  hasError: boolean;
}

interface IProps extends RouteComponentProps<{}> {}

const LoginBase: React.FC<IProps> = (props) => {
  const rootContext: IRootContext = useCtx();

  const initialState: IState = {
    username: '',
    password: '',
    errorMsg: '',
    hasError: false,
  }

  const [state, setState] = React.useState<IState>(initialState);

  const handleLoginError = (err: any): void => {
    // tslint:disable-next-line:no-console
    console.log(err);

    state.password = '';
    state.errorMsg = err.message;
    state.hasError = true;

    setState({...state});
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, authenticationStore: IAuthenticationStore) => {
    event.preventDefault();
    try {
      await authenticationStore.login(state.username, state.password);
    // tslint:disable-next-line:no-console
    // console.log('LoginBase: at handleSubmit - logged in? ' + loggedIn + '  context loggin: ' + rootContext.loginContext.loggedIn);
    }
    catch(err) {
      handleLoginError(err);
    }
  }
    
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const changeValue = event.currentTarget.value;

    if (event.currentTarget.id === 'user') {
      setState({ ...state, username: changeValue })
    }
    if (event.currentTarget.id === 'password') {
      setState({ ...state, password: changeValue })
    }
  }

  const classes = useStyles();

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h5">
        Sign in
      </Typography>
      { showMsg(state.errorMsg, state.hasError, classes.error) }
      <form 
        className={classes.form} 
        onSubmit={
          // tslint:disable-next-line:jsx-no-lambda
          (event) => handleSubmit(event, rootContext.services.authenticationStore) 
        }
      >
        <FormControl margin="normal" required={true} fullWidth={true}>
          <InputLabel htmlFor="user">User name</InputLabel>
          <Input 
            id="user" 
            name="user"
            type="text" 
            autoFocus={true}
            value={state.username}
            onChange={
              // tslint:disable-next-line:jsx-no-lambda
              (event: any) => handleChange(event)
            } 
          />
        </FormControl>
        <FormControl margin="normal" required={true} fullWidth={true}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input 
            name="password" 
            type="password" 
            id="password" 
            autoComplete="current-password" 
            value={state.password}
            onChange={
              // tslint:disable-next-line:jsx-no-lambda
              (event: any) => handleChange(event)
            } 
           />
        </FormControl>
        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={
            // tslint:disable-next-line:jsx-no-lambda
            (event: any) => handleSubmit(event, rootContext.services.authenticationStore)
          } >
        >
          Sign in
        </Button>
      </form>
    </Paper>
    </main>
  );
}

/**
 * Build a text component with the specified msg. If the msg is empty nothing
 * is rendered.
 * 
 * @param msg The text message to render.
 * @param className The class of the text component. 
 */
const showMsg = (msg: string | null, errorFlag: boolean, className: any): any => {
  if (errorFlag) {
    return (
      <div className={className}>
      <Typography variant="body2" color="error" align="left">
        {msg}
      </Typography>
      </div>
    )
  }
  else {
    return null;
  }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  error: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

export default withRouter(LoginBase);
