import { HTTP_ERROR_TYPE, HttpError  } from './http.error';

export const SERVER_DOWN_MESSAGE = 'Server connection is down.';
/**
 * Defines exception thrown when the server is down or the server url is invalid.
 */
export class ServerDownError extends HttpError {
  constructor() {
    super(0, SERVER_DOWN_MESSAGE, HTTP_ERROR_TYPE.SERVER_DOWN);
  }
}
