import axios from 'axios';

import { AxiosResponse } from 'axios';

import { IGetProductsResponse, IProductQueryOptions, SHOPIFY_PRODUCT_FIELD_NAMES } from '../models';

import { IApiProviderUtils } from '../api.provider.utils';

export interface IProductsProvider {
  retrieveProducts(
    shop: string, 
    appId: string,
    options?: IProductQueryOptions,
    fields?: SHOPIFY_PRODUCT_FIELD_NAMES[]): Promise<IGetProductsResponse>;
}

export class ProductsProvider implements IProductsProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  public async retrieveProducts(
    shop: string, 
    appId: string, 
    options?: IProductQueryOptions,
    fields?: SHOPIFY_PRODUCT_FIELD_NAMES[]): Promise<IGetProductsResponse>
  {
    const headers = this.apiProviderUtils.buildDefaultHeaders();

    const url: string = this.baseApiUrl + `shopifyapi/${shop}/${appId}/products`;
    const fullOptions = this.apiProviderUtils.buildFieldOption(options, fields);
    const queryUrl = this.apiProviderUtils.buildQueryString(url, fullOptions);

    return axios.get<IGetProductsResponse>(queryUrl, { headers })
      .then((response: AxiosResponse): any => {
        // tslint:disable-next-line:no-console
        // console.log('retrieveProducts', response.data);
        const responseData = response.data as IGetProductsResponse;

        return responseData;
      })
      .catch(this.apiProviderUtils.handleApiError);
  }
}
