import React from 'react';

import blueGrey from '@material-ui/core/colors/blueGrey';

import {
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { 
  createStyles,
  Theme,
  WithStyles,
  withStyles,
 } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  headerRow: {
    backgroundColor: blueGrey[400],
  },
});

/**
 * Override the background color of the header row.
 */
const ReportHeaderRowBase = (props: Table.RowProps & WithStyles<typeof styles>) => {
  const { children, classes, ...restProps } = props;

  return (
  <TableHeaderRow.Row
    {...restProps}
    className={classes.headerRow}
  >
    {children}
  </TableHeaderRow.Row>
)};

const ReportHeaderRow = withStyles(styles, { name: 'ReportHeaderRow.Row' })(ReportHeaderRowBase);
export default ReportHeaderRow;
