import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';

// A theme with custom primary and secondary color.
const themeBuilder = createMuiTheme({
    palette: {
      primary: {
        light: '#e5e5e5',
        main: '#727272',
        dark: '#363839',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff5e50',
        main: '#e41e26',
        dark: '#a90000',
        contrastText: '#fff',
      },
    },
  } as any);

function withRoot(Component: React.ComponentType) {
  function WithRoot(props: object) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={themeBuilder}>
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
