import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { IErrorHandlerProps, RESET_ACTION_TYPES } from '../../ErrorBoundary';

import { HTTP_ERROR_TYPE, HttpError } from '../../core/providers/api.provider';

const styles = (theme: Theme) => createStyles({
  root: {
    textAlign: 'left',
    paddingTop: theme.spacing(),
    paddingLeft: 5,
    paddingRight: 5,
  },
});

interface IErrorState {
  error: Error | undefined;
}

interface IErrorAction {
  payload: IErrorState;
} 

const initialErrorState: IErrorState = { error: undefined };

export type ErrorHandler = (error: Error) => void;

/**
 * Custom hook to handle exceptions from other component hooks.
 *  
 */
export const useErrorHandler: () => ErrorHandler = () => {
  const [/* errorState */, dispatch] = 
    React.useReducer<React.Reducer<IErrorState, IErrorAction>>(reducer, initialErrorState);

  return (error) => {
    dispatch({ payload: { error }});
  };
};

const reducer: React.Reducer<IErrorState, IErrorAction> = (state, action) => {
      // tslint:disable-next-line:no-console
      // console.log('SalesReport - useReducer: ', state, '  Action: ', action);
  if (!state.error) {
    if( action.payload.error) {
      // tslint:disable-next-line:no-console
      // console.log('reducer handle known error: ', action.payload.error.message);
      throw action.payload.error;
    } else {
      // tslint:disable-next-line:no-console
      console.log('error reducer handle unknown error: ', action.payload);
      
      throw new Error('Unknown error type');
    }
  } 

  return { error: undefined };
}

export interface IProps extends RouteComponentProps<{}>, WithStyles<typeof styles>, IErrorHandlerProps {}

/**
 * The handler for errors generated by report components and underlying services.
 */
const ErrorHandlerBase: React.FC<IProps> = (props) => {
  const { classes, onReset, error, ...restProps } = props;
  let message: string = error.message;
  let resetAction: RESET_ACTION_TYPES = RESET_ACTION_TYPES.ERROR_PAGE;
  
  // tslint:disable-next-line:no-console
  console.log('Reports error handler - error: ' + error + '  error type:' + (error as HttpError).type);

  if ((error as HttpError).isHandledError) {
    const httpError: HttpError = error as HttpError;

    if (httpError.type === HTTP_ERROR_TYPE.SERVER_DOWN) {
      message = 'Server is down';
      resetAction = RESET_ACTION_TYPES.ERROR_PAGE;
    } else if (httpError.type === HTTP_ERROR_TYPE.INVALID_CREDENTIALS) {
      message = 'Credentials expired - Please login again.';
      resetAction = RESET_ACTION_TYPES.REAUTHORIZE;
    } else if (httpError.type === HTTP_ERROR_TYPE.UNAUTHORIZED_ACCESS) {
      message = 'Unauthorized access: notify administrator';
      resetAction = RESET_ACTION_TYPES.REAUTHORIZE;
    } else if (httpError.type === HTTP_ERROR_TYPE.HTTP) {
      message = 'Unknown http error - ' + error.message;
      resetAction = RESET_ACTION_TYPES.ERROR_PAGE;
    }
  }
  
  const resetHandler = () => onReset({message, action: resetAction});

  return (
    <React.Fragment>
      <div {...restProps}>{message}</div>
      <Button
        onClick={resetHandler}>
        Return to report
      </Button>
    </React.Fragment>
  )
}

export default withStyles(styles)(withRouter(ErrorHandlerBase));
