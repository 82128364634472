import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { IApiProvider } from '../../core/providers/api.provider';
import { IEnvironmentStore } from '../../core/stores/environment';
import { IRootContext, useCtx } from '../../core/stores/root.context';

interface IAboutPageProps {
  name: string;
}

interface IProps extends RouteComponentProps<{}>, IAboutPageProps {}
interface IVersionInfo {
  server: string;
  api: string;
}

const AboutPageBase: React.FC<IProps> = (props) => {
  const rootContext: IRootContext = useCtx();
  const environmentStore: IEnvironmentStore = rootContext.services.environmentStore;
  const apiProvider: IApiProvider = rootContext.services.apiProvider;

  const [versionInfo, setVersionInfo] = useState<IVersionInfo>({server: '', api: ''});

  /**
   * Retrieve the server version information.
   */
  React.useEffect(() => {
    const effect = async () => {
      const version: IVersionInfo = await apiProvider.getVersionProvider().retrieveVersion();

      setVersionInfo(version);
    }

    effect();
  }, []);

  const classes = useStyles();

    // tslint:disable-next-line:no-console
    // console.log(props);
    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom={false} align="center">
          Client information
        </Typography>
          <Typography variant="body1" align="center">
            version: {environmentStore.getVersion()}
          </Typography>
          <Typography variant="body1" gutterBottom={true} align="center">
            Runtime type: {environmentStore.production() ? 'Production' : 'Development'}
          </Typography>
        <Typography variant="h5" gutterBottom={false} align="center">
          Server version information
        </Typography>
          <Typography variant="body1" align="center">
              server: {versionInfo.server}
          </Typography>
          <Typography variant="body1" align="center">
              api: {versionInfo.api}
          </Typography>
      </div> 
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'left',
    paddingTop: theme.spacing(20),
    paddingLeft: 15,
    paddingRight: 15,
  },
}));


export default withRouter(AboutPageBase);
