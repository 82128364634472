import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';
import { ContextProvider } from './core/stores/root.context';

import './index.css';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <ContextProvider>
    <App />
  </ContextProvider>,  
  document.getElementById('root') as HTMLElement
);

registerServiceWorker();
