import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { createMuiTheme } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import indigo from '@material-ui/core/colors/indigo';

import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { IServiceProps } from './withReportService';

import InventoryReport from './inventory.report/InventoryReport';
import SalesReport from './sales.report/SalesReport';
import TransactionsReport from './transactions.report/TransactionsReport';

import ReportsErrorHandler from './ReportsErrorHandler';

import ErrorBoundary from '../../ErrorBoundary';

interface ITildeReportsProps {
  shop: string;
  appId: string;
}

export interface IProps extends RouteComponentProps<{}>, WithStyles<typeof styles>, ITildeReportsProps {}

const reportsTheme = (theme: any) => {
  const overrideTheme =  {
    ...theme,
    overrides: {
    },
  }

  return overrideTheme;
};

const TildeReportsBase: React.FC<IProps> = (props) => {
  const { classes, shop, appId, ...restProps } = props;
  
  const [tabNum, setTabNum] = React.useState(0);
  const handleChangeTab = (event: React.ChangeEvent<{}>, newTabNum: number) => {
    setTabNum(newTabNum)
  }

  interface TabContainerProps {
    shop: string;
    children?: React.ReactNode;
  }
  
  function TabContainer(tabContainerProps: TabContainerProps) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {tabContainerProps.children}
      </Typography>
    );
  }
  
  const fullTheme = (theme: any) => {
    return createMuiTheme(reportsTheme(theme));
  }

  interface IReportWithErrorHandlerProps extends IServiceProps, RouteComponentProps {
    reportComponent: React.ComponentType<IServiceProps>;
  }

  const ReportWithErrorHandler: React.FC<IReportWithErrorHandlerProps> = (reportProps) => {
    const {reportComponent: ReportComponent, ...serviceProps} = reportProps;
    const errorBoundaryProps = serviceProps as RouteComponentProps;

    return (
      <ErrorBoundary
        errorHandler={ReportsErrorHandler}
        {...errorBoundaryProps}
      >
        <ReportComponent
          {...serviceProps}
        /> 
      </ErrorBoundary> 
    )
  }

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={fullTheme}>
        <AppBar position="static" classes={{root: classes.tabs_root}}>
          <Tabs value={tabNum} onChange={handleChangeTab}>
            <Tab label="Sales Report" />
            <Tab label="Inventory Report" />
            <Tab label="Transaction Report" />
          </Tabs>
        </AppBar>
        {tabNum === 0 && <TabContainer shop={shop}><ReportWithErrorHandler reportComponent={SalesReport} shop={shop} appId={appId} {...restProps}/></TabContainer>}
        {tabNum === 1 && <TabContainer shop={shop}><ReportWithErrorHandler reportComponent={InventoryReport} shop={shop} appId={appId} {...restProps}/></TabContainer>}
        {tabNum === 2 && <TabContainer shop={shop}><ReportWithErrorHandler reportComponent={TransactionsReport} shop={shop} appId={appId} {...restProps}/></TabContainer>}
      </MuiThemeProvider>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({
  root: {
    textAlign: 'left',
    paddingTop: theme.spacing(),
    paddingLeft: 5,
    paddingRight: 5,
  },
  tabs_root: {
    background: indigo[400],
  },
});

export default withStyles(styles)(withRouter(TildeReportsBase));
