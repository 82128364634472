import * as Excel from 'exceljs';

import { FieldTypes } from '../../reportDefinitions';

import { COLUMN_KEY_TITLES } from '../InventoryReportDefinition';

/**
 * Defines the columns and order for the inventory Xcel sheet.
 */
export const XcelHeaderTypes: FieldTypes[] = [
  FieldTypes.SKU,
  FieldTypes.VENDOR,
  FieldTypes.TITLE,
  FieldTypes.VARIANT_TITLE,
  FieldTypes.PRICE,
  FieldTypes.QTY,
  FieldTypes.COST,
  FieldTypes.COLLECTION,
  FieldTypes.SUB_COLLECTION,
  FieldTypes.SORT_CODE,
  FieldTypes.UPDATED_AT,
];
export const XcelCategoryGroupingHeaderTypes: FieldTypes[] = [
  FieldTypes.SKU,
  FieldTypes.VENDOR,
  FieldTypes.TITLE,
  FieldTypes.VARIANT_TITLE,
  FieldTypes.PRICE,
  FieldTypes.QTY,
  FieldTypes.COST,
  FieldTypes.SUB_COLLECTION,
  FieldTypes.SORT_CODE,
  FieldTypes.UPDATED_AT,
];

export interface IRow {
  sku: string;
  vendor: string;
  title: string;
  price: number;
  quantity: number;
  cost: number;
  collections: string;
  sub_collection: string;
  updated_at: string;
  sort_code: string;
  variant_title: string;
}

const DEFAULT_COLUMN_STYLE: Partial<Excel.Style> = {
  alignment: {horizontal: 'left'},
  font: { size: 10, name: 'Arial', family: 2, charset: 1 },
}
const DEFAULT_NUM_COLUMN_STYLE: Partial<Excel.Style> = {
  alignment: {horizontal: 'right'},
  font: { size: 10, name: 'Arial', family: 2, charset: 1 },
}

const DEFAULT_COLUMN_METADATA : Partial<Excel.Column> = {
  header: 'Default Header', 
  key: 'default',
  hidden: false,
  width: 11,
  outlineLevel: 0,
  style: {...DEFAULT_COLUMN_STYLE},
}

const SKU_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SKU)!.key,
}

const VENDOR_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 18,
  header: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.VENDOR)!.key,
}

const TITLE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 25,
  header: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.TITLE)!.key,
}

const VARIANT_TITLE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 25,
  header: COLUMN_KEY_TITLES.get(FieldTypes.VARIANT_TITLE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.VARIANT_TITLE)!.key,
}

const PRICE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 11,
  header: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.PRICE)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE, 
          numFmt: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00'},
}

const COST_METADATA: Partial<Excel.Column> = {
  ...PRICE_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.COST)!.key,
}

const UPDATED_AT_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 12,
  header: COLUMN_KEY_TITLES.get(FieldTypes.UPDATED_AT)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.UPDATED_AT)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE,
          alignment: {horizontal: 'center'},
          numFmt: 'MM/DD/YYYY'},
}

const QTY_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 6,
header: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.QTY)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE, 
    numFmt: 'General'},
}

const COLLECTION_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 20,
  header: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTION)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.COLLECTION)!.key,
}

const SUB_COLLECTION_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 20,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SUB_COLLECTION)!.key,
}

const SORT_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 11,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SORT_CODE)!.key,
}

export const SheetColumnMetaData = new Map<FieldTypes, Partial<Excel.Column>>([
  [FieldTypes.SKU,  SKU_METADATA],
  [FieldTypes.COLLECTION, COLLECTION_METADATA],
  [FieldTypes.SUB_COLLECTION, SUB_COLLECTION_METADATA],
  [FieldTypes.PRICE, PRICE_METADATA],
  [FieldTypes.COST, COST_METADATA],
  [FieldTypes.UPDATED_AT, UPDATED_AT_METADATA],
  [FieldTypes.QTY, QTY_METADATA],
  [FieldTypes.SORT_CODE, SORT_METADATA],
  [FieldTypes.TITLE, TITLE_METADATA],
  [FieldTypes.VARIANT_TITLE, VARIANT_TITLE_METADATA],
  [FieldTypes.VENDOR, VENDOR_METADATA],
]);
