import * as Excel from 'exceljs';

import { FieldTypes } from '../../reportDefinitions';
import { XlsxFieldColumnNumber } from '../../xlsx/xlsxDefinitions';

import { COLUMN_KEY_TITLES } from '../transactionsReportDefinition';

export interface IRow {
  order_number: string;
  total: number;
  source: string;
  discount: number;
  discount_code: string;
  refunds: number;
  date: string;
}

/**
 * Defines the summation column number for the transactions xlsx report.
 * Excel columns start with column 1.
 */
export const XlsxSummationColumns: XlsxFieldColumnNumber = new Map<FieldTypes, number>([
  [FieldTypes.SUMMATION_TOTAL_LABEL, 1],
  [FieldTypes.SUMMATION_TOTAL, 2],
  [FieldTypes.SUMMATION_DISCOUNTS_TOTAL_LABEL, 3],
  [FieldTypes.SUMMATION_DISCOUNTS_TOTAL, 4],
  [FieldTypes.SUMMATION_REFUNDS_TOTAL_LABEL, 5],
  [FieldTypes.SUMMATION_REFUNDS_TOTAL, 6],
])

const DEFAULT_COLUMN_STYLE: Partial<Excel.Style> = {
  alignment: {horizontal: 'left'},
  font: { size: 10, name: 'Arial', family: 2, charset: 1 },
}
const DEFAULT_NUM_COLUMN_STYLE: Partial<Excel.Style> = {
  alignment: {horizontal: 'right'},
  font: { size: 10, name: 'Arial', family: 2, charset: 1 },
}

const DEFAULT_COLUMN_METADATA : Partial<Excel.Column> = {
  header: 'Default Header', 
  key: 'default',
  hidden: false,
  width: 11,
  outlineLevel: 0,
  style: {...DEFAULT_COLUMN_STYLE},
}

const DEFAULT_DOLLARS_COLUMN_METADATA : Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 11,
  style: {...DEFAULT_NUM_COLUMN_STYLE, 
    numFmt: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00'},
}

const ORDER_NUMBER_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 14,
  header: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NUMBER)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.ORDER_NUMBER)!.key,
}

const TOTAL_PRICE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_DOLLARS_COLUMN_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_PRICE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_PRICE)!.key,
}

const SOURCE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 25,
  header: COLUMN_KEY_TITLES.get(FieldTypes.SOURCE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.SOURCE)!.key,
}

const DISCOUNT_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_DOLLARS_COLUMN_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_DISCOUNTS)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.TOTAL_DISCOUNTS)!.key,
}

const DISCOUNT_CODE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 25,
  header: COLUMN_KEY_TITLES.get(FieldTypes.DISCOUNT_CODE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.DISCOUNT_CODE)!.key,
}

const REFUNDS_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_DOLLARS_COLUMN_METADATA,
  header: COLUMN_KEY_TITLES.get(FieldTypes.REFUNDS)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.REFUNDS)!.key,
}

const DATE_METADATA: Partial<Excel.Column> = {
  ...DEFAULT_COLUMN_METADATA,
  width: 12,
  header: COLUMN_KEY_TITLES.get(FieldTypes.DATE)!.title,
  key: COLUMN_KEY_TITLES.get(FieldTypes.DATE)!.key,
  style: {...DEFAULT_NUM_COLUMN_STYLE,
          alignment: {horizontal: 'center'},
          numFmt: 'MM/DD/YYYY'},
}

export const XlsxSheetColumnMetaData = new Map<FieldTypes, Partial<Excel.Column>>([
  [FieldTypes.ORDER_NUMBER, ORDER_NUMBER_METADATA],
  [FieldTypes.TOTAL_PRICE, TOTAL_PRICE_METADATA],
  [FieldTypes.SOURCE, SOURCE_METADATA],
  [FieldTypes.TOTAL_DISCOUNTS, DISCOUNT_METADATA],
  [FieldTypes.DISCOUNT_CODE, DISCOUNT_CODE_METADATA],
  [FieldTypes.REFUNDS, REFUNDS_METADATA],
  [FieldTypes.DATE, DATE_METADATA],
])
