import React from "react";

import Moment from 'moment';

import Button from "@material-ui/core/Button";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Update from '@material-ui/icons/Update';

import DatePicker from '../components/DatePicker';
import FilterMenu from '../components/FilterMenu';
import ReportToolbar from '../components/ReportToolbar';

const styles = (theme: Theme) => createStyles({
  button: {
    variant:"contained",
    color:"default",
    'justify-content':"center",
    'align-self':'flex-end',
    width:'100%',
    'vertical-align':'middle',
    margin:'auto',
  },
  buttonWrapper: {
    display:'flex',
    height:'100%',
  },
  dateIcon: {
  },
  exportIcon: {
  },
  updateIcon: {
  },
  actionContainer: {
    display: 'flex',
    'justify-content': 'left',
    height: '100%',
  },
  filterContainer: {
    display: 'flex',
    'justify-content': 'left',
  },
  label: {
    display:'block',
  }
});

export interface ISalesActionDates {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Sales toolbar properties.
 * @param actionsEnabled flag indicating that the action buttons are enabled or not.
 * @param actionDates Starting and ending transaction dates for date filtering.
 * @param refreshAction function called with the action dates when the refresh button is clicked.
 * @param exportAction function called with the action dates when the export button is clicked.
 */
export interface ISalesToolbarProps extends WithStyles<typeof styles> {
  actionsEnabled?: boolean;
  actionDates?: ISalesActionDates;
  collections: string[];
  collectionFilter: string;
  refreshAction: (actionDates: ISalesActionDates) => void;
  exportAction: (actionDates: ISalesActionDates) => void;
  filterAction: (collection: string) => void;
}

/**
 * Renders the Sales Report toolbar and handles toolbar actions.
 */
class SalesToolbar extends React.Component<ISalesToolbarProps, {}> {
  private startDate: Date | undefined;
  private endDate: Date | undefined;

  /**
   * Initialize component state 
   */
  constructor(props: ISalesToolbarProps) {
    super(props);

    this.startDate = props.actionDates!.startDate;
    this.endDate = props.actionDates!.endDate;
  }
  
  /**
   * Handler for export button clicks. Calls the function specified by the exportAction property.
   */
  public handleExportClick = () => {
    // tslint:disable-next-line:no-console
    // console.log("clicked on export icon!: StartDate - " + this.startDate + '  endDate: ' + this.endDate);

    this.props.exportAction({startDate: this.startDate, endDate: this.endDate});
  }
  
  /**
   * Handler for refresh button clicks. Calls the function specified by the refreshAction property.
   */
  public handleRefreshClick = () => {
    this.props.refreshAction({startDate: this.startDate, endDate: this.endDate});
  }
  
  public handleEndDateSet = (date?: Date) => {
    this.endDate = date ? Moment(date).endOf('day').toDate() : undefined;
  }
  
  public handleStartDateSet = (date?: Date) => {
    this.startDate = date ? Moment(date).startOf('day').toDate() : undefined;
  }

  /**
   * Handle click on a collection filter menu item. The action is simply forwarded
   * to the filterAction specified by the parent component.
   */
  public handleCollectionFilter = (collection: string) => {
    this.props.filterAction(collection);
  }

  
  /**
   * Builds the inventory filters toolbar component.
   */
  public salesFilters: () => React.ReactNode  = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.filterContainer}>
          <div>
            <Tooltip title={"Select Start Date"}>
              <span>
                <DatePicker id="startDate" label="Start Date" onChange={this.handleStartDateSet} initialDate={this.startDate}/>
              </span>
            </Tooltip>
          </div>
          <div>
            <Tooltip title={"Select End Date"}>
              <span>
                <DatePicker id="endDate" label="End Date" onChange={this.handleEndDateSet}  initialDate={this.endDate}/>
              </span>
            </Tooltip>
          </div>
          <div>
            <Tooltip title={"Categories"}>
              <span>
                <FilterMenu 
                  onChange={this.handleCollectionFilter}  
                  filters={this.props.collections} 
                  selected={this.props.collectionFilter} 
                  title={'Categories'} />
              </span>
            </Tooltip>
          </div>
        </div>
      </React.Fragment>
    )
  }
  
  /**
   * Builds the sales actions toolbar component.
   */
  public salesActions: () => React.ReactNode = () => {
    const { actionsEnabled, classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.actionContainer}>
          <div>
            <Tooltip title={"Refresh"}>
              <div className={classes.buttonWrapper}>
                <Button className={classes.button} onClick={this.handleRefreshClick} disabled={!actionsEnabled}>
                  Refresh<br/>
                  <Update className={classes.updateIcon} />
                </Button>
              </div>
            </Tooltip>
          </div>
          <div>
            <Tooltip title={"Export"}>
              <div className={classes.buttonWrapper}>
                <Button className={classes.button} onClick={this.handleExportClick} disabled={!actionsEnabled}>
                  Export<br/>
                  <CloudUpload className={classes.exportIcon} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </React.Fragment>
    )
  }

  /**
   * Renders the sales toolbar using the salesFilters and salesActions components.
   */
  public render() {
    return (
      <ReportToolbar filters={this.salesFilters} actions={this.salesActions} />
    );
  }

}

export default withStyles(styles, { name: "SalesToolbar" })(SalesToolbar);
