import * as React from 'react';

import { Link } from "react-router-dom";

import { 
  Collapse,
  createStyles, 
  List, 
  ListItem, 
  ListItemIcon,
  ListItemText,
  Theme, 
  WithStyles, 
  withStyles, 
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { INavLinkItem } from './NavMenuItem';

export interface INavFolderProps extends WithStyles<typeof styles> {
  text: string;
  open?: boolean;
  handleItemSelect: () => void;
  items: INavLinkItem[];
}

export interface INavState {
    tildeReportsFolderOpen: boolean;
}

class NavMenuFolder extends React.Component<INavFolderProps, {}> {
  public props: INavFolderProps;
  public state: INavState;

  constructor(props: INavFolderProps) {
    super(props);
    this.props = props
    this.state = {
        tildeReportsFolderOpen: this.props.open === true,
    };
  }

  public buildFolderItems(items: INavLinkItem[]) {
    const folderItems =  items.map((item: INavLinkItem) => {
      return (
        <ListItem key={item.path}>
          <ListItemIcon>
            {item.icon()}
          </ListItemIcon>
          <Link to={{pathname: item.path}}  onClick={this.props.handleItemSelect}>{item.text}</Link>
        </ListItem>
      );
    });

    return folderItems;
  }

  public render() {
    return (
      <div >
        <ListItem button={true} onClick={this.handleTildeReportsFolderClick}>
          <ListItemText inset={false} primary={this.props.text} />
          {this.state.tildeReportsFolderOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.tildeReportsFolderOpen} timeout="auto" unmountOnExit={true}>
          <List>
            {this.buildFolderItems(this.props.items)}
          </List>
        </Collapse>
      </div>
    );
  }

  public handleTildeReportsFolderClick = () => {
    this.setState({ tildeReportsFolderOpen: !this.state.tildeReportsFolderOpen });
  };
}

const styles = (theme: Theme) => createStyles({
});

export default withStyles(styles)(NavMenuFolder);
