export class Permission {
  public readonly subject: string;
  public readonly manage: boolean;
  public readonly view: boolean;
  public readonly execute: boolean;

  constructor(
    subject: string,
    canView: boolean,
    canManage: boolean,
    canExecute: boolean = false
  ) {
    this.subject = subject;
    this.view = canView;
    this.manage = canManage;
    this.execute = canExecute;
  }
}
