import { Permission } from '../authorization/permission';
import { Session } from './session';

export interface ISessionStore {
  getStorageKey(): string;
  getSession(): Session;
  restoreSession(): Session;
  archiveSession(): void;
  archiveSessionToSessionStorage(): void;
  restoreSessionFromSessionStorage(): boolean;
  clearArchivedSession(): void;
  clearSession(): void;
  clearAllSessions(): void;
}

export class SessionStore implements ISessionStore {
  private storageKey = 'sessionStorageBackup';

  /**
   * Holds the Session controlled by the session manager.
   */
  private session: Session;

  constructor() {
    this.session = new Session();
  }

  /*
   * Get the key used for session and local storage.
   *
   * @returns {string}
   */
  public getStorageKey(): string {
    return this.storageKey;
  }

  public getSession(): Session {
    return this.session;
  }

  /**
   * Restore the session from LocalStorage.
   * @returns Restores the session from local storage.
   */
  public restoreSession(): Session {
    this.clearSession();
    this.restoreSessionStorageFromLocalStorage();
    this.restoreSessionFromSessionStorage();

    return this.getSession();
  }

  public archiveSession(): void {
    this.clearArchivedSession();
    this.archiveSessionToSessionStorage();
    this.archiveSessionStorageToLocalStorage();
  }

  /**
   * Persist the current session properties to the browser's SessionStorage.
   *
   * Developer Note: public access for unit testing.
   */
  public archiveSessionToSessionStorage(): void {
    const backup = {};
    const session = this.session;

    backup['userName'] = session.userName;
    backup['slug'] = session.slug;

    // Serialize the permissions Map as an array.
    const permissions: Permission[] = [];
    session.getPermissions().forEach((permission: Permission) => {
      permissions.push(permission);
    });

    backup['userPermissions'] = permissions;
    backup['userShopifyShops'] = session.getUserShops();
    backup['accessToken'] = session.accessToken;

    sessionStorage.setItem(this.storageKey, JSON.stringify(backup));
  }

  /**
   * Restores the session properties from the browser's SessionStorage.
   *
   * @return true if the session was in SessionStorage.
   *
   * Developer Note: public access for unit testing.
   */
  public restoreSessionFromSessionStorage(): boolean {
    this.clearSession();

    const sessionStorageText: string | null = sessionStorage.getItem(this.storageKey);
    let sessionObject: Object;

    if (sessionStorageText && sessionStorageText !== 'null') {
      try {
        sessionObject = JSON.parse(sessionStorageText);
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(
          'Session storage parsing error. value: %s',
          sessionStorageText
        );
        return false;
      }

      this.session.userName = sessionObject['userName'];
      this.session.slug = sessionObject['slug'];

      sessionObject['userPermissions'].forEach((sessionPermission: any) => {
        const permission: Permission = new Permission(
          sessionPermission.subject,
          sessionPermission.view,
          sessionPermission.manage,
          sessionPermission.execute
        );

        this.session.permission = permission;
      });

      this.session.clearUserShops();
      sessionObject['userShopifyShops'].forEach((shop: string) => {
        this.session.addUserShop(shop);
      });

      this.session.accessToken = sessionObject['accessToken'];
    }

    return true;
  }

  public clearArchivedSession(): void {
    sessionStorage.removeItem(this.storageKey);
    localStorage.removeItem(this.storageKey);
  }

  public clearSession(): void {
    this.session.userName = null as unknown as string;
    this.session.slug = null as unknown as string;
    this.session.clearPermissions();
    this.session.clearUserShops();
    this.session.accessToken = null as unknown as string;
  }

  public clearAllSessions(): void {
    this.clearSession();
    this.clearArchivedSession();
  }

  /**
   * Copy session properties from local storage to session storage.
   */
  private restoreSessionStorageFromLocalStorage(): void {
    sessionStorage.removeItem(this.storageKey);
    if (localStorage.getItem(this.storageKey)) {
      sessionStorage.setItem(
        this.storageKey,
        localStorage.getItem(this.storageKey) as string
      );
    }
  }

  private archiveSessionStorageToLocalStorage() {
    localStorage.setItem(
      this.storageKey,
      sessionStorage.getItem(this.storageKey) as string
    );
  }
}
