/**
 * Defines the default settings for xslx work sheets used in Tilde reports.
 */
import * as Excel from 'exceljs';

export const DEFAULT_ROW_HEIGHT = 12.8;
export const DEFAULT_MARGINS: Excel.Margins = {
  left: 0.7,
  right: 0.7,
  top: 0.75,
  bottom: 0.75,
  header: 0.3,
  footer: 0.3,
}

export const DEFAULT_PAGESETUP: Partial<Excel.PageSetup> = {
  margins: {...DEFAULT_MARGINS},
  paperSize: 9, 
  orientation:'landscape',
  fitToPage: true,
  fitToHeight: 1, 
  fitToWidth: 1,
  blackAndWhite: true,
}
