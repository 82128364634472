export enum HTTP_ERROR_TYPE {
  UNAUTHORIZED_ACCESS,
  SERVER_DOWN,
  HTTP,
  INVALID_CREDENTIALS
}

/**
 * Defines http generated exceptions.
 */
export class HttpError extends Error {
  public readonly isHandledError = true;
  public readonly status: number;
  public readonly type: HTTP_ERROR_TYPE;

  constructor(
    status: number,
    message: string,
    type: HTTP_ERROR_TYPE = HTTP_ERROR_TYPE.HTTP
  ) {
    super(message);

    this.status = status;
    this.type = type;
  }
}
