import axios from 'axios';
import { AxiosResponse } from 'axios';

import moment from 'moment';

import { IGetOrdersResponse, IOrdersQueryOptions } from '../models';

import { IApiProviderUtils } from '../api.provider.utils';

export interface IOrdersProvider {
  retrieveOrders(
    shop: string, 
    appId: string,
    options?: IOrdersQueryOptions): Promise<IGetOrdersResponse>;
}

export class OrdersProvider implements IOrdersProvider {
  private baseApiUrl: string;

  constructor(private readonly apiProviderUtils: IApiProviderUtils) {
    this.baseApiUrl =  this.apiProviderUtils.getBaseApiUrl();
  }

  public async retrieveOrders(
    shop: string, 
    appId: string,
    options?: IOrdersQueryOptions): Promise<IGetOrdersResponse>
  {
    const headers = this.apiProviderUtils.buildDefaultHeaders();
    const url: string = this.baseApiUrl + `shopifyapi/${shop}/${appId}/orders`;

    const apiOptions: any = {...options};

    apiOptions.fields = options!.fields!.join(',');
    if (options!.processed_at_max) {
      apiOptions.processed_at_max = moment(options!.processed_at_max)
        .endOf('day')
        .format();
    }
    if (options!.processed_at_min) {
      apiOptions.processed_at_min = moment(options!.processed_at_min)
        .startOf('day')
        .format();
    }
    if (options!.updated_at_max) {
      apiOptions.updated_at_max = moment(options!.updated_at_max)
        .endOf('day')
        .format();
    }
    if (options!.updated_at_min) {
      apiOptions.updated_at_min = moment(options!.updated_at_min)
        .startOf('day')
        .format();
    }

    const queryUrl = this.apiProviderUtils.buildQueryString(url, apiOptions);

    return axios.get<IGetOrdersResponse>(queryUrl, { headers })
      .then((response: AxiosResponse): any => {
        // tslint:disable-next-line:no-console
        // console.log('retrieveSales', response.data);
        const responseData = response.data as IGetOrdersResponse;

        return responseData;
      })
      .catch(this.apiProviderUtils.handleApiError);
  }
}
