import { IJwtHelper } from '../../utils/jwt.helper';

import { IApiProvider} from '../../providers/api.provider';
import { Permission } from '../authorization/permission';
import { Session, SessionStore } from '../session';

import { IOnContextChange } from '../root.context';

import { IGetShopsResponse, ILoginResponse } from '../../providers/api.provider/models';
import { HttpError } from '../../providers/api.provider/models';

export interface IAuthenticationStore {
  isLoggedIn: boolean;
  logout(): boolean;
  activeLoggin(): boolean;
  tokenExpired(): boolean;
  login(username: string, password: string): Promise<boolean>;
  restoreSession(): boolean;
  reAuthenticate(): boolean;
}

/**
 * Provides a base for authentication workflow.
 */
export class AuthenticationStore  implements IAuthenticationStore  {
  private loggedIn: boolean;

  constructor(
    private readonly sessionStore : SessionStore,
    private readonly apiProvider: IApiProvider,
    private readonly jwtHelper: IJwtHelper,
    private readonly onContextChange: IOnContextChange
  ) {
    this.loggedIn = false;
  }

  get isLoggedIn(): boolean {
    return this.loggedIn;
  }

  // This method will log the user out
  public logout(): boolean {
    this.sessionStore.clearAllSessions();
    this.setLoggedIn(false);
    this.onContextChange.logout();

    return true;
  }

  public async login(username: string, password: string): Promise<boolean> {
    return this.apiProvider.getLoginProvider().doLogin(username, password)
      .then(remoteLoginResponse => {
        return this.handleLogin(remoteLoginResponse);
      })
      .then(loggedIn => {
        if (loggedIn) {
          return this.retrieveSessionShops();
        }

        return loggedIn;
      })
      .then(loggedIn => {
        this.setLoggedIn(loggedIn);
        this.onContextChange.login();

        return loggedIn;
      })
      .catch(err => {
        return this.handleLoginError(err);
      });
  }

  public setLoggedIn(loggedIn: boolean): void {
    this.loggedIn = loggedIn;
  }

  // Finally, this method will check to see if the user is logged in. We'll be able to tell by checking to
  // see if they have a token and whether that token is valid or not.
  public activeLoggin(): boolean {
    const sessionExpired = this.tokenExpired();

    if (this.isLoggedIn && sessionExpired) {
      this.setLoggedIn(false);
    }

    return this.isLoggedIn;
  }

  public tokenExpired(): boolean {
    const accessToken: string = this.sessionStore.getSession().accessToken;
    let tokenExpired = false;

    try {
      tokenExpired = this.jwtHelper.tokenExpired(accessToken, new Date());
    } catch (err) {
      tokenExpired = true;
    }

    return tokenExpired;
  }

  /**
   * Forces a reauthenticate action. 
   * The current session's access token is cleared as if the session timed out,
   * The app is then re-rendered.
   */
  public  reAuthenticate(): boolean {
    this.sessionStore.getSession().clearAccessToken();
    this.onContextChange.reAuthenticate();

    return true;
  }

  public restoreSession(): boolean {
    this.setLoggedIn(false);
    
    const session: Session = this.sessionStore.restoreSession();
    if (session.userName && session.accessToken && !this.tokenExpired()) {
      this.setLoggedIn(true);
    }

    return true;
  }

  private handleLogin(loginResponse: ILoginResponse): boolean {
    // Ensure the login session is cleaned up.
    this.sessionStore.clearAllSessions();

    const session: Session = this.sessionStore.getSession();

    session.userName = loginResponse.name;
    session.slug = loginResponse.slug;
    session.accessToken = loginResponse.token;

    session.clearPermissions();

    const permissions: Permission[] = loginResponse.permissions;
    permissions.forEach(permission => {
      // TODO: Even though this works, should change name to session.addPermission( see Session.permission ).
      session.permission = permission;
    });

    this.sessionStore.archiveSession();

    return true;
  }

  private handleLoginError(httpError: HttpError): Promise<boolean> {
    this.sessionStore.clearAllSessions();

    this.setLoggedIn(false);

    return Promise.reject(httpError);
  }

  private async retrieveSessionShops(): Promise<boolean> {
    const session: Session = this.sessionStore.getSession();

    return this.apiProvider.getShopsProvider().retrieveShops()
      .then((shopsResponse: IGetShopsResponse) => {
        shopsResponse.shops.forEach(shop => {
          session.addUserShop(shop.name);
        })
        this.sessionStore.archiveSession();

        return true;
      })
      .catch(err => {
        return this.handleLoginError(err);
      });
  }
}
