import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import moment from 'moment';

import { FieldTypes } from '../reportDefinitions';

import { DEFAULT_PAGESETUP, DEFAULT_ROW_HEIGHT } from './sheetMetadata';

// Developer Note: 'A' added as the array's 0 element as a placeholer so
//  column letters can be referenced by a 1 base number.
const COLUMNLETTERS = ['A', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q'];

export const createWorkbook = (creator: string): Excel.Workbook => {
  const workbook = new Excel.Workbook();

  workbook.creator = creator;
  workbook.created = new Date();
  workbook.modified = new Date();

  return workbook;
}

/**
 * Create a workbook worksheet with default properties.
 */
export const createWorksheet =  (workbook: Excel.Workbook, worksheetName: string): Excel.Worksheet => {
  const workSheetOptions: Partial<Excel.AddWorksheetOptions> = {
    properties: {defaultRowHeight: DEFAULT_ROW_HEIGHT},
    state: 'visible',
    pageSetup: { ...DEFAULT_PAGESETUP },
  }
  
  return workbook.addWorksheet(worksheetName, workSheetOptions);
}

  /**
   * Build the sheet's columns. 
   */
export const buildColumns = (columnTypes: FieldTypes[], columnMetadata: Map<FieldTypes, Partial<Excel.Column>>): Array<Partial<Excel.Column>> => {
  const columns: Array<Partial<Excel.Column>> = [];

  columnTypes.forEach((fieldType) => {
    if (columnMetadata.has(fieldType)) {
      columns.push(columnMetadata.get(fieldType)!);
    }
  })

  return columns;
}

export const setHeaderStyles = (worksheet: Excel.Worksheet): void => {
  // Set header text to bold and centered
  const row1: Excel.Row = worksheet.getRow(1);
  row1.eachCell(cell => {
    cell.style!.alignment = {...cell.style.alignment, horizontal: 'center'};
    cell.style!.font = {...cell.style.font, bold: true};
  })

worksheet.views = [
  {state: 'frozen', ySplit: 1}
  ];
}

export const styleSummationRow = (row: Excel.Row): void => {
  // Demark the row by top and borders.
  row.eachCell((cell) => {
    cell.border = {
      top: { style: 'thin' }, bottom: { style: 'double' },
    };
  });
}


export const addSheetTitleRow = (
  worksheet: Excel.Worksheet, 
  columnTypes: FieldTypes[], 
  title: string, 
  startDate: Date, 
  endDate: Date): Excel.Row  => {
    // Add a delimiter row.
    addEmptyRow(worksheet, columnTypes);

    const titleRow = addEmptyRow(worksheet, columnTypes);

    const rowLastColumnAddress = findCellColumnLetterByNumber(columnTypes.length);
    const rowNumber = titleRow.number;

    worksheet.mergeCells(`A${rowNumber}:${rowLastColumnAddress}${rowNumber}`);

    // Set the field label and style.
    const titleStartDate = startDate ? 'Start Date: ' + moment(startDate).format('L') + '    ': '';
    const titleEndDate = 'End Date: ' + moment(endDate).format('L');

    const titleCell: Excel.Cell = titleRow.getCell(1);
    titleCell.value = title + '  ' + titleStartDate + titleEndDate;

    titleCell.style = {
      ...titleCell.style, 
      alignment: {horizontal: 'center'},
      font: {...titleCell.style.font, bold: true}
    };
    titleCell.border = { bottom: { style: 'double' } };

    // Add a delimiter row.
    addEmptyRow(worksheet, columnTypes);

    return titleRow;
  }

export const addEmptyRow = (workSheet: Excel.Worksheet, columnTypes: FieldTypes[]): Excel.Row => {
  // Initialize the row values.
  const emptyRowValues = columnTypes.map(() => '');

  // Add row having all empty values.
  const row: Excel.Row = workSheet.addRow(emptyRowValues)
  
  return row;
}

export const addSummationFormulaToRow = (
  worksheet: Excel.Worksheet,
  summationRow: Excel.Row, 
  summationRowNumbers: number[],
  labelCellLetter: string, 
  summationCellLetter: string): void => {
    addSummationFormulaWithTitleToRow(
        worksheet,
        summationRow,
        summationRowNumbers,
        labelCellLetter,
        summationCellLetter,
        'Total:',
    )
  }

/**
 * Adds a title and summation formula to a row. The label and formula are set in the row cells specified.
 * The formula uses the summationRowNumbers columns as specified by the summationCellLetter to calculate
 * the sum and the formula. If all of the rows are consecutive the formula is of the form SUM(D14:D67).
 * If the rows are not consecutive the formula specifies the sum is each row ie. SUM(D14,D17,D45...).
 * 
 * @param worksheet 
 * @param summationRow 
 * @param summationRowNumbers The list of sheet row numbers to use in the summation.
 * @param labelCellLetter The Excel A1 cell column for the label.
 * @param summationCellLetter The Excel A1 cell column for the formula.
 */
export const addSummationFormulaWithTitleToRow = (
  worksheet: Excel.Worksheet,
  summationRow: Excel.Row, 
  summationRowNumbers: number[],
  labelCellLetter: string, 
  summationCellLetter: string,
  title: string): void => {

  // Set the totals field label and style.
  const totalsLabelCell: Excel.Cell = summationRow.getCell(labelCellLetter);

  addLabelToTotalsCell(totalsLabelCell, title);

  // Calculate the column sum.
  let totalsSum = 0;

  // The list of A1 cell addresses used to calculate the sum.
  const cellAddresses: string[] = [];

  // The maximum number between summation row numbers.
  let maxRowJump = 0;
  let lastRowNum = summationRowNumbers[0] || 0;

  summationRowNumbers.forEach(rowNumber => {
    maxRowJump = Math.max(maxRowJump, rowNumber - lastRowNum);
    lastRowNum = rowNumber;

    const row = worksheet.getRow(rowNumber);
    const summandCell: Excel.Cell = row.getCell(summationCellLetter);
    /**
     * Compile error workaround. Cannot use const enum Excel.ValueType.Formula,
     * so using the enum value directly. 
     */
    if (summandCell.type === 6) {
      const formulaValue = summandCell.value as Excel.CellFormulaValue;

      totalsSum += formulaValue.result as number;
    } else {
      totalsSum += summandCell.value as number;
    }

    cellAddresses.push(summationCellLetter + rowNumber);
  })

  // Build the formula using the sum long form ie. SUM(D12,D15,D34).
  let summationFormula = `SUM(${cellAddresses.join(',')})`;

  // If the summation row numbers are consecutive use the short form ie. SUM(D34:D66).
  if (maxRowJump === 1) {
    cellAddresses.length = 0;
    if (summationRowNumbers.length > 0) {
      cellAddresses.push(summationCellLetter + summationRowNumbers[0]);
    }
    if (summationRowNumbers.length > 1) {
      const lastRowNumber = summationRowNumbers[summationRowNumbers.length - 1];
      cellAddresses.push(summationCellLetter + lastRowNumber);
    }
    summationFormula = `SUM(${cellAddresses.join(':')})`;
  }

  // Add the sum and formula to the totals cell.
  const totalsCell: Excel.Cell = summationRow.getCell(summationCellLetter);
  totalsCell.value = {formula: summationFormula, result: totalsSum, date1904: true};
  totalsCell.border = {
    top: { style: 'thin' }, bottom: { style: 'double' },
  };
}

export const addLabelToTotalsCell = (cell: Excel.Cell, label: string): void => {
  cell.value = label;
  cell.style = {
    ...cell.style, 
    alignment: {horizontal: 'right'},
    font: {...cell.style.font, bold: true}
  };
  cell.border = {
    top: { style: 'thin' }, bottom: { style: 'double' },
  };
                      
}

/**
 * Set the value and style of a cell containing a title.
 * 
 * @param titleCell The Excel.Cell to modify.
 * @param title The cell's text value.
 */
export const setTitleCell = (titleCell: Excel.Cell, title: string): Excel.Cell => {
  titleCell.value = title;

  titleCell.style = {
    ...titleCell.style, 
    alignment: {horizontal: 'left'},
    font: {...titleCell.style.font, bold: true}
  };
  titleCell.border = { bottom: { style: 'double' } };

  return titleCell;
}

/**
 * Convert an Xlsx column number to a column letter.
 * 
 * @param columnNumber The 1 based column number
 */
export const findCellColumnLetterByNumber = (columnNumber: number): string => {
  if (columnNumber < 1) {
    columnNumber = 1;
  }
  if (columnNumber > COLUMNLETTERS.length - 1) {
    columnNumber = COLUMNLETTERS.length - 1;
  }

  return COLUMNLETTERS[columnNumber];
}

/**
 * Finds the cell letter given a list of excel sheet columns types and the column type of the
 * column letter to return.
 * @param headerTypes 
 * @param type 
 */
export const findCellColumnLetterByType = (headerTypes: FieldTypes[], type: FieldTypes): string => {
  const typeIndex = headerTypes.findIndex( headerType =>
  {
    return headerType === type;
  });

  return findCellColumnLetterByNumber(typeIndex + 1);
}

/**
 * Find the A1 cell address for the indicated column FieldType and row number.
 * 
 * @param headerTypes The array of column types.
 * @param type The FieldType to lookup.
 * @param row The cell's row number.
 * 
 * @return The A1 cell number of the specified column and row.
 */
export const findCellLetterAddress = (headerTypes: FieldTypes[], type: FieldTypes, row: string): string=> {
  return findCellColumnLetterByType(headerTypes, type) + row;
}

export const uploadWorkbook = (workbook: Excel.Workbook, reportTitle: string): Promise<boolean> => {
  const fileName = moment().format(`[${reportTitle}_]MM_DD_YYYY[.xlsx]`);

  return workbook.xlsx.writeBuffer()
    .then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    })
    .then(() => {
      return true;
    });  
}
