import { Logger } from '../logger.service';
import { IAuthorizationStore } from '../stores/authorization/authorization.store';

import {
  CONFIGURATION_ITEM_TYPES,
  IConfigurationItem,
  IRoute,
  IRouteConfiguration,
} from './app.configuration';

const log = new Logger('AppConfigurationService');

export interface IAppConfigurationService {
  buildConfiguration(configurationDefinition: IConfigurationItem): IConfigurationItem;
  buildRoutes(configurationDefinition: IConfigurationItem): IRoute[];
}

export class AppConfigurationService implements IAppConfigurationService {
  constructor(private authorizationStore: IAuthorizationStore) {}

  public buildConfiguration(configurationDefinition: IConfigurationItem): IConfigurationItem {
    log.debug('AppConfigurationService: Building configuration');

    const urlSegments: string[] = [];
    
    return this.buildAuthorizedConfiguration(urlSegments, configurationDefinition);
  }

  public buildRoutes(configurationDefinition: IConfigurationItem): IRoute[] {
    log.debug('AppConfigurationService: Building Routes configuration');


    let routes: IRoute[] = [];

    // Make a copy of the configuration as it must be modified.
    const parentConfiguration: IConfigurationItem = Object.assign(
      {},
      configurationDefinition
    );

    parentConfiguration.urlSegments = [];
    if (
      parentConfiguration.type === CONFIGURATION_ITEM_TYPES.FOLDER ||
      parentConfiguration.type === CONFIGURATION_ITEM_TYPES.LINK ||
      parentConfiguration.type === CONFIGURATION_ITEM_TYPES.ROUTER_PATH
    ) {
      parentConfiguration.urlSegments.push(parentConfiguration.path as string);
    }

    parentConfiguration.children!.forEach((child: IConfigurationItem) => {
      // tslint:disable-next-line:no-console
      // console.log('buildRoutes: ', child);

      if (this.itemHasAuthority(child)) {
        routes = routes.concat(this.buildChildRoutes(parentConfiguration, child));
      }
    });

    return routes;
  }

  public buildAuthorizedConfiguration(
    urlSegments: string[],
    configuration: IConfigurationItem
  ): IConfigurationItem {
    const buildConfiguration: IConfigurationItem = Object.assign({}, configuration);
    buildConfiguration.urlSegments = urlSegments.slice(0);

    if (
      configuration.type === CONFIGURATION_ITEM_TYPES.FOLDER ||
      configuration.type === CONFIGURATION_ITEM_TYPES.LINK ||
      configuration.type === CONFIGURATION_ITEM_TYPES.ROUTER_PATH
    ) {
      if (configuration.path && configuration.path.length > 0) {
        buildConfiguration.urlSegments.push(configuration.path);
      } else {
        buildConfiguration.urlSegments.push('');
      }
    }

    buildConfiguration.children = [];

    if (configuration.children && configuration.children.length > 0) {
      configuration.children.forEach((child: IConfigurationItem) => {
        if (this.itemHasAuthority(child)) {
          buildConfiguration.children!.push(
            this.buildAuthorizedConfiguration(buildConfiguration.urlSegments as string[], child)
          );
        }
      });
    }

    return buildConfiguration;
  }

  public buildChildRoutes(
    parent: IConfigurationItem,
    child: IConfigurationItem
  ): IRoute[] {
    let routes: IRoute[] = [];
    const childBuildPage: IConfigurationItem = Object.assign({}, child);

      // tslint:disable-next-line:no-console
      // console.log('buildChildRoutes', parent, child);

    if (
      child.type === CONFIGURATION_ITEM_TYPES.FOLDER ||
      child.type === CONFIGURATION_ITEM_TYPES.ROUTER_PATH
    ) {
      parent.urlSegments = parent.urlSegments || [];
      childBuildPage.urlSegments = parent.urlSegments.slice(0);
      if (child.path && child.path.length > 0) {
        childBuildPage.urlSegments.push(child.path);
      }
      childBuildPage.children = [];

      if (child.children && child.children.length > 0) {
        const childCopy: IConfigurationItem = Object.assign({}, child);
        childCopy.children = child.children;
        childCopy.urlSegments = childBuildPage.urlSegments;

        child.children.forEach((grandChild: IConfigurationItem) => {
          if (this.itemHasAuthority(grandChild)) {
              routes = routes.concat(this.buildChildRoutes(childCopy, grandChild))
          }
        });
      }
    } else if (child.type === CONFIGURATION_ITEM_TYPES.LINK) {
      const urlSegments: string[] = (parent.urlSegments as string[]).slice(0);

      urlSegments.push(child.path || '');

      const routeConfiguration: IRouteConfiguration = child.routeConfiguration as IRouteConfiguration;
      const route: IRoute = {
        path: urlSegments.join('/'),
        exact: routeConfiguration.exact,
        componentName: routeConfiguration.componentName,
        secured: routeConfiguration.secured,
      }

      if (routeConfiguration.permissions) {
        route.permissions = routeConfiguration.permissions;
      } else if (child.permissions) {
        route.permissions = child.permissions;
      }
      if (routeConfiguration.shops) {
        route.shops = routeConfiguration.shops;
      } else if (child.shopAuthorizations) {
        route.shops = child.shopAuthorizations.map(shopAuth => shopAuth.shop);
      }
      if (routeConfiguration.props) {
        route.props = routeConfiguration.props;
      }

      routes.push(route);
    }

    return routes;
  }

  public itemHasAuthority(item: IConfigurationItem): boolean {
    let hasAuthority = true;

    if (item.permissions && item.permissions.length > 0) {
      hasAuthority = this.authorizationStore.hasAuthority(item.permissions);
    }

    if (
      hasAuthority &&
      item.shopAuthorizations &&
      item.shopAuthorizations.length > 0
    ) {
      const shops: string[] = [];

      item.shopAuthorizations.forEach(shopPermission => {
        shops.push(shopPermission.shop);
      });

      hasAuthority = this.authorizationStore.isAuthorizedForShops(shops);
    }

    //    log.debug(`Item ${item.handle}  hasAuthority ${hasAuthority}`);
    //    log.debug(`Permissions ` + JSON.stringify(item.permissions));
    //    log.debug(`Shops ` + JSON.stringify(item.shopAuthorizations));

    return hasAuthority;
  }
}
