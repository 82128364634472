import { 
  IInventoryProvider, 
  ILoginProvider, 
  IOrdersProvider, 
  IProductsProvider, 
  ISalesProvider, 
  IShopsProvider,
  IVersionProvider,
 } from './api.providers';

export interface IApiProvider {
  getInventoryProvider(): IInventoryProvider;
  getLoginProvider(): ILoginProvider;
  getOrdersProvider(): IOrdersProvider;
  getProductsProvider(): IProductsProvider;
  getSalesProvider(): ISalesProvider;
  getShopsProvider(): IShopsProvider;
  getVersionProvider(): IVersionProvider;
}

export class ApiProvider implements IApiProvider {

  constructor(
    private readonly inventoryProvider: IInventoryProvider,
    private readonly loginProvider: ILoginProvider,
    private readonly ordersProvider: IOrdersProvider,
    private readonly productsProvider: IProductsProvider,
    private readonly salesProvider: ISalesProvider,
    private readonly shopsProvider: IShopsProvider,
    private readonly versionProvider: IVersionProvider,
    ) {}

  public getInventoryProvider(): IInventoryProvider {
    return this.inventoryProvider;
  }
  
  public getLoginProvider(): ILoginProvider {
    return this.loginProvider;
  }

  public getSalesProvider(): ISalesProvider {
    return this.salesProvider;
  }

  public getOrdersProvider(): IOrdersProvider {
    return this.ordersProvider;
  }

  public getProductsProvider(): IProductsProvider {
    return this.productsProvider;
  }

  public getShopsProvider(): IShopsProvider {
    return this.shopsProvider;
  }

  public getVersionProvider(): IVersionProvider {
    return this.versionProvider;
  }
}
